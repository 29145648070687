import {useMutation} from '@apollo/client';
import config from '../config/config';
import String from '../utils/string';
import {toastNotification} from '../components/toastify';
import {GERAR_TOKEN_ANUNCIANTE, GERAR_TOKEN_COMERCIAL} from '../graphql/mutation';

const usePainelService = () => {
  const [accessTokenAnuncianteByComercial] = useMutation(GERAR_TOKEN_ANUNCIANTE);
  const [accessTokenComercialByAdmin] = useMutation(GERAR_TOKEN_COMERCIAL);

  const acessarPainelAnunciante = (id, setLoading) => {
    accessTokenAnuncianteByComercial({
      variables: {
        id
      },
    })
      .then(({data}) => {
        const authData = data.accessTokenAnuncianteByComercial;
        const authDataBuffer = Object.keys(authData).reduce(
          (actual, current) => {
            const keySnakeCase = String.transformCamelToSnakeCase(current);
            return {...actual, [keySnakeCase]: authData[current]};
          },
          {},
        );

        window.open(
          `${config.URL_PAINEL_ANUNCIANTE}?auth-data=${btoa(
            JSON.stringify(authDataBuffer),
          )}`
        );
      })
      .catch((error) => toastNotification({message: error.message, type: 'error'}))
      .finally(() => setLoading && setLoading(true));
  };

  const acessarPainelComercial = (id, setLoading) => {
    accessTokenComercialByAdmin({
      variables: {
        id
      },
    })
      .then(({data}) => {
        const authData = data.accessTokenComercialByAdmin;
        const authDataBuffer = Object.keys(authData).reduce(
          (actual, current) => {
            const keySnakeCase = String.transformCamelToSnakeCase(current);
            return {...actual, [keySnakeCase]: authData[current]};
          },
          {},
        );

        window.open(
          `?auth-data=${btoa(
            JSON.stringify(authDataBuffer),
          )}`,
          '_self'
        );
      })
      .catch((error) => toastNotification({message: error.message, type: 'error'}))
      .finally(() => setLoading && setLoading(false));
  };

  return {acessarPainelAnunciante, acessarPainelComercial};
};

export default usePainelService;