import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  Box
} from '@mui/material';
import {Visibility, OpenInNew} from '@mui/icons-material';
import {persistFiltrosCampanha} from '../../redux/Campanha/slice';
import {FIND_CAMPANHA_BY_COMERCIAL} from '../../graphql/queries';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import {SelectAnunciantes} from '../../containers/selects/anunciantes';
import {campanhaSituacao} from '../../constants/enum-labels';
import {SelectEstados} from '../../containers/selects/estados';
import {SelectCidades} from '../../containers/selects/cidades';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import TableCellOrdered from '../../components/table-cell-ordered';
import Select from '../../components/select/mui-select';
import usePainelService from '../../services/PainelService';

const getPeriodoVeiculacao = (campanha) => {
  const dataInicioCampanha = campanha.dataInicioVeiculacao;

  if (!dataInicioCampanha) return;

  const dataInicio = moment(dataInicioCampanha);
  const dataFinal = moment(dataInicioCampanha)
    .add(campanha?.diasPeriodo * campanha?.quantidadeMeses || 0, 'd')
    .subtract(1, 'd');

  return `${dataInicio.format('DD/MM/YY')} - ${dataFinal.format('DD/MM/YY')}`;
};

const getStatusConsulta = (status) => {
  if (!status) return null;

  switch (status) {
    case 'ATIVA':
      return ['ATIVA', 'ATIVA_PARCIAL'];
    case 'EM_OPERACAO':
      return ['PENDENTE', 'AGUARDANDO_INSTALACAO'];
    case 'FINALIZADA':
      return ['FINALIZADA', 'SUSPENSA', 'CANCELADA'];
    default:
      return [status];
  }
};

const columns = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'anunciante.nomeFantasia',
    format: ({anunciante}) => anunciante?.nomeFantasia || ' - ',
  },
  {
    id: 'cidades',
    label: 'Cidades',
    format: ({cidades}) => (cidades.length || 0),
    calculado: true
  },
  {
    id: 'titulo',
    label: 'Título',
    format: ({titulo, numero}) => `[${numero}] ${titulo}`
  },
  {id: 'quantidadeVeiculos', label: 'Veículos'},
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao}) => campanhaSituacao[situacao],
  },
  {
    id: 'periodoVeiculacao',
    label: 'Veículação',
    campo: 'dataInicioVeiculacao',
    format: (campanha) => getPeriodoVeiculacao(campanha),
  },
];

const ConsultaCampanha = ({pageSize = 20}) => {
  const {filtrosCampanha} = useSelector((store) => store.Campanha);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const painelService = usePainelService();

  const [page, setPage] = useState(filtrosCampanha.page);
  const [filtros, setFiltros] = useState(filtrosCampanha.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosCampanha.orderBy);
  const [rowsPerPage] = useState(pageSize);
  const [campanhas, setCampanhas] = useState([]);
  const [loadingAccessAnunciante, setLoadingAccessAnunciante] = useState(null);

  const campanhasQuery = useQuery(FIND_CAMPANHA_BY_COMERCIAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        anuncianteId: searchDTO.anunciante?.value,
        comercialId: searchDTO.comercial?.value,
        estadoId: searchDTO.estado?.value,
        cidadeCampanhaId: searchDTO.cidade?.value,
        campanhaSituacoesIn: getStatusConsulta(
          searchDTO.campanhaSituacao?.value,
        ),
        campanhaSituacoesDiferenteDe: ['AGUARDANDO_APROVACAO'],
      },
    },
  });

  useEffect(() => {
    if (campanhasQuery.loading) return;

    setCampanhas(campanhasQuery.data?.campanhas?.content || []);
    dispatch(persistFiltrosCampanha({filtros, page, orderBy}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhasQuery]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  component={InputV2Formik}
                  label="Título"
                  name="search"
                  onChange={(e) =>
                    setFiltros({...filtros, search: e?.target?.value || ''})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) => {
                    setFiltros({...filtros, anunciante: e});
                  }}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  value={filtros.campanhaSituacao}
                  onChange={(e) =>
                    setFiltros({...filtros, campanhaSituacao: e || null})
                  }
                  options={[
                    {label: 'Todas'},
                    {value: 'ATIVA', label: 'Ativa'},
                    {value: 'EM_OPERACAO', label: 'Em operação'},
                    {value: 'FINALIZADA', label: 'Finalizada'},
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="button"
                  value="Filtrar"
                  loading={campanhasQuery.loading}
                  onClick={refetch}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead tabIndex={-1}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell
                      key="acoes"
                      align="center"
                      sx={styles.columnHeadAcoes}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campanhas.map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                          >
                            <RoundTooltipButton
                              title={'Acessar painel'}
                              onClick={() => {
                                setLoadingAccessAnunciante(row);
                                painelService.acessarPainelAnunciante(row.anunciante?.id, setLoadingAccessAnunciante);
                              }}
                              loading={loadingAccessAnunciante?.id === row.id}
                              icon={<OpenInNew />}
                              id="icon-gray"
                            />
                            <RoundTooltipButton
                              title={'Visualizar'}
                              onClick={() =>
                                navigate(`${row.id}/editar`)
                              }
                              icon={<Visibility />}
                              id="icon-primary"
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={campanhasQuery.data?.campanhas?.totalElements || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  columnHeadAcoes: {
    cursor: 'default',
    fontWeight: 'bold',
    width: '135px'
  }
};

export default ConsultaCampanha;
