import {useMutation, useQuery} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {Box, Grid, Modal, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import InputV2Formik from '../../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../../components/mui-button';
import UploadableFotoPerfil from '../../../components/mui-uploadable-foto-perfil';
import {toastNotification} from '../../../components/toastify';
import {ATUALIZAR_ANUNCIANTE} from '../../../graphql/mutation';
import {FIND_BY_ID_ANUNCIANTE} from '../../../graphql/queries';
import {coordenadasPorEndereco} from '../../../services/GeocodingService';
import {uploadAmazonS3} from '../../../services/UploadService';
import Objects from '../../../utils/objects';
import string from '../../../utils/string';
import {SelectCidades} from '../../selects/cidades';
import {SelectSegmentos} from '../../selects/segmento';

const EditarClienteModal = ({isOpen, onClose, onSave, selecionado = {}}) => {
  const [atualizarAnunciante] = useMutation(ATUALIZAR_ANUNCIANTE);
  const [anunciante, setAnunciante] = useState(selecionado);
  const [fotoPerfil, setFotoPerfil] = useState(anunciante?.fotoPerfil);
  const [uploadImage, setUploadImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const {data, loading} = useQuery(FIND_BY_ID_ANUNCIANTE, {
    variables: {
      id: selecionado.id,
    },
  });

  useEffect(() => {
    if (!data?.anunciante || loading) return;

    setAnunciante(data.anunciante);
  }, [data, loading, setAnunciante]);

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitAtualizarCliente(
    {
      cep,
      logradouro,
      cidade,
      numero,
      complemento,
      bairro,
      agencia,
      segmentoAtuacao,
      ...values
    },
    {setSubmitting},
  ) {
    if (!anunciante.id) {
      setSubmitting(false);
      return;
    }

    const dadosForm = {
      ...values,
      cnpj: string.removeSpecialChars(values.cnpj),
      telefone: string.removeSpecialChars(values.telefone),
      celular: string.removeSpecialChars(values.celular),
      marketingTelefone: string.removeSpecialChars(values.marketingTelefone),
      financeiroTelefone: string.removeSpecialChars(values.financeiroTelefone),
      marketingTelefoneAdicional: string.removeSpecialChars(
        values.marketingTelefoneAdicional,
      ),
      financeiroTelefoneAdicional: string.removeSpecialChars(
        values.financeiroTelefoneAdicional,
      ),
      agencia: agencia?.id ? {id: agencia.id} : undefined,
      segmentoAtuacao: Objects.jsToEntity(segmentoAtuacao),
      endereco: {
        cep: string.removeSpecialChars(cep),
        logradouro,
        cidade: Objects.jsToEntity(cidade),
        numero,
        complemento,
        bairro,
      },
    };

    try {
      const coordenadasEndereco = await coordenadasPorEndereco({
        ...dadosForm.endereco,
        cidade,
      });

      let uploadData = null;
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }

      const dadosAnunciante = {
        ...dadosForm,
        endereco: {
          ...dadosForm.endereco,
          latitude: coordenadasEndereco.lat,
          longitude: coordenadasEndereco.lng,
        },
        fotoPerfil:
          uploadData && uploadData.id ? uploadData.id : anunciante.fotoPerfil,
        id: anunciante.id,
      };

      const response = await atualizarAnunciante({
        variables: {
          anunciante: {
            ...dadosAnunciante,
            cadastroCompleto: true,
          },
        },
      });

      onSave({
        ...dadosAnunciante,
        id: response.data.updateAnunciante.id,
      });
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}>
      <Grid sx={styles.content}>
        <Grid sx={styles.header}>
          <Typography sx={styles.headerText}>Editar cliente</Typography>
          <Close sx={styles.closeIcon} onClick={onClose} />
        </Grid>
        <Formik
          validationSchema={ClienteScheme}
          initialValues={{
            nomeRazao: anunciante.nomeRazao || '',
            nomeFantasia: anunciante.nomeFantasia || '',
            cnpj: anunciante.cnpj || '',
            inscricaoEstadual: anunciante.inscricaoEstadual || '',
            segmentoAtuacao: anunciante.segmentoAtuacao,
            agencia: anunciante.agencia,
            cep: anunciante.endereco?.cep || '',
            cidade: anunciante.endereco?.cidade,
            logradouro: anunciante.endereco?.logradouro || '',
            bairro: anunciante.endereco?.bairro || '',
            numero: anunciante.endereco?.numero || '',
            complemento: anunciante.endereco?.complemento || '',
            nomeContato: anunciante.nomeContato || '',
            telefone: anunciante.telefone || '',
            email: anunciante.email || '',
            celular: anunciante.celular || '',
            site: anunciante.site || '',
            financeiroNome: anunciante.financeiroNome || '',
            financeiroEmail: anunciante.financeiroEmail || '',
            financeiroTelefone: anunciante.financeiroTelefone || '',
            financeiroNomeAdicional: anunciante.financeiroNomeAdicional || '',
            financeiroTelefoneAdicional:
              anunciante.financeiroTelefoneAdicional || '',
            financeiroEmailAdicional: anunciante.financeiroEmailAdicional || '',
            marketingNome: anunciante.marketingNome || '',
            marketingEmail: anunciante.marketingEmail || '',
            marketingTelefone: anunciante.marketingTelefone || '',
            marketingNomeAdicional: anunciante.marketingNomeAdicional || '',
            marketingTelefoneAdicional:
              anunciante.marketingTelefoneAdicional || '',
            marketingEmailAdicional: anunciante.marketingEmailAdicional || '',
            emailUsuario: anunciante.emailUsuario || '',
          }}
          enableReinitialize
          onSubmit={handleSubmitAtualizarCliente}>
          {({isSubmitting, handleSubmit, values}) => (
            <Box
              component="form"
              className="styled-scroll"
              sx={styles.form}
              onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <UploadableFotoPerfil
                    image={previewImage || fotoPerfil}
                    setImage={setFotoPerfil}
                    handleChangeUploadImage={handleChangeUploadImage}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  direction={'row'}
                  spacing={2}
                  alignContent={'space-around'}>
                  <Grid item xs={12}>
                    <Field
                      component={InputV2Formik}
                      name="nomeRazao"
                      label="Razão social"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={InputV2Formik}
                      name="nomeFantasia"
                      label="Nome fantasia"
                      sx={!values.nomeFantasia && styles.campoObrigatorio}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="cnpj"
                    label="CNPJ"
                    mask="cnpj"
                    sx={!values.cnpj && styles.campoObrigatorio}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="inscricaoEstadual"
                    label="Insrição estadual"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={SelectSegmentos}
                    name="segmentoAtuacao"
                    label="Segmento de atuação"
                    isClearable
                    sx={!values.segmentoAtuacao && styles.campoObrigatorio}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone / Empresa"
                    mask="phone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field component={InputV2Formik} name="site" label="Site" />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="email"
                    label="E-mail"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Endereço
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={SelectCidades}
                    name="cidade"
                    label="Cidade/Estado"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Marketing
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingNome"
                    label="Contato / Marketing"
                    sx={!values.marketingNome && styles.campoObrigatorio}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingTelefone"
                    label="Telefone"
                    mask="phone"
                    sx={!values.marketingTelefone && styles.campoObrigatorio}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingEmail"
                    label="E-mail"
                    sx={!values.marketingEmail && styles.campoObrigatorio}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingNomeAdicional"
                    label="Contato / Marketing 2"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingTelefoneAdicional"
                    label="Telefone"
                    mask="phone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingEmailAdicional"
                    label="E-mail"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Financeiro
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroNome"
                    label="Contato / Financeiro"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroTelefone"
                    label="Telefone"
                    mask="phone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroEmail"
                    label="E-mail"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroNomeAdicional"
                    label="Contato / Financeiro 2"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroTelefoneAdicional"
                    label="Telefone"
                    mask="phone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroEmailAdicional"
                    label="E-mail"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="emailUsuario"
                    label="E-mail usuário"
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 25,
                }}>
                <ButtonComponent
                  id="button-cancel"
                  type="button"
                  value="Cancelar"
                  onClick={onClose}
                  sx={styles.buttons}
                  disabled={isSubmitting}
                />
                <ButtonComponent
                  id="button"
                  type="submit"
                  value="Salvar"
                  sx={styles.buttons}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

const ClienteScheme = Yup.object().shape({
  nomeRazao: Yup.string().required('Campo obrigatório'),
  nomeFantasia: Yup.string().required('Campo obrigatório'),
  cnpj: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cnpj', 'CNPJ inválido', (value) => string.validaCNPJ(value)),
  cep: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  marketingNome: Yup.string().required('Campo obrigatório'),
  marketingEmail: Yup.string().required('Campo obrigatório'),
  marketingTelefone: Yup.string().required('Campo obrigatório'),
  financeiroNome: Yup.string().required('Campo obrigatório'),
  financeiroEmail: Yup.string().required('Campo obrigatório'),
  financeiroTelefone: Yup.string().required('Campo obrigatório'),
  segmentoAtuacao: Yup.object().nullable().required('Campo obrigatório'),
  emailUsuario: Yup.string().required('Campo obrigatório'),
});

const styles = {
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0',
  },
  closeIcon: {
    color: '#0083e6',
    fontSize: '30px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '8px',
  },
  form: {
    overflowY: 'auto',
    padding: '0 16px',
    height: 'calc(100% - 50px)',
  },
  buttons: {
    width: '100%',
    marginBottom: '12px',

    '@media (min-width: 1024px)': {
      width: '185px',
    },
  },
  subtitle: {
    color: ' #00106B',
    fontSize: ' 14px',
    fontWeight: '900',
    marginBottom: '16px',
  },
  campoObrigatorio: {
    '& input': {
      border: '1px solid #ff5252',
    },
    '& .react-select__control': {
      border: '1px solid #ff5252',
    },
  },
};

export default EditarClienteModal;
