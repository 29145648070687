import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

import AvatarImage from '../assets/images/km-blue.png';

const SliderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10%;
`;

const AvatarContainer = styled.div`
  margin-right: 15px;
`;

const DescricaoContainer = styled.div``;

const Avatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

const Descricao = styled.p`
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 8px;
`;

const InfoUsuario = styled.p`
  font-size: 14px;
  color: #1ffcff;
  margin: 0;
`;

const PaginacaoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;
const PaginacaoItem = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  width: 30px;
  height: 4px;
  margin: 0 6px;
  border-radius: 2px;
  position: relative;
`;

const PaginacaoItemInnerBackground = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 0%;
  height: 4px;
  border-radius: 2px;
`;

const tempoSlider = 5000;

export const DepoimentosContainer = props => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { width } = useSpring({
    from: {
      width: 0
    },
    to: {
      width: 100
    },
    config: { duration: tempoSlider },
    reset: true
  });

  return (
    <>
      <Slider
        autoplay={tempoSlider}
        previousButton={null}
        nextButton={null}
        onSlideChange={event => {
          setSlideIndex(event.slideIndex);
        }}
      >
        {props.depoimentos &&
          props.depoimentos.map((item, index) => (
            <SliderContentContainer key={index}>
              <AvatarContainer>
                <Avatar src={AvatarImage} alt="" />
              </AvatarContainer>
              <DescricaoContainer>
                <Descricao>{item.descricao}</Descricao>
                <InfoUsuario>{item.infoUsuario}</InfoUsuario>
              </DescricaoContainer>
            </SliderContentContainer>
          ))}
      </Slider>
      <PaginacaoContainer>
        {props.depoimentos &&
          props.depoimentos.map((item, index) => (
            <PaginacaoItem key={index}>
              {index === slideIndex && (
                <PaginacaoItemInnerBackground
                  style={{ width: width.interpolate(w => `${w.toFixed(0)}%`) }}
                />
              )}
            </PaginacaoItem>
          ))}
      </PaginacaoContainer>
    </>
  );
};
