const Objects = {
  jsToEntity(object) {
    if (!object?.value) return undefined;

    const newObject = {...object};

    delete newObject?.value;
    delete newObject?.label;

    return newObject;
  },
  jsToEntityId(object) {
    if (!object?.value) return undefined;

    const newObject = {id: object.value};

    return newObject;
  },
};

export default Objects;
