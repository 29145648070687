import React, {useState} from 'react';
import {Grid, Paper, Box, Typography} from '@mui/material';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';
import {Edit, Key, Save} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {CheckboxWithLabel} from 'formik-mui';
import {useSelector} from 'react-redux';
import ImagemModal from '../../containers/modais/imagem-modal';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {SelectCidades} from '../../containers/selects/cidades';
import String from '../../utils/string';
import {
  CRIAR_COMERCIAL,
  GERAR_NOVA_SENHA_COMERCIAL,
} from '../../graphql/mutation';
import {uploadAmazonS3} from '../../services/UploadService';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {toastNotification} from '../../components/toastify';
import {confirmAlertCustom} from '../../components/confirm-alert/confirm-alert';
import {SelectBancos} from '../../containers/selects/bancos';
import {tipoContaBancaria} from '../../constants/enum-labels';
import SelectFormik from '../../components/select/mui-select-formik';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import {ButtonComponent} from '../../components/mui-button';

const FormularioExecutivo = ({executivo = {}, refetch}) => {
  const navigate = useNavigate();
  const {id: comercialLogado} = useSelector(store => store.Auth);

  const [readOnly, setReadOnly] = useState(!!executivo?.id);
  const [uploadImage, setUploadImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(executivo.fotoPerfil);
  const [imagemModal, setImagemModal] = useState({
    visible: false,
    id: null,
    titulo: '',
  });

  const [criarComercial] = useMutation(CRIAR_COMERCIAL);
  const [gerarNovaSenha] = useMutation(GERAR_NOVA_SENHA_COMERCIAL);

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitDadosComercial(
    {
      cep,
      logradouro,
      cidade,
      bairro,
      numero,
      complemento,
      banco,
      agencia,
      tipoContaBancaria,
      conta,
      chavePix,
      documentoFavorecido,
      nomeFavorecido,
      bancoCidade,
      ...values
    },
    {setSubmitting},
  ) {
    const dadosComercial = {
      ...values,
      cpfCnpj: String.removeSpecialChars(values.cpfCnpj),
      telefone: String.removeSpecialChars(values.telefone),
      celular: String.removeSpecialChars(values.celular),
      role: 'LICENCA_COMERCIAL',
      comercialResponsavel: {
        id: comercialLogado
      },
      endereco: {
        cep: String.removeSpecialChars(cep),
        logradouro,
        bairro,
        cidade: {
          id: cidade.value,
        },
        numero,
        complemento,
      },
      dadoBancario: {
        banco: !banco?.id ? null : {
          id: banco.id,
        },
        agencia: agencia,
        tipoContaBancaria: tipoContaBancaria?.value,
        conta: conta,
        chavePix: chavePix,
        cpfCnpjFavorecido: String.removeSpecialChars(documentoFavorecido),
        nomeFavorecido: nomeFavorecido,
        cidade: !bancoCidade?.id ? null : {
          id: bancoCidade.id,
        },
      },
    };

    let uploadData = null;
    try {
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }
    } catch (error) {
      console.log(error);
    }

    criarComercial({
      variables: {
        comercial: {
          ...dadosComercial,
          id: executivo?.id || undefined,
          fotoPerfil: uploadData?.id || executivo.fotoPerfil,
        },
      },
    })
      .then(({data}) => {
        setReadOnly(true);
        toastNotification({
          message: 'Os dados cadastrados foram salvos com sucesso!',
          type: 'success',
        });
        navigate(`/executivos/${data?.comercial?.id}/editar`)
      })
      .catch(error => toastNotification({message: error.message, type: 'error'}))
      .finally(() => setSubmitting(false));

  }

  const handleOpenConfirmAlert = () => {
    confirmAlertCustom({
      message: 'Deseja mesmo gerar uma nova senha?',
      onConfirm: gerarUsuarioSenha
    })
  }

  const gerarUsuarioSenha = () => {
    gerarNovaSenha({
      variables: {
        comercial: {
          id: executivo.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.senha}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.senha);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={ComercialScheme}
          initialValues={{
            nomeRazao: executivo.nomeRazao || '',
            cpfCnpj: executivo.cpfCnpj || '',
            cep: executivo.endereco?.cep || '',
            cidade: !executivo.endereco ? null : {
              ...executivo.endereco.cidade,
              value: executivo.endereco.cidade.id,
              label: executivo.endereco.cidade.nomeEUf,
            },
            logradouro: executivo.endereco?.logradouro || '',
            bairro: executivo.endereco?.bairro || '',
            numero: executivo.endereco?.numero || '',
            complemento: executivo.endereco?.complemento || '',
            nomeContato: executivo.nomeRazao || '',
            telefone: executivo.telefone || '',
            email: executivo.email || '',
            celular: executivo.celular || '',
            porcentagemValorRepasse: 0,
            banco: !executivo.dadoBancario?.banco ? undefined : {
              ...executivo.dadoBancario.banco,
              value: executivo.dadoBancario.banco.id,
              label: executivo.dadoBancario.banco.codigoENome
            },
            agencia: executivo.dadoBancario?.agencia || '',
            tipoContaBancaria: !executivo.dadoBancario?.tipoContaBancaria ? null : {
              value: executivo.dadoBancario?.tipoContaBancaria,
              label: tipoContaBancaria[executivo.dadoBancario?.tipoContaBancaria],
            },
            conta: executivo.dadoBancario?.conta || '',
            chavePix: executivo.dadoBancario?.chavePix || '',
            documentoFavorecido: executivo.dadoBancario?.cpfCnpjFavorecido || '',
            nomeFavorecido: executivo.dadoBancario?.nomeFavorecido || '',
            bancoCidade: !executivo.dadoBancario?.cidade ? null : {
              ...executivo.dadoBancario?.cidade,
              value: executivo.dadoBancario?.cidade.id,
              label: executivo.dadoBancario?.cidade.nomeEUf,
            },
            ativo: executivo.ativo || '',
          }}
          enableReinitialize
          onSubmit={handleSubmitDadosComercial}
        >
          {({values, isSubmitting, handleSubmit, setFieldValue}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <UploadableFotoPerfil
                    image={previewImage || fotoPerfil}
                    setImage={setFotoPerfil}
                    handleChangeUploadImage={handleChangeUploadImage}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid container item xs={10} direction={'row'} spacing={2} alignContent={'space-around'}>
                  <Grid item xs={8}>
                    <Field
                      component={InputV2Formik}
                      name="nomeRazao"
                      label="Nome"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputV2Formik}
                      name="cpfCnpj"
                      label="CPF / CNPJ"
                      mask="cpfCnpj"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={InputV2Formik}
                      name="email"
                      label="E-mail"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={InputV2Formik}
                      name="telefone"
                      label="Telefone"
                      mask="phone"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={InputV2Formik}
                      name="celular"
                      label="Celular"
                      mask="phone"
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" sx={styles.formularioSubtitle}>
                    Endereço
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      label="Cidade/Estado"
                      value={executivo.endereco?.cidade?.nomeEUf}
                      title={executivo.endereco?.cidade?.nomeEUf}
                      disabled
                    />
                  ) : (
                    <Field
                      component={SelectCidades}
                      name="cidade"
                      label="Cidade/Estado"
                      disabled={readOnly}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" sx={styles.formularioSubtitle}>
                    Dados bancários
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Banco"
                      value={values.banco?.codigoENome}
                    />
                  ) : (
                    <Field component={SelectBancos} name="banco" label="Banco" />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="agencia"
                    label="Agência"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Tipo de conta"
                      value={values.tipoContaBancaria?.label || ''}
                    />
                  ) : (
                    <Field
                      component={SelectFormik}
                      name="tipoContaBancaria"
                      label="Tipo de conta"
                      options={[
                        {
                          value: 'CORRENTE',
                          label: 'Corrente',
                        },
                        {
                          value: 'POUPANCA',
                          label: 'Poupança',
                        },
                      ]}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="conta"
                    label="Conta"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="chavePix"
                    label="Chave PIX"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="documentoFavorecido"
                    label="CPF/CNPJ favorecido"
                    mask="cpfCnpj"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="nomeFavorecido"
                    label="Nome favorecido"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Cidade"
                      value={values.bancoCidade?.nomeEUf}
                    />
                  ) : (
                    <Field
                      component={SelectCidades}
                      name="bancoCidade"
                      label="Cidade"
                      disabled={readOnly}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" sx={styles.formularioSubtitle}>
                    Usuário
                  </Typography>
                </Grid>
                <Grid item xs={3} md={12}>
                  <Field
                    component={CheckboxWithLabel}
                    defaultValue={values.ativo}
                    type="checkbox"
                    name="ativo"
                    color="primaryPatern"
                    Label={{
                      label: 'Ativo',
                    }}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent={'space-between'} style={{marginTop: 'auto', padding: '30px 0 0 0'}}>
                <Grid
                  container
                  item
                  direction={'row'}
                  xs={6}
                  style={{visibility: executivo?.id ? 'visible' : 'hidden'}}
                >
                  <ButtonComponent
                    type="button"
                    id="button-green"
                    sx={styles.buttonWrapper}
                    value="Gerar nova senha"
                    icon={<Key />}
                    onClick={handleOpenConfirmAlert}
                    disabled={!readOnly}
                  />
                </Grid>
                <Grid container item justifyContent={'flex-end'} xs={3}>
                  {readOnly && (
                    <ButtonComponent
                      sx={styles.buttonWrapper}
                      type="button"
                      value={'Editar'}
                      icon={<Edit />}
                      onClick={() => setReadOnly(false)}
                    />
                  )}
                  {!readOnly && (
                    <ButtonComponent
                      type="submit"
                      sx={styles.buttonWrapper}
                      value={'Salvar'}
                      loading={isSubmitting}
                      icon={<Save />}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
        {imagemModal.visible && (
          <ImagemModal
            visible={imagemModal.visible}
            id={imagemModal.id}
            titulo={imagemModal.titulo}
            onClose={() => setImagemModal({visible: false, id: null, titulo: ''})}
          />
        )}
      </Paper>
    </Grid>
  );
};

const ComercialScheme = Yup.object().shape({
  nomeRazao: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable()
    .required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  celular: Yup.string().required('Campo obrigatório'),
});

const styles = {
  buttonWrapper: {
    width: '256px',

    '@media (max-width: 960px)': {
      width: '100%',
    },
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  multiselect: {
    '& .react-select__multi-value': {
      borderRadius: '6px',
      color: '#afb7c9',
    },
    '& .react-select__multi-value__label': {
      color: '#657496'
    }
  },
  formularioSubtitle: {
    color: ' #00106B',
    fontSize: ' 14px',
    fontWeight: '900',
  },
};

export default FormularioExecutivo;
