import {useQuery} from '@apollo/client';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import {FIND_ALL_MODELO_ANUNCIO} from '../../graphql/queries';
import Number from '../../utils/number';

const columns = [
  {id: 'nome', label: 'Modelo anúncio'},
  {
    id: 'producao',
    label: 'Produção',
    format: ({valorInstaladorPorInstalacao}) =>
      Number.currencyFormat(valorInstaladorPorInstalacao),
  },
  {
    id: 'valorVenda',
    label: 'Veiculação\n(Tabela nacional)',
    format: ({valorVenda}) => Number.currencyFormat(valorVenda),
    style: {width: 'fit-content', textAlign: 'center'},
  },
  {
    id: 'valorTres',
    label: 'Veiculação\n(Valor recomendado)',
    format: ({tabelaTres, valorVenda}) =>
      `${Number.format(tabelaTres) || 0} % - ${Number.currencyFormat(
        ((100 - tabelaTres) / 100) * valorVenda,
      )}`,
    style: {width: 'fit-content', textAlign: 'center'},
  },
];

const ConsultaTabelaPreco = ({pageSize = 30}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);
  const [filtros, setFiltros] = useState({});
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [modelos, setModelos] = useState([]);

  const modeloAnunciosQuery = useQuery(FIND_ALL_MODELO_ANUNCIO, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
        ativo: true,
      },
    },
  });

  useEffect(() => {
    if (modeloAnunciosQuery.loading || modeloAnunciosQuery.error) return;

    setModelos(modeloAnunciosQuery.data?.modelos?.content || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeloAnunciosQuery]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <InputV2
                  label="Modelo anúncio"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="button"
                  value="Filtrar"
                  loading={modeloAnunciosQuery.loading}
                  onClick={() => refetch()}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={styles.columnHead}>
                        <Typography component="pre" sx={column.style}>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modelos.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={modeloAnunciosQuery.data?.modelos?.totalElements || 0}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                labelDisplayedRows={({from, to, count}) =>
                  `${from}-${to} de ${count}`
                }
                onPageChange={handleChangePage}
              />
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    flexGrow: 1,
    alignContent: 'flex-start',
    background: (theme) => theme.palette.paterns.white,
    overflow: 'auto',
  },
  columnHead: {
    '& pre': {
      fontFamily: 'inherit',
      cursor: 'default',
      fontWeight: 'bold',
      paddingTop: '0px',
      paddingBottom: '0px',
      margin: 0,
    },
  },
};

export default ConsultaTabelaPreco;
