
const campanhaSituacao = {
  AGUARDANDO_APROVACAO: 'Aguardando aprovação',
  AGUARDANDO_IMPRESSAO: 'Em operação',
  AGUARDANDO_INSTALACAO: 'Em operação',
  PENDENTE: 'Em operação',
  ATIVA: 'Ativa',
  ATIVA_PARCIAL: 'Ativa',
  SUSPENSA: 'Finalizada',
  CANCELADA: 'Finalizada',
  FINALIZADA: 'Finalizada',
  NAO_APROVADA: 'Não aprovada',
};

const anuncioSituacao = {
  AGUARDANDO_TROCA_MOTORISTA: 'Aguardando troca de motorista',
  AGUARDANDO_ACEITE_MOTORISTA: 'Aguardando aceite do motorista',
  SUSPENSO: 'Suspenso',
  AGUARDANDO_IMPRESSAO: 'Aguardando impressão',
  AGUARDANDO_AGENDAMENTO_INSTALACAO: 'Aguardando agendamento da instalação',
  AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO: 'Aguardando aceite do agendamento da instalação',
  AGUARDANDO_INSTALACAO: 'Aguardando instalação',
  AGUARDANDO_IMAGEM_INSTALACAO: 'Aguardando imagem de instalção',
  ATIVO: 'Ativo',
  AGUARDANDO_REMOCAO: 'Aguardando remoção',
  FINALIZADO: 'Finalizado'
};

const orcamentoSituacao = {
  ORCAMENTO: 'Prospecção',
  EM_NEGOCIACAO: 'Em negociação',
  NEGOCIACAO_AVANCADA: 'Negociação avançada',
  AGUARDANDO_PI: 'Aguardando PI',
  AGUARDANDO_APROVACAO: 'Aguardando aprovação',
  APROVADO: 'Aprovado',
  REPROVADO: 'Reprovado'
};

const movimentacaoSituacao = {
  APROVADO: 'Aprovado',
  PENDENTE: 'Pendente',
  FINALIZADO: 'Finalizado',
};

const movimentacaoComercialSituacao = {
  APROVADO: 'Agendado',
  PENDENTE: 'À faturar',
  FINALIZADO: 'Pago',
};

const tipoContaBancaria = {
  POUPANCA: 'Poupança',
  CORRENTE: 'Corrente'
};

const eventoSituacao = {
  PENDENTE: 'Pendente',
  CANCELADO: 'Cancelado',
  CONCLUIDO: 'Concluído',
  ATRASADO: 'Atrasado',
  REAGENDADO: 'Reagendado',
  AGENDADO: 'Agendado',
  PESSOAL: 'Pessoal'
};

const formaPagamento = {
  CARTAO: 'Cartão',
  BOLETO: 'Boleto',
  TRANSFERENCIA_BANCARIA: 'Transferência Bancária',
  PERMUTA: 'Permuta',
};

const faturarPor = {
  VALOR_BRUTO: 'Valor bruto',
  VALOR_LIQUIDO: 'Valor líquido',
};

const anuncianteTipo = {
  ANUNCIANTE: 'Anunciante',
  AGENCIA: 'Agência',
};

const imagemAnuncioTipo = {
  INSTALACAO: 'Instalação',
  MENSAL: 'Mensal',
  REMOCAO: 'Remoção'
};

const parcelaTipo = {
  VEICULACAO: 'Veiculação',
  PRODUCAO: 'Produção'
}

export {
  campanhaSituacao,
  anuncioSituacao,
  orcamentoSituacao,
  movimentacaoSituacao,
  tipoContaBancaria,
  movimentacaoComercialSituacao,
  eventoSituacao,
  formaPagamento,
  faturarPor,
  anuncianteTipo,
  imagemAnuncioTipo,
  parcelaTipo
};
