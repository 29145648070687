import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import CadastroPropostaModal from '../../containers/modais/cadastro-proposta/cadastro-proposta-modal';
import EditarPedido from '../pedidos/editar';
import ConsultaProposta from './consulta';
import EditarProposta from './editar';

const Proposta = () => {
  const {role} = useSelector((store) => store.Auth);

  const [openProposta, setOpenProposta] = useState(false);

  const isAdmin = () => {
    return role === 'LICENCA_ADMINISTRADOR';
  };

  return (
    <>
      <Routes>
        <Route exact path={'/'} element={<ConsultaProposta />} />
        <Route path={`/:propostaId/editar`} element={<EditarProposta />} />
        {isAdmin() && (
          <Route path={`/:pedidoId/aprovar`} element={<EditarPedido />} />
        )}
      </Routes>
      <Grid>
        <Button
          id="button-green"
          onClick={() => setOpenProposta(true)}
          sx={[styles.button, {marginRight: '40px'}]}
          toolbar="true">
          Nova negociação
        </Button>
      </Grid>
      <CadastroPropostaModal
        isOpen={openProposta}
        onClose={() => setOpenProposta(false)}
      />
    </>
  );
};

const styles = {
  button: {
    width: '150px',
  },
};

export default Proposta;
