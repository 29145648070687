import React from 'react'
import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import {ReactComponent as KmMidiaLogo} from '../../../assets/images/km-midia-logo.svg';
import Number from '../../../utils/number';
import String from '../../../utils/string';
import Data from '../../../utils/data';

const Divisor = require('../../../assets/images/divisor-relatorio.png');

const ImpressaoRelatorioMotoristasPagos = ({movimentacoes = [], periodo}) => {
  const valorTotalMototristas = movimentacoes.length > 0 ? movimentacoes.map(movimentacao => movimentacao.valorPago)
    .reduce((acc = 0, value) => acc + parseFloat(value)) : 0;

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img alt={'divisor'} style={{width: '100%', margin: '10px 0'}} src={Divisor} />
      </Grid>
      <Grid item xs={12} sx={styles.reportHeader}>
        <Grid item xs={10}>
          <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>Motoristas pagos</Typography>
          <Typography sx={styles.titleReport}>{`Período: ${Data.dataFormat(periodo, 'MMMM/YYYY') || ' - '}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '100%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell sx={styles.tableHead}>
                Nº
              </TableCell>
              <TableCell sx={styles.tableHead}>
                Nome
              </TableCell>
              <TableCell sx={styles.tableHead}>
                CPF
              </TableCell>
              <TableCell sx={styles.tableHead}>
                Data
              </TableCell>
              <TableCell sx={styles.tableHead} align="right">
                Valor
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {movimentacoes.map((item, index) => (
              <TableRow
                tabIndex={-1}
                key={`item${index}`}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {item?.motorista?.nome}
                </TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>
                  {String.formatCpfCnpj(item?.motorista?.cpf)}
                </TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>
                  {Data.dataFormat(item?.dataPgto, 'DD/MM/YYYY')}
                </TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item?.valorPago)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap', border: 'none'}}>
                <Typography sx={styles.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid sx={styles.totalContainer}>
        <Typography sx={styles.footerText}>
          Total mensal bruto
        </Typography>
        <Typography sx={styles.footerText}>
          {movimentacoes.length}
        </Typography>
        <Typography sx={styles.footerText}>
          {Number.currencyFormat(valorTotalMototristas)}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={[styles.footer, {display: 'none'}]} className={`page-footer`}>
        <img alt={'divisor'} style={{width: '100%', margin: '5px 0'}} src={Divisor} />
        <Grid sx={styles.footerTextContainer}>
          <Typography style={{fontSize: '11px'}}>
            {`Emissão: ${Data.hoje('DD/MM/YYYY')}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px'
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '0.9rem',
    color: '#000'
  },
  table: {
    margin: '30px 0'
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 10px',
    marginTop: '-50px'
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '1050px'
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: '600'
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    marginRight: '10px'
  },
};

export default ImpressaoRelatorioMotoristasPagos;