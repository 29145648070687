import React from 'react';
import {Grid, Modal, Typography, Box, Divider, Stack} from '@mui/material';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import Textarea from '../../../components/textarea/mui-textarea';
import Select from '../../../components/select/mui-select';
import InputDateV2 from '../../../components/mui-input-date';
import {ButtonComponent} from '../../../components/mui-button';

export const ModalWithInput = ({
  openModal,
  onClose,
  onClick,
  textArea,
  disabled,
  titulo,
  input,
  select,
  ButtonPrimary = 'Cancelar',
  ButtonSecundary = 'Aceitar',
  inputValue,
  idButtonPrimary = 'button-cancel',
  idButtonSecundary = 'button',
  mensagem,
  nome,
  inputOnChange,
  label,
  inputDate,
  loadingButton,
  inputStyle = {},
  dividerTitle = false,
  dividerName = false,
  styleTitle = null,
  onCancel,
  width,
  ...props
}) => {
  const checkInput = (type) => {
    switch (type) {
      case input:
        return (
          <InputV2
            label={label}
            value={inputValue}
            onChange={({target}) => inputOnChange(target.value)}
            {...props}
          />
        );
      case textArea:
        return (
          <Textarea
            label={label}
            value={inputValue || ''}
            onChange={({target}) => inputOnChange(target.value)}
            row={6}
            {...props}
          />
        );
      case select:
        return (
          <Select
            label={label}
            value={inputValue || ''}
            onChange={({target}) => inputOnChange(target.value)}
            {...props}
          />
        );
      case inputDate:
        return (
          <InputDateV2
            label={label}
            selected={inputValue || ''}
            onChange={(date) => inputOnChange(date)}
            {...props}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box sx={[styles.modalBody, inputStyle, {width: width || '600px'}]}>
        <Grid container spacing={2}>
          {nome && (
            <Grid item xs={12}>
              <Typography sx={styles.modalnome}>{nome}</Typography>
              {dividerName && <Divider />}
            </Grid>
          )}
          {titulo && (
            <Grid item xs={12}>
              <Typography sx={styleTitle || styles.modalTitle}>
                {titulo}
              </Typography>
              {dividerTitle && <Divider />}
            </Grid>
          )}
          {mensagem && (
            <Grid item xs={12}>
              <Typography sx={styles.modalMessage}>{mensagem}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {checkInput(input || textArea || select || inputDate)}
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <ButtonComponent
                onClick={onCancel ? () => onCancel() : () => onClose()}
                id={idButtonPrimary}
                style={{width: '200px'}}
                value={ButtonPrimary}
              />
              <ButtonComponent
                loading={loadingButton}
                id={idButtonSecundary}
                onClick={onClick}
                disabled={disabled}
                style={{width: '200px'}}
                value={ButtonSecundary}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: (theme) => theme.zIndex.modal,
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
  },
  modalnome: {
    fontSize: 11,
    fontFamily: (theme) => theme.typography.fontFamily.pattern,
    color: '#afafaf',
  },
  modalTitle: {
    fontSize: 20,
    fontFamily: (theme) => theme.typography.fontFamily.patternBold,
    color: '#0A2549',
  },
  modalMessage: {
    fontSize: 14,
    fontFamily: (theme) => theme.typography.fontFamily.patternMedium,
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5px',
  },
  box: {},
};
