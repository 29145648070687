import React from 'react';
import {Grid} from '@mui/material';
import HomeComercial from './home/home-comercial';

const Home = () => {
  return (
    <Grid id="page-container" container>
      <HomeComercial />
    </Grid>
  );
};

export default Home;
