import React from 'react';
import {FIND_ALL_MODELO_ANUNCIO as query} from '../../graphql/queries';
import {SelectBase} from '../../components/select/mui-base';

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 30}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {...searchDTO, search, ativo: true},
  };
};

export const SelectModeloAnuncio = (props) => {
  return (
    <SelectBase
      label="Modelo anúncio"
      responseObjectName="modelos"
      labelProp="nome"
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      query={query}
      {...props}
    />
  );
};
