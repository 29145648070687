import React from 'react';
import {CircularProgress, Box, Typography} from '@mui/material';

export const LoadingPage = ({loading, error}) => {
  if (loading) {
    return (
      <Box
        height="100%"
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.patternBold,
            fontSize: '30px',
          }}>
          Carregando
        </Typography>
        <CircularProgress color="primary" size={'30px'} />
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        height="100%"
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.patternBold,
            fontSize: '30px',
          }}>
          Ops! Ocorreu um erro.
        </Typography>
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.pattern,
            fontSize: '12px',
          }}>
          {error.message}
        </Typography>
      </Box>
    );
  }
  return '';
};
