import config from '../config/config';

const File = {
  getFileAuth: (idArquivo, nomeArquivo) => {
    const Auth = JSON.parse(window.localStorage.getItem('auth') || '{}');

    if (Auth.access_token) {
      return `${config.AMAZON_S3_BASE_URL}${idArquivo}/${nomeArquivo}?access_token=${Auth.access_token}`;
    } else {
      return '';
    }
  },
  getImageAuth: idFoto => {
    const Auth = JSON.parse(window.localStorage.getItem('auth') || '{}');

    if (Auth.access_token) {
      return `${config.AMAZON_S3_BASE_URL}${idFoto}?access_token=${Auth.access_token}`;
    } else {
      return '';
    }
  },
  b64toBlob: (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
};

export default File;