import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaAnunciante from './consulta';
import EditarAnunciante from './editar';
import CadastrarAnunciante from './cadastrar';

const Anunciante = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaAnunciante />} />
      <Route path={`/:anuncianteId/editar`} element={<EditarAnunciante />} />
      <Route path={`/criar`} element={<CadastrarAnunciante />} />
    </Routes>
  );
};

export default Anunciante;
