import React from 'react';
import Lottie from 'react-lottie-player';

const LottieIcon = ({icon, height = 30, width = 30, ...props}) => {
  const defaultOptions = {
    loop: true,
    play: true,
    animationData: icon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Lottie
      {...defaultOptions}
      height={height}
      width={width}
      style={{margin: 5, width, height}}
      {...props}
    />
  )
};

export default LottieIcon;
