import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaMateriaisVenda from './consulta';

const MateriaisVenda = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaMateriaisVenda />} />
    </Routes>
  );
};

export default MateriaisVenda;
