import React from 'react';
import {Grid} from '@mui/material';
import {CameraAltRounded} from '@mui/icons-material';
import Image from './image';

const ImagemContainer = ({image, label, onSetImagem, onClickImage, onMouseDown, sx = {}}) => {
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} sx={sx.imagemContainer}>
      {image && (
        <Image
          id={image}
          sx={[styles.imagem, sx.imagem]}
          onError={() => onSetImagem && onSetImagem(null)}
          alt={label}
          onMouseDown={onMouseDown && onMouseDown}
          onClick={e =>
            onClickImage && onClickImage(e, {
              id: image,
              titulo: label,
            })
          }
        />
      )}
      {!image && (
        <CameraAltRounded sx={styles.iconCamera} />
      )}
    </Grid>
  )
}

const styles = {
  imagem: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
    maxHeight: '250px',

    '@media (min-width: 1024px)': {
      maxHeight: '170px'
    },

    '@media (min-width: 1367px)': {
      maxHeight: '200px'
    },
  },
  iconCamera: {
    color: '#fff',
    height: '30%',
    width: '30%',
  }
};

export default ImagemContainer;