import React from 'react';
import FormularioExecutivo from './formulario';

const CadastrarLicencas = () => {
  return (
    <FormularioExecutivo />
  );
};

export default CadastrarLicencas;
