import {DescriptionOutlined, DirectionsCar, Report} from '@mui/icons-material';
import {Grid, Typography} from '@mui/material';
import React from 'react';
import Data from '../../utils/data';
import ImagemAnunciante from './imagem-anunciante';

const Card = ({data, token, id, onClick}) => {
  const renderVeiculos = ['AGUARDANDO_APROVACAO', 'APROVADO'].includes(
    data?.situacao,
  );

  return (
    <Grid sx={styles.gridTitulo} title={'Card'} id={id} onClick={onClick}>
      {!!data?.orcamentos?.filter((o) => o.reprovadoMotivo).length && (
        <Report sx={styles.alertIcon} />
      )}
      <Grid sx={styles.gridDataFoto}>
        <Grid>
          <Typography sx={styles.tituloDataCadastro}>
            Última alteração em
          </Typography>
          <Typography sx={styles.dataCadastro}>
            {Data.dataFormat(data?.dataAlteracao, 'DD/MM/YYYY [às] HH:mm')}
          </Typography>
        </Grid>
        <Grid sx={styles.avatarImg}>
          <ImagemAnunciante
            fotoPerfil={data?.anunciante?.fotoPerfil}
            width={'40px'}
            height={'40px'}
            token={token}
          />
        </Grid>
      </Grid>
      <Grid sx={styles.gridTituloNomeFantasia}>
        <Typography sx={styles.nomeFantasia}>
          {data?.anunciante?.nomeFantasia}
        </Typography>
        <Typography sx={styles.tituloCard}>{data?.titulo}</Typography>
        {data?.role === 'LICENCA_ADMINISTRADOR' && (
          <Typography sx={styles.comercialCard}>
            {data?.comercial?.nomeRazao}
          </Typography>
        )}
      </Grid>
      <Grid sx={styles.containerValorTags}>
        <Grid sx={styles.gridContainerTags}>
          <Grid sx={styles.gridIconQuantidade}>
            <DescriptionOutlined sx={styles.iconQuantidade} />
            <Typography sx={styles.textQuantidadeCarros}>
              {data?.orcamentos?.length || 0}
            </Typography>
          </Grid>
        </Grid>
        {renderVeiculos && (
          <Grid sx={styles.gridContainerTags}>
            <Grid sx={styles.gridIconQuantidade}>
              <DirectionsCar sx={styles.iconQuantidade} />
              <Typography sx={styles.textQuantidadeCarros}>
                {data?.orcamentos?.find((o) => o.situacao === 'APROVADO')
                  ?.quantidadeVeiculos || 0}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  gridContainerTags: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  gridIconQuantidade: {
    display: 'flex',
    flexDirection: 'row',
    color: '#657496',
    border: '1px solid #8995B0',
    borderRadius: '8px',
    padding: '4px 8px',
    marginLeft: '5px',
  },
  containerValorTags: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  textQuantidadeCarros: {
    fontFamily: 'AktivGrotesk-Medium',
    fontSize: '12px',
  },
  gridDataFoto: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px',
    alignItems: 'center',
  },
  tituloDataCadastro: {
    color: '#8995B0',
    fontFamily: 'AktivGrotesk',
    fontSize: '12px',
  },
  dataCadastro: {
    color: '#8995B0',
    fontFamily: 'AktivGrotesk-Bold',
    fontSize: '12px',
  },
  gridTitulo: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '240px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    minWidth: '240px',
    padding: '20px',
    borderRadius: '6px',
    margin: '0px 0px 16px 0px',
    border: '2px solid rgba(0, 0, 0, 0)',
    '&:hover': {
      border: '2px solid rgba(0, 131, 230, 0.5)',
    },
  },
  tituloCard: {
    fontFamily: 'AktivGrotesk',
    fontSize: '14px',
    color: '#929292',
    width: '100%',
    whiteSpace: 'break-spaces',
  },
  nomeFantasia: {
    fontFamily: 'AktivGrotesk-Bold',
    fontSize: '18px',
    whiteSpace: 'normal',
    marginBottom: '8px',
  },
  valorCampanha: {
    color: '#007BE1',
    fontSize: '18px',
    fontFamily: 'AktivGrotesk-Bold',
    marginBottom: '8px',
  },
  tagModeloAnuncio: {
    fontFamily: 'AktivGrotesk-Medium',
    fontSize: '12px',
    color: '#0A2549',
    padding: '4px 8px 4px 8px',
    borderRadius: '8px',
  },
  iconQuantidade: {
    height: '17px',
    width: '16px',
    marginRight: '10px',
  },
  gridTituloNomeFantasia: {
    margin: '12px 0',
  },
  avatarImg: {
    display: 'flex',
    alignItems: 'center',
  },
  alertIcon: {
    position: 'absolute',
    top: '0px',
    color: '#DC3131',
    left: '210px',
    height: '30px',
    width: '30px',
  },
  comercialCard: {
    fontFamily: 'AktivGrotesk',
    fontSize: '12px',
    width: '100%',
    whiteSpace: 'break-spaces',
    fontWeight: '600',
  },
};

export default Card;
