import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, HashRouter as Router, Routes} from 'react-router-dom';
import {SidebarComponent} from '../components/sidebar';
import {ToolBarComponent} from '../components/toolbar';
import {
  ANUNCIANTES_ROUTE,
  CAMPANHAS_ROUTE,
  DASHBOARD_ROUTE,
  EXECUTIVOS_ROUTE,
  EXTRATO_PAGAR_ROUTE,
  EXTRATO_RECEBER_ROUTE,
  MATERIAIS_VENDA_ROUTE,
  MODELO_ANUNCIOS_ROUTE,
  PAINEL_ANUNCIANTE_ROUTE,
  PERFIL_ROUTE,
  PROPOSTAS_ROUTE,
  TABELA_PRECO_ROUTE,
} from '../constants/routes';
import {GET_COMERCIAL_LOGADO} from '../graphql/queries';
import {persistUser} from '../redux/Auth/slice';
import AnunciantesView from '../views/anunciantes';
import CampanhasView from '../views/campanha';
import ExecutivosView from '../views/executivo';
import ExtratoPagarView from '../views/extrato-repasse/pagar';
import ExtratoReceberView from '../views/extrato-repasse/receber';
import HomeView from '../views/home';
import MateriaisVendaView from '../views/materiais-venda';
import ModeloAnunciosView from '../views/modelo-anuncio';
import PainelAnuncianteView from '../views/painel-anunciante';
import PerfilView from '../views/perfil';
import PropostasView from '../views/propostas';
import TabelaPrecoView from '../views/tabela-preco';
import ScrollTop from './scroll-top';

export const RouterComponent = () => {
  const dispatch = useDispatch();

  const [role, setRole] = useState(undefined);
  const {sidebarOpen} = useSelector((state) => state.Sidebar);

  const comercialLogadoQuery = useQuery(GET_COMERCIAL_LOGADO);

  useEffect(() => {
    if (!comercialLogadoQuery.loading && !comercialLogadoQuery.error) {
      const comercial = comercialLogadoQuery.data.comercial;
      dispatch(persistUser(comercial));
      setRole(comercial?.role);
    }
  }, [comercialLogadoQuery, dispatch]);

  return (
    <Router>
      <ScrollTop />
      <FullWrapper>
        <SidebarComponent role={role} />
        <Content open={sidebarOpen}>
          <ToolBarComponent />
          <Main>
            <Routes>
              <Route path={`${DASHBOARD_ROUTE}/*`} element={<HomeView />} />
              <Route
                path={`${CAMPANHAS_ROUTE}/*`}
                element={<CampanhasView />}
              />
              <Route
                path={`${PROPOSTAS_ROUTE}/*`}
                element={<PropostasView />}
              />
              <Route
                path={`${ANUNCIANTES_ROUTE}/*`}
                element={<AnunciantesView />}
              />
              <Route path={`${PERFIL_ROUTE}/*`} element={<PerfilView />} />
              <Route
                path={`${EXTRATO_PAGAR_ROUTE}/*`}
                element={<ExtratoPagarView />}
              />
              <Route
                path={`${EXTRATO_RECEBER_ROUTE}/*`}
                element={<ExtratoReceberView />}
              />
              <Route
                path={`${EXECUTIVOS_ROUTE}/*`}
                element={<ExecutivosView />}
              />
              <Route
                path={`${PAINEL_ANUNCIANTE_ROUTE}/*`}
                element={<PainelAnuncianteView />}
              />
              <Route
                path={`${TABELA_PRECO_ROUTE}/*`}
                element={<TabelaPrecoView />}
              />
              <Route
                path={`${MATERIAIS_VENDA_ROUTE}/*`}
                element={<MateriaisVendaView />}
              />
              {role === 'LICENCA_ADMINISTRADOR' && (
                <Route
                  path={`${MODELO_ANUNCIOS_ROUTE}/*`}
                  element={<ModeloAnunciosView />}
                />
              )}
              <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} />} />
            </Routes>
          </Main>
        </Content>
      </FullWrapper>
    </Router>
  );
};

const Content = styled.div`
  display: inline-block;
  width: 100%;
  transition: padding-left 0.2s ease-in-out;
  padding-left: ${({theme, open}) =>
    open ? `calc(${theme.sidebarWidth} + 300px)` : theme.sidebarWidth};
`;

const FullWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.palette.paterns.background};
`;

const Main = styled.main`
  width: 100%;
  padding: 0;
  height: ${({theme}) => `calc(100vh - ${theme.toolbarHeight})`};
  overflow-y: auto;
`;
