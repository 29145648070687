import React from 'react';
import {FIND_ANUNCIANTE_BY_COMERCIAL as query} from '../../graphql/queries';
import string from '../../utils/string';
import {SelectBase} from '../../components/select/mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map(item => ({
      ...item,
      label: `${string.formatCpfCnpj(item.cnpj)} - ${item.nomeFantasia}`,
      value: item.id
    }))
    : [];
};

const getVariablesCnpj = ({pageNumber, search = '', pageSize = 30}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nomeFantasia', sortDir: 'ASC'},
    searchDTO: {cpfCnpj: string.removeSpecialChars(search)},
  };
};

const getVariables = ({pageNumber, search = '', pageSize = 30}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nomeFantasia', sortDir: 'ASC'},
    searchDTO: {search},
  };
};

export const SelectAnunciantes = ({onCreateOption, ...props}) => {
  return (
    <SelectBase
      label="Cliente"
      responseObjectName="anunciantes"
      labelProp="nomeFantasia"
      getVariables={getVariables}
      query={query}
      onCreateOption={onCreateOption}
      {...props}
    />
  );
};

export const SelectAnunciantesCnpj = ({onCreateOption, ...props}) => {
  return (
    <SelectBase
      label="Cliente"
      placeholder="Digite o CNPJ do cliente"
      responseObjectName="anunciantes"
      transformOptions={transformOptions}
      getVariables={getVariablesCnpj}
      query={query}
      onCreateOption={onCreateOption}
      {...props}
    />
  );
};
