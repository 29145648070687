export const typography = {
  fontFamily: {
    roboto: 'Roboto',
    pattern: 'AktivGrotesk',
    patternRegular: 'AktivGrotesk-Regular',
    patternLight: 'AktivGrotesk-Light',
    patternMedium: 'AktivGrotesk-Medium',
    patternBold: 'AktivGrotesk-Bold',
  },
  fontWeight: {
    bold: 700,
    default: 400,
    light: 300,
  },
};
