import Axios from 'axios';
import config from '../../config/config';

const Auth = Axios.create({
  baseURL: `${config.OAUTH_BASE_URL}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  auth: {
    username: `${config.AUTH_USERNAME}`,
    password: `${config.AUTH_PASSWORD}`,
  },
});

const AuthApi = Axios.create({
  baseURL: `${config.AUTH_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const RedefinirSenhaApi = Axios.create({
  baseURL: `${config.AUTH_BASE_URL}/public/v1/usuario/redefinir-senha`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getToken = async (username, password) => {
  const {data} = await Auth.post(
    '',
    `grant_type=password&username=${username}&password=${password}&client_id=${config.AUTH_USERNAME}`,
  );

  return data;
};

export const logout = async token => {
  try {
    await AuthApi.post(
      `logout?token=${token}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const redefinirSenha = async email => {
  try {
    const response = await RedefinirSenhaApi.post('', {
      email,
    });

    if (!response.ok) {
      throw new Error(response.data.mensagem);
    }

    return `Enviamos para seu e-mail ${
      response.data && response.data.mensagem ? response.data.mensagem : ''
    } um link para redefinir sua senha.`;
  } catch (error) {
    throw error;
  }
};
