import config from '../config/config';
import Geocode from 'react-geocode';
import string from '../utils/string';

Geocode.setApiKey(`${config.GOOGLE_MAPS_API_KEY}`);
Geocode.setLanguage('pt-BR');

export const coordenadasPorEndereco = async endereco => {
  try {
    if (!endereco) {
      throw new Error('Nenhum endereço informado');
    }

    const address = `${endereco.logradouro ? `${endereco.logradouro}, ` : ''}${
      endereco.numero ? `${endereco.numero}, ` : ''
    }${endereco.cidade ? `${endereco.cidade.label}` : ''}`;

    if (string.isEmpty(address)) {
      return null;
    }

    const response = await Geocode.fromAddress(address);

    if (response.status !== 'OK') {
      throw new Error('Não foi possível buscar as coordenadas para o endereço');
    }
    const { location } = response.results[0].geometry;

    if (!location) {
      throw new Error('Não foi possível buscar as coordenadas para o endereço');
    }

    return location;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const coordenadasPorCidade = async nomeCidade => {
  try {
    if (!nomeCidade) {
      throw new Error('Nenhum nome de cidade informado');
    }

    const response = await Geocode.fromAddress(nomeCidade);

    if (response.status !== 'OK') {
      throw new Error('Não foi possível buscar as coordenadas para a cidade');
    }
    const { location } = response.results[0].geometry;

    if (!location) {
      throw new Error('Não foi possível buscar as coordenadas para a cidade');
    }

    return location;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};
