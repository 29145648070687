import {useLazyQuery, useMutation} from '@apollo/client';
import {Close, Save} from '@mui/icons-material';
import {Box, Grid} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {
  alertCustom,
  confirmAlertCustom,
} from '../../../components/confirm-alert/confirm-alert';
import {ButtonComponent} from '../../../components/mui-button';
import Textarea from '../../../components/textarea/mui-textarea';
import {toastNotification} from '../../../components/toastify';
import {CRIAR_PROPOSTA} from '../../../graphql/mutation';
import {COUNT_PROPOSTAS_ABERTAS_ANUNCIANTE} from '../../../graphql/queries';
import Objects from '../../../utils/objects';
import {SelectAnunciantes} from '../../selects/anunciantes';
import CadastroClienteSimplificadoModal from './cadastro-cliente-simplificado-modal';

const CadastroProposta = ({onClose}) => {
  const navigate = useNavigate();

  const comercialLogado = useSelector((store) => store.Auth);

  const [anunciante, setAnunciante] = useState(undefined);
  const [anuncianteCadastrado, setAnuncianteCadastrado] = useState(false);
  const [proposta, setProposta] = useState({});
  const [openCadastroCliente, setOpenCadastroCliente] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fieldErro, setFieldErro] = useState([]);

  const [loadPropostasPendentes, {loading, data}] = useLazyQuery(
    COUNT_PROPOSTAS_ABERTAS_ANUNCIANTE,
  );
  const [createProposta] = useMutation(CRIAR_PROPOSTA);

  const formularioOk = () => {
    const erros = [];
    if (!anunciante?.id) erros.push('anuncinte');

    setFieldErro(erros);
    return erros.length;
  };

  const handleClickSave = () => {
    if (formularioOk() > 0) return;

    if (
      anunciante?.comercial?.id &&
      anunciante?.comercial?.id !== comercialLogado?.id &&
      anunciante?.comercial?.id !== comercialLogado?.responsavelId
    ) {
      alertCustom({
        title: `Este cliente é atendido por outro comercial`,
        message: 'Não é possível criar propostas',
        onConfirm: () => null,
        onClickOutside: () => null,
      });

      return;
    }

    loadPropostasPendentes({
      variables: {
        anunciante: Objects.jsToEntity(anunciante),
      },
    });
  };

  useEffect(() => {
    if (loading || !data) return;

    if (!data?.propostas || data.propostas.length === 0) return onSave();

    confirmAlertCustom({
      title: `Existe${data.propostas.length === 1 ? '' : 'm'} ${
        data.propostas.length
      } negociaç${
        data.propostas.length === 1 ? 'ão' : 'ões'
      } em aberto para esse cliente!`,
      message: 'Deseja mesmo criar uma nova negociação para esse cliente?',
      confirmLabel: 'Criar negociação',
      cancelLabel: 'Abrir negociação',
      onConfirm: onSave,
      onCancel: () => {
        onClose();
        navigate(`/propostas/${data.propostas[0].id}/editar`);
      },
      onClickOutside: () => null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  useEffect(() => {
    if (!anuncianteCadastrado || !anunciante?.id) return;

    handleClickSave();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anuncianteCadastrado, anunciante]);

  const onSave = () => {
    setSubmitting(true);

    createProposta({
      variables: {
        proposta: {
          titulo: proposta.titulo,
          anunciante: {
            id: anunciante.id,
            nomeFantasia: anunciante.nomeFantasia,
          },
          comercial: {id: comercialLogado?.id},
          situacao: 'ORCAMENTO',
          observacao: proposta.observacao,
        },
      },
    })
      .then(({data}) => {
        setSubmitting(false);
        toastNotification({
          message: 'Negociação cadastrada com sucesso',
          type: 'success',
        });
        onClose();
        navigate(`/propostas/${data.createProposta.id}/editar`);
      })
      .catch((error) => {
        setSubmitting(false);
        toastNotification({message: error.message, type: 'error'});
      });
  };

  const handleCloseModalAnunciante = () => {
    setOpenCadastroCliente(false);
  };

  return (
    <Grid sx={styles.container}>
      <Grid className="styled-scroll" sx={styles.form}>
        <Grid sx={styles.content} container>
          <Formik
            validationSchema={PropostaScheme}
            enableReinitialize
            initialValues={{
              ...proposta,
              anunciante,
            }}>
            {() => (
              <Box component="form" sx={{width: '100%'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      label="Anunciante"
                      key="anunciante"
                      name="anunciante"
                      component={SelectAnunciantes}
                      onChange={(anunciante) => {
                        setAnunciante(anunciante);
                        setFieldErro([]);
                      }}
                      onCreateOption={() => setOpenCadastroCliente(true)}
                      error={fieldErro.includes('anuncinte')}
                      creatable
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textarea
                      sx={styles.textAreaObservacoes}
                      name="observacao"
                      label="Observação"
                      value={proposta.observacao || ''}
                      onChange={({target}) =>
                        setProposta({...proposta, observacao: target.value})
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
          <CadastroClienteSimplificadoModal
            isOpen={openCadastroCliente}
            onClose={handleCloseModalAnunciante}
            onSave={(anunciante) => {
              setAnunciante(anunciante);
              setAnuncianteCadastrado(true);
              handleCloseModalAnunciante();
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={styles.footer}>
        <ButtonComponent
          id="button-cancel-empty"
          sx={styles.buttons}
          value="Cancelar"
          type="button"
          icon={<Close />}
          onClick={onClose}
          disabled={isSubmitting}
        />
        <ButtonComponent
          id="button"
          sx={styles.buttons}
          type="button"
          loading={isSubmitting}
          icon={<Save />}
          value={'Salvar'}
          onClick={handleClickSave}
          disabled={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

const PropostaScheme = Yup.object().shape({
  anunciante: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
});

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    paddingTop: '16px',
    flexDirection: 'column',
  },
  form: {
    overflowY: 'auto',
    height: 'inherit',
    padding: '12px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonIconLeft: {
    fontSize: 20,
    margin: '0 10px 0 -20px',
  },
  buttonIconRight: {
    fontSize: 20,
    margin: '0 -20px 0 10px',
  },
  buttons: {
    width: '100%',
    marginBottom: 0,
    marginLeft: '10px',

    '@media (min-width: 1024px)': {
      width: '185px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    marginTop: 'auto',
    padding: '15px',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  textAreaObservacoes: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '80px',
    marginBottom: 0,
  },
};

export default CadastroProposta;
