import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import FiltroMotoristas from './filtro-motoristas';
import {RoundTooltipButton} from '../../components/mui-button';
import {FIND_ALL_CIDADES_CAMPANHA} from '../../graphql/queries';
import {campanhaSituacao} from '../../constants/enum-labels';
import TableCellOrdered from '../../components/table-cell-ordered';
import MotoristasCampanhaCidade from './components/motoristas';

const columns = [
  {id: 'cidade', label: 'Cidade', campo: 'cidade.nome', format: ({cidade}) => cidade.nomeEUf},
  {id: 'modeloAnuncio', label: 'Modelo', campo: 'modeloAnuncio.nome', format: ({modeloAnuncio}) => modeloAnuncio.nome},
  {id: 'anuncios', label: 'Motoristas', campo: 'quantidadeVeiculos', format: ({anuncios, quantidadeVeiculos}) => `${anuncios.length} / ${quantidadeVeiculos}`},
  {id: 'situacao', label: 'Situação', format: ({situacao}) => campanhaSituacao[situacao]},
];

const Cidades = ({campanha = {}}) => {
  const [searchDTO, setSearchDTO] = useState({});
  const [cidades, setCidades] = useState([]);
  const [orderBy, setOrderBy] = useState({campo: 'cidade.nome', asc: true});

  const cidadesQuery = useQuery(FIND_ALL_CIDADES_CAMPANHA, {
    variables: {
      searchDTO: {
        campanhaId: campanha.id
      },
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (cidadesQuery.loading || cidadesQuery.error) return;
    setCidades(cidadesQuery.data?.cidades?.content || []);
  }, [cidadesQuery]);

  const handleFiltrar = (filtros) => {
    setSearchDTO(filtros);
  };

  return (
    <Grid container>
      <Grid item container xs={12} mb="0.83em">
        <Grid item xs={12} md={10}>
          <FiltroMotoristas handleFiltrar={handleFiltrar} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="status"
                    align="center"
                    style={{width: '50px'}}
                  />
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cidades.map((campanhaCidade) => (
                  <CidadeRow
                    key={campanhaCidade.id}
                    campanhaCidade={campanhaCidade}
                    search={searchDTO}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const CidadeRow = ({campanhaCidade, search}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={styles.cidadeRow}
        hover
        role="checkbox"
        tabIndex={-1}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={styles.openButtonCell}>
          <RoundTooltipButton
            id="icon-primary"
            title={'Ver motoristas'}
            icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            sx={styles.openButton}
          />
        </TableCell>
        {columns.map((column, index) => {
          const value = campanhaCidade[column.id];
          return (
            <TableCell key={index} align={column.align}>
              {column.format ? column.format(campanhaCidade) : value}
            </TableCell>
          );
        })}
      </TableRow>
      {campanhaCidade.anuncios.length > 0 && (
        <MotoristasCampanhaCidade
          open={open}
          campanhaCidade={campanhaCidade}
          search={search}
        />
      )}
    </>
  );
};

const styles = {
  tableButtonsContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'flex-end',
    cursor: 'default',
    backgroundColor: '#ededed',
    top: 5,
    right: 5,
    padding: '6px 10px',
    borderRadius: 8,
  },
  openButtonCell: {
    padding: 0,
    width: 50
  },
  openButton: {
    background: '#fff0 !important',
    color: '#868686 !important',
    '&:hover': {
      background: '#fff0 !important',
      boxShadow: 'none',
    },
  },
  cidadeRow: {
    height: '73px',
    '& > *': {
      borderBottom: 'unset'
    }
  }
};

export default Cidades;
