import {Box, Checkbox, FormControlLabel, Grid, Paper} from '@mui/material';
import React, {useEffect, useState} from 'react';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Number from '../../utils/number';

const FormularioModeloAnuncio = ({modelo = {}}) => {
  const [readOnly] = useState(true);
  const [modeloAnuncio, setModeloAnuncio] = useState({
    frente: false,
    laterais: false,
    lateraisRecorte: false,
    traseira: false,
    interno: false,
    interativo: false,
    ativo: false,
  });

  useEffect(() => {
    if (!modelo?.id) return;

    setModeloAnuncio({
      ...modelo,
      valorMotorista: Number.currencyFormat(modelo.valorMotorista),
      valorBonusInstalacao: Number.currencyFormat(modelo.valorBonusInstalacao),
      valorVenda: Number.currencyFormat(modelo.valorVenda),
      valorInstaladorPorInstalacao: Number.currencyFormat(
        modelo.valorInstaladorPorInstalacao,
      ),
      porcentagemInstalacao: Number.regraDeTresPorcentagem(
        modelo.valorBonusInstalacao,
        modelo.valorMotorista,
        true,
      ),
      percentualImpactos: Number.format(modelo.percentualImpactos),
      diasPeriodo: Number.format(modelo.diasPeriodo),
      tabelaEspecial: Number.format(modelo.tabelaEspecial),
      tabelaUm: Number.format(modelo.tabelaUm),
      tabelaDois: Number.format(modelo.tabelaDois),
      tabelaTres: Number.format(modelo.tabelaTres),
      valorTabelaEspecial: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaEspecial,
        true,
      ),
      valorTabelaUm: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaUm,
        true,
      ),
      valorTabelaDois: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaDois,
        true,
      ),
      valorTabelaTres: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaTres,
        true,
      ),
    });
  }, [modelo]);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item md={4} xs={6}>
              <InputV2
                name="nome"
                label="Titulo"
                value={modeloAnuncio.nome}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <InputV2
                name="descricao"
                label="Descrição"
                value={modeloAnuncio.descricao}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                name="percentualImpactos"
                label="Porcetagem de impactos"
                mask="porcento"
                value={modeloAnuncio.percentualImpactos || 0}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                name="diasPeriodo"
                label="Tempo de veiculação"
                mask="numero"
                endAdornment="dias"
                value={modeloAnuncio.diasPeriodo || 0}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <InputV2
                name="valorVenda"
                label="Valor de venda"
                mask="moeda"
                value={modeloAnuncio.valorVenda}
                defaultValue={0}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <Box sx={styles.box}>
                <InputV2
                  label="Valor recomendado"
                  name="tabelaTres"
                  mask="porcento"
                  value={modeloAnuncio.tabelaTres}
                  defaultValue={0}
                  disabled={readOnly}
                />
                <InputV2
                  name="valorTabelaTres"
                  label="Valor"
                  mask="moeda"
                  value={modeloAnuncio.valorTabelaTres}
                  defaultValue={0}
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <InputV2
                name="valorInstaladorPorInstalacao"
                label="Valor da produção"
                mask="moeda"
                value={modeloAnuncio.valorInstaladorPorInstalacao || 0}
                disabled={readOnly}
              />
            </Grid>
            <Grid item lg={3} xs={6}>
              <InputV2
                name="valorMotorista"
                label="Valor do motorista"
                mask="moeda"
                value={modeloAnuncio.valorMotorista}
                defaultValue={0}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xl={4} xs={6}>
              <Box sx={styles.box}>
                <InputV2
                  label={'Bonus de instalação'}
                  name="porcentagemInstalacao"
                  mask="porcento"
                  value={modeloAnuncio.porcentagemInstalacao}
                  defaultValue={0}
                  disabled={readOnly}
                />
                <InputV2
                  label="Valor"
                  name="valorBonusInstalacao"
                  mask="moeda"
                  value={modeloAnuncio.valorBonusInstalacao}
                  defaultValue={0}
                  disabled={readOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.componentesContainer}>
                <Grid container spacing={1} sx={styles.checboxContainer}>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.frente}
                        label="Capô"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.laterais}
                        label="Portas Laterais"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.lateraisRecorte || false}
                        label="Portas Laterais (Recorte)"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.traseira}
                        label="Vidro Traseiro"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.interno}
                        label="Mídia Interna"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.interativo}
                        label="Interativo"
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.box}>
                <FormControlLabel
                  control={<Checkbox color="primaryPatern" />}
                  checked={modeloAnuncio.ativo}
                  label="Ativo"
                  sx={styles.checkbox}
                  disabled={readOnly}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabelaPrecoContainer: {
    position: 'relative',
    borderRadius: 'px',
    margin: '10px 0 30px 0 !important',
    border: '2px solid #0083e6',
    width: '100%',
    padding: '20px',
  },
  componentesContainer: {
    borderRadius: '6px',
    border: '2px solid #0083e6',
  },
  buttonWrapper: {
    width: '256px',
  },
  checkbox: {
    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#657496',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  tabelaTitulo: {
    position: 'absolute',
    backgroundColor: '#fff',
    color: '#0083e6',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '2px',
    top: '-14px',
    left: '15px',
  },
  checboxContainer: {
    justifyContent: 'space-between',
    padding: '0 30px 0 20px',
  },
};

export default FormularioModeloAnuncio;
