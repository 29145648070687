import 'symbol-observable';
import React from 'react';
import {Provider as ReduxProvider, useSelector, useDispatch} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {ApolloProvider} from '@apollo/client';
import {Helmet} from 'react-helmet';
import {ToastContainer} from 'react-toastify';
import {RouterComponent as RouterDash} from './router/router';
import {RouterAuthComponent as RouterAuth} from './router/router-auth';
import {loginSuccess, clearUser} from './redux/Auth/slice';
import {theme} from './theme/mui-theme';
import {GlobalStyle} from './theme';
import {store} from './redux';
import initApolloClient from './initApolloClient';

import 'moment/locale/pt-br';

function Apollo({children}) {
  const dispatch = useDispatch();
  const [client, setClient] = React.useState(null);
  const {token, refresh_token} = useSelector(state => state.Auth);

  const onSucessRefresh = authorization => {
    dispatch(loginSuccess(authorization));
  };

  const configureApollo = () => {
    const client = initApolloClient({refresh_token, onSucessRefresh});

    setClient(client);
  };

  React.useEffect(() => {
    token && configureApollo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!client) {
    return (
      <div />
    );
  };

  return (
    <ApolloProvider client={client}>{children}</ApolloProvider >
  );
}

const Main = () => {
  const dispatch = useDispatch();
  const {logged} = useSelector(state => state.Auth);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const jsonAuthData = params.get('auth-data');
    let qsAuth;

    if (jsonAuthData) {
      qsAuth = JSON.parse(atob(jsonAuthData));
    }

    const Auth = qsAuth || JSON.parse(window.localStorage.getItem('auth'));
    if (Auth) {
      dispatch(loginSuccess(Auth));
    } else {
      dispatch(clearUser());
    }
  });

  if (logged === undefined) {
    return null;
  }

  if (!logged) {
    return (
      <RouterAuth />
    );
  };

  return (
    <Apollo>
      <RouterDash />
    </Apollo>
  );
};

function App() {
  const [isShown] = React.useState(true);
  window.sessionStorage.setItem('isSidebarOpen', true);

  return (
    <ReduxProvider store={store()}>
      <ThemeProvider theme={theme}>
        <>
          <Helmet>
            <html lang="pt-BR" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,900;1,500&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;600;700;900&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;400;500;600;700;900&display=swap" rel="stylesheet" />
          </Helmet>
          <GlobalStyle />
          {isShown ? <Main /> : <div />}
          <ToastContainer />
        </>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
