const pagar = [
  {
    value: 'MOTORISTA',
    label: 'Motoristas',
  },
  {
    value: 'INSTALADOR',
    label: 'Produção',
  },
  {
    value: 'ROYALTIES',
    label: 'Royalties Km Mídia',
  },
];

const receber = [
  {
    value: 'ANUNCIANTE',
    label: 'Campanhas',
  },
];

const getDestinosRecebimento = (tipoLancamento) => {
  switch (tipoLancamento) {
    case 'PAGAR': return pagar;
    case 'RECEBER': return receber;
    default: return [];
  }
}

export default getDestinosRecebimento;