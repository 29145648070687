export const maringa = {lat: -23.421, lng: -51.9331};
export const centroBrasil = {lat: -13.888254654607433, lng: -50.84689243001579};

export default function getMarkers() {
  const cnt = 10;
  const markers = [];
  for (let i = 0; i < cnt; i++) {
    markers.push({
      id: i,
      title: 'marker: ' + i,
      lat: maringa.lat + 0.003 * i,
      lng: maringa.lng + 0.003 * i
    });
  }
  return markers;
}

export const normalizeCampanhas = (campanhas = []) => {
  if (campanhas.length === 0) return [];

  return campanhas.reduce((acc, item) => {
    acc.push({
      id: item.id,
      title: 'marker: ' + item.id,
      lat: item.localizacaoAtual && item.localizacaoAtual.latitude,
      lng: item.localizacaoAtual && item.localizacaoAtual.longitude,
      ...item
    });

    return acc;
  }, []);
};
