import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Formik} from 'formik';
import {useMutation} from '@apollo/client';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';
import {
  OpenInNew,
  Person,
  Visibility,
} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Number from '../../utils/number';
import {TextAreaV2} from '../../components/textarea/mui-textarea';
import {ButtonComponent} from '../../components/mui-button';
import {
  campanhaSituacao,
  faturarPor,
  formaPagamento,
} from '../../constants/enum-labels';
import DragNDrop from '../../components/drag-n-drop';
import {toastNotification} from '../../components/toastify';
import InputDate from '../../components/mui-input-date';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import Data from '../../utils/data';
import usePainelService from '../../services/PainelService';
import {GERAR_USUARIO_SENHA} from '../../graphql/mutation';
import CidadeItem from './components/cidade-item';

moment.locale('pt-br');

const FormularioCampanha = ({campanha = {}, role}) => {
  const painelService = usePainelService();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [readOnly] = useState(true);
  const [loadingAccessPainel, setLoadingAccessPainel] = useState(false);
  const [banner, setBanner] = useState(campanha.bannerCampanha);

  const [gerarUsuario] = useMutation(GERAR_USUARIO_SENHA);

  const filtrarParcelas = (movimentacao) => {
    return (
      (!movimentacao.parcelaTipo ||
        movimentacao.parcelaTipo === 'VEICULACAO') &&
      movimentacao.tipo === 'CREDITO'
    );
  };

  const filtrarParcelasProducao = (movimentacao) => {
    return (
      movimentacao.parcelaTipo === 'PRODUCAO' && movimentacao.tipo === 'CREDITO'
    );
  };

  const getFormaPagamento = (forma) => {
    if (!forma) return null;

    return {value: forma, label: formaPagamento[forma]};
  };

  const gerarUsuarioSenhaAnunciante = (anunciante) => {
    if (!anunciante.emailUsuario) {
      toastNotification({
        message: 'Preencha o e-mail do usuário para gerar o usuário',
        type: 'error',
      });
      return;
    }

    gerarUsuario({
      variables: {
        anunciante: {
          id: anunciante.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.gerarUsuarioSenhaAnunciante}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.gerarUsuarioSenhaAnunciante);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    const campanhaVeiculacaoMensal = campanha?.cidades
      ?.map(c => c.valorPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    const totalInstalacaoCampanha = campanha?.cidades
      ?.map(c => c.instalacaoPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    setFormData({
      ...campanha,
      modeloAnuncio: campanha.modeloAnuncio,
      valorVeiculacaoMensal: campanhaVeiculacaoMensal,
      totalInstalacaoCampanha,
      movimentacaoFinanceiras: campanha.movimentacaoFinanceiras,
      valorRepasseInstaladorInstalacao: Number.currencyFormat(
        campanha.valorRepasseInstaladorInstalacao,
      ),
      valorMensalMotorista: Number.currencyFormat(
        campanha.valorMensalMotorista,
      ),
      descontoPercent: Number.regraDeTresPorcentagem(
        campanha.desconto,
        campanha.valorBrutoVeiculo,
        true
      ),
      desconto: Number.currencyFormat(campanha.desconto),
      repasseAgenciaPercent: Number.regraDeTresPorcentagem(
        campanha.repasseAgencia,
        campanhaVeiculacaoMensal,
        true
      ),
      repasseAgencia: Number.currencyFormat(campanha.repasseAgencia),
      repasseTerceirosPercent: Number.regraDeTresPorcentagem(
        campanha.repasseTerceiros,
        campanhaVeiculacaoMensal - campanha.repasseAgencia,
        true
      ),
      repasseTerceiros: Number.currencyFormat(campanha.repasseTerceiros),
      formaDePagamento: getFormaPagamento(
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelas)[0]
          ?.formaPagamento,
      ),
      formaDePagamentoProducao: getFormaPagamento(
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelasProducao)[0]
          ?.formaPagamento,
      ),
      faturarPor: {value: campanha.faturarPor, label: faturarPor[campanha.faturarPor]},
      parcelas:
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelas).length,
      parcelasProducao: campanha.movimentacaoFinanceiras?.filter(
        filtrarParcelasProducao,
      ).length,
      dataInicioVeiculacao: campanha.dataInicioVeiculacao
        ? moment(`${campanha.dataInicioVeiculacao}`).toDate()
        : ' ',
      refazParcelamento: false
    });
  }, [campanha]);

  if (!formData.id) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <h2>Carregando...</h2>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box>
      <Formik initialValues={formData} enableReinitialize>
        <Grid container spacing={2} padding="0px !important">
          <Grid item xs={3}>
            <UploadableFotoPerfil
              image={banner}
              setImage={setBanner}
              readOnly
            />
          </Grid>
          <Grid item xs={9}>
            <Box marginTop="10px" marginBottom="32px">
              <Grid container spacing={2}>
                <Grid item xl={1} xs={2}>
                  <InputV2
                    name="numero"
                    label="Nº"
                    value={formData.numero}
                    disabled
                  />
                </Grid>
                <Grid item xl={8} md={7} xs={6}>
                  <InputV2
                    name="titulo"
                    label="Título"
                    value={formData.titulo}
                    disabled
                  />
                </Grid>
                <Grid item xl={3} md={3} xs={4}>
                  <InputV2
                    name="situacao"
                    label="Situação"
                    value={campanhaSituacao[formData.situacao]}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={9} xs={8}>
                  <InputV2
                    name="anunciante"
                    label="Cliente"
                    value={formData.anunciante?.label}
                    endAdornment={(
                      <Visibility
                        titleAccess='Ver anunciante'
                        onClick={() => navigate(`/anunciantes/${formData.anunciante?.id}/editar`)}
                        style={{cursor: 'pointer'}}
                      />
                    )}
                    disabled
                  />
                </Grid>
                <Grid item md={3} xs={4}>
                  <InputDate
                    name="dataInicio"
                    label="Data início"
                    selected={formData.dataInicioVeiculacao}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={2} xs={4}>
            <InputV2
              label="Km total"
              value={Number.format(formData.kmTotal)}
              disabled
            />
          </Grid>
          <Grid item md={1.5} xs={4}>
            <InputV2
              name="quantidadeVeiculos"
              label="Nº de carros"
              value={formData.quantidadeVeiculos}
              disabled
            />
          </Grid>
          <Grid item md={2.5} xs={4}>
            <InputV2
              label="Tempo de campanha"
              value={`${formData.quantidadeMeses}    de ${formData?.diasPeriodo} dias`}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <InputV2
              label="Veiculação por mês"
              mask="moeda"
              value={Number.currencyFormat(formData.valorVeiculacaoMensal)}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <InputV2
              label="Reajuste"
              mask="moeda"
              value={Number.currencyFormat(formData.valorReajuste)}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <InputV2
              label="Veiculação total"
              mask="moeda"
              value={Number.currencyFormat(formData.valorVeiculacao)}
              disabled
            />
          </Grid>
          <Grid item md={4} xs={7}>
            <Box sx={styles.box}>
              <InputV2
                label="Agência por mês"
                name="repasseAgenciaPercent"
                value={`${formData.repasseAgenciaPercent} %`}
                disabled
              />
              <Box sx={{width: '300px'}}>
                <InputV2
                  label="Valor"
                  name="repasseAgencia"
                  mask="moeda"
                  value={formData.repasseAgencia}
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={5}>
            <InputV2
              label="Agência total"
              mask="moeda"
              defaultValue={0}
              value={Number.currencyFormat(
                Number.stringToFloat(formData.repasseAgencia) * formData.quantidadeMeses || 0
              )}
              disabled
            />
          </Grid>
          <Grid item md={4} xs={7}>
            <Box sx={styles.box}>
              <InputV2
                label="Terceiros por mês"
                name="repasseTerceirosPercent"
                value={`${formData.repasseTerceirosPercent} %`}
                disabled
              />
              <Box sx={{width: '300px'}}>
                <InputV2
                  label="Valor"
                  name="repasseTerceiros"
                  mask="moeda"
                  value={formData.repasseTerceiros}
                  style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={5}>
            <InputV2
              label="Terceiros total"
              mask="moeda"
              defaultValue={0}
              value={Number.currencyFormat(
                Number.stringToFloat(formData.repasseTerceiros) *
                formData.quantidadeMeses || 0,
              )}
              disabled
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <InputV2
              label="Valor produção total"
              mask="moeda"
              value={Number.currencyFormat(formData?.totalInstalacaoCampanha)}
              disabled
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <InputV2
              label="Valor total da campanha"
              mask="moeda"
              value={Number.currencyFormat(formData.valor)}
              disabled
            />
          </Grid>
          <Grid container item xs={12} sx={styles.cidades}>
            <Grid item xs={12}>
              <Box padding={'8px 0 8px 18px'}>
                <Typography sx={styles.labelFaturamento}>
                  Cidades
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {campanha?.cidades?.map((item) => (
                <CidadeItem
                  key={item.id}
                  item={item}
                />
              ))}
            </Grid>
          </Grid>
          {campanha.situacao === 'NAO_APROVADA' && (
            <Grid item md={12} xs={12}>
              <TextAreaV2
                label="Motivo da reprovação"
                value={campanha.mensagemReprovacao || ''}
                disabled
              />
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <TextAreaV2
              name="observacoesRelatorios"
              label="Observações para relatório"
              value={formData.observacoesRelatorios || ''}
              onChange={({target}) =>
                setFormData({
                  ...formData,
                  observacoesRelatorios: target.value,
                })
              }
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt="18px">
              <Typography sx={styles.labelFaturamento}>
                Faturamento da veiculação
              </Typography>
            </Box>
            <Box mt="18px">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputV2
                    label="Formas de Pagamento"
                    value={formData.formaDePagamento?.label}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputV2
                    name="parcelas"
                    label="Parcelas"
                    value={formData.parcelas}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputV2
                    label="Faturar por"
                    value={formData?.faturarPor?.label}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Box margin="18px 0px">
                <Grid container columnSpacing={20}>
                  <Grid container item xs={12} justifyContent={'space-between'}>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Parcelamento
                    </Typography>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Emissão NF
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {formData?.movimentacaoFinanceiras
                  ?.filter(filtrarParcelas)
                  .map((m, index) => (
                    <Grid
                      container
                      key={m.id}
                      alignItems="center"
                      marginBottom="10px"
                      columnSpacing={20}
                    >
                      <Grid container item xs={12} key={m.id} flexDirection="row" justifyContent="space-between">
                        <Typography sx={styles.itemParcela}>
                          {`${index + 1}ª. ${Number.currencyFormat(m.valor)} - ${Data.dataFormat(m.dataPrevisaoPgto) || '-'}`}
                        </Typography>
                        <Typography sx={styles.itemParcela}>
                          {`${Data.dataFormat(m.dataEmitirNota) || '-'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} alignItems="flex-start">
            <Box mt="18px">
              <Typography sx={styles.labelFaturamento}>
                Faturamento da produção
              </Typography>
            </Box>
            {formData.faturarProducao && (
              <Box mt="18px">
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={6}>
                    <InputV2
                      label="Formas de Pagamento"
                      value={formData.formaDePagamentoProducao?.label}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputV2
                      name="parcelasProducao"
                      label="Parcelas"
                      value={formData.parcelasProducao}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Box margin="18px 0px">
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    columnSpacing={12}
                  >
                    <Grid container item xs={12} justifyContent={'space-between'}>
                      <Typography sx={styles.parcelamentoTitulo}>
                        Parcelamento
                      </Typography>
                      <Typography sx={styles.parcelamentoTitulo}>
                        Emissão NF
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  {formData?.movimentacaoFinanceiras
                    ?.filter(filtrarParcelasProducao)
                    .map((m, index) => (
                      <Grid
                        container
                        key={m.id}
                        alignItems="center"
                        justifyContent="flex-start"
                        marginBottom="10px"
                        spacing={12}
                      >
                        <Grid container item xs={12} key={m.id} flexDirection="row" justifyContent="space-between">
                          <Typography sx={styles.itemParcela}>
                            {`${index + 1}ª. ${Number.currencyFormat(m.valor)} - ${Data.dataFormat(m.dataPrevisaoPgto) || '-'}`}
                          </Typography>
                          <Typography sx={styles.itemParcela}>
                            {`${Data.dataFormat(m.dataEmitirNota) || '-'}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography sx={styles.pedidoInsercaoHeader}>
                P.I. (Veiculação)
              </Typography>
              <Grid item xs={12} style={{minHeight: '125px'}}>
                {campanha.pedidoInsercao?.id && (
                  <DragNDrop
                    id={campanha.pedidoInsercao?.id}
                    fileName={formData.pedidoInsercao?.nome}
                    disabled={readOnly}
                  />
                )}
              </Grid>
            </Grid>
            {formData.faturarProducao && (
              <Grid item xs={6}>
                <Typography sx={styles.pedidoInsercaoHeader}>
                  A.P. (Produção)
                </Typography>
                <Grid item xs={12} style={{minHeight: '125px'}}>
                  {campanha.pedidoInsercaoProducao?.id && (
                    <DragNDrop
                      id={campanha.pedidoInsercaoProducao?.id}
                      fileName={formData.pedidoInsercaoProducao?.nome}
                      disabled={readOnly}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Formik>
      <Grid container rowSpacing={2} columnSpacing={0} mt="16px">
        <Grid
          item
          xs={12}
          display="flex"
          sx={styles.sizeContainerButtonFinal}
        >
          <ButtonComponent
            sx={{width: '256px'}}
            id="button-green"
            type="button"
            value="Gerar usuário"
            loading={loadingAccessPainel}
            icon={<Person />}
            onClick={() => gerarUsuarioSenhaAnunciante(campanha.anunciante)}
          />
          <ButtonComponent
            sx={{width: '256px'}}
            id="button-gray-blue"
            type="button"
            value={'Acessar painel'}
            loading={loadingAccessPainel}
            icon={<OpenInNew />}
            onClick={() => {
              setLoadingAccessPainel(true);
              painelService.acessarPainelAnunciante(campanha.anunciante?.id, setLoadingAccessPainel);
            }}
          />
        </Grid>
      </Grid>
    </Box >
  );
};

const styles = {
  itemParcela: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    color: '#6c7b9b',
    marginBottom: '5px',
  },
  parcelamentoTitulo: {
    margin: '0 0 5px 0',
    color: ' #657496',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  labelFaturamento: {
    color: theme => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  mapaContainer: {
    height: '36vh',
    marginBottom: '16px',

    ' @media (min-width: 1367px)': {
      height: '40vh',
      marginNottom: '24px',
    },
  },
  pedidoInsercaoHeader: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '36px',
    color: '#007BE1',
  },
  gridLabelFaturamento: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    alignItems: 'center',
  },
  sizeContainerButton: {
    '@media (min-width: 600px)': {
      justifyContent: 'center !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end !important',
    },
  },
  sizeContainerButtonFinal: {
    '@media (min-width: 600px)': {
      justifyContent: 'center !important',
      marginRight: '0px !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'space-between !important',
      marginRight: '0px !important',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cidades: {
    background: '#e4e9f3bf',
    borderRadius: '6px',
    padding: '0px !important',
    margin: '20px 0px 16px 16px'
  },
};

export default FormularioCampanha;
