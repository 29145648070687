import {useQuery} from '@apollo/client';
import {Visibility} from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import Select from '../../components/select/mui-select';
import TableCellOrdered from '../../components/table-cell-ordered';
import {FIND_ALL_MODELO_ANUNCIO} from '../../graphql/queries';
import {
  persistFiltrosModeloAnuncios,
  persistModeloAnuncios,
} from '../../redux/ModeloAnuncio/slice';
import inputsEvents from '../../utils/inputs-events';

const columns = [
  {id: 'nome', label: 'Título'},
  {id: 'descricao', label: 'Descrição'},
  {
    id: 'ativo',
    label: 'Situação',
    format: (ativo) => (ativo ? 'Ativo' : 'Inativo'),
  },
];

const ConsultaModeloAnuncio = ({pageSize = 20}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    modeloAnuncios,
    totalModeloAnuncios,
    filtrosModeloAnuncios,
    refetchModeloAnuncios,
  } = useSelector((store) => store.ModeloAnuncio);

  const [page, setPage] = useState(filtrosModeloAnuncios.page);
  const [rowsPerPage] = useState(pageSize);
  const [filtros, setFiltros] = useState(filtrosModeloAnuncios.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosModeloAnuncios.orderBy);

  const modeloAnunciosQuery = useQuery(FIND_ALL_MODELO_ANUNCIO, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        ativo: searchDTO.ativo?.value,
      },
    },
  });

  useEffect(() => {
    if (!modeloAnunciosQuery.loading && !modeloAnunciosQuery.error) {
      dispatch(
        persistModeloAnuncios(modeloAnunciosQuery.data.modelos),
      );
      dispatch(persistFiltrosModeloAnuncios({filtros, page, orderBy}));
    }
    if (refetchModeloAnuncios) {
      modeloAnunciosQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeloAnunciosQuery, dispatch, refetchModeloAnuncios]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid
        item
        xs={12}
        onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <InputV2
                  label="Título"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  value={filtros.ativo}
                  onChange={(e) => setFiltros({...filtros, ativo: e || null})}
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Ativo'},
                    {value: false, label: 'Inativo'},
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  loading={modeloAnunciosQuery.loading}
                  onClick={() => refetch()}
                  value="Filtrar"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modeloAnuncios.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => navigate(`${row.id}`)}
                        style={{cursor: 'pointer'}}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Visualizar'}
                            icon={<Visibility />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalModeloAnuncios}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaModeloAnuncio;
