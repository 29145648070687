import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaPainelAnunciante from './consulta';

const PainelAnunciante = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaPainelAnunciante />} />
    </Routes>
  );
};

export default PainelAnunciante;
