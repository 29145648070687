import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  Button,
  Box
} from '@mui/material';
import {Add, OpenInNew, Visibility} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Formik} from 'formik';
import {FIND_ALL_COMERCIAL} from '../../graphql/queries';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectCidades} from '../../containers/selects/cidades';
import {SelectEstados} from '../../containers/selects/estados';
import TableCellOrdered from '../../components/table-cell-ordered';
import Select from '../../components/select/mui-select';
import usePainelService from '../../services/PainelService';
import {persistExecutivos, persistFiltrosExecutivos} from '../../redux/Executivo/slice';

const columns = [
  {id: 'nomeRazao', label: 'Nome'},
  {
    id: 'endereco',
    label: 'Cidade',
    campo: 'endereco.cidade.nome',
    format: (endereco) =>
      endereco?.cidade ? `${endereco.cidade.nome} / ${endereco.cidade.uf}` : '',
  },
  {
    id: 'estadosAtuacao',
    label: 'Estados de atuação',
    format: (estadosAtuacao) => estadosAtuacao.map(e => e.uf).join(' | '),
    calculado: true
  },
  {
    id: 'ativo',
    label: 'Situação',
    format: (ativo) => (ativo ? 'Ativo' : 'Inativo'),
  },
];

const ConsultaExecutivo = ({pageSize = 20}) => {
  const {executivos, totalExecutivos, refetchExecutivos, filtrosExecutivos} = useSelector(
    store => store.Executivo
  );

  const {id: comercialLogado} = useSelector(store => store.Auth);
  const painelService = usePainelService();

  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(filtrosExecutivos.page);
  const [orderBy, setOrderBy] = useState(filtrosExecutivos.orderBy)
  const [filtros, setFiltros] = useState(filtrosExecutivos.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [loadingAccessComercial, setLoadingAccessComercial] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const executivoQuery = useQuery(FIND_ALL_COMERCIAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        cpfCnpj: searchDTO.cpfCnpj,
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        ativo: searchDTO.ativo?.value,
        comercialId: comercialLogado,
        comercialRoles: ['LICENCA_COMERCIAL']
      },
    },
  });

  useEffect(() => {
    if (!executivoQuery.loading && !executivoQuery.error && executivoQuery.data) {
      dispatch(persistExecutivos(executivoQuery.data.findAllComercial || []));
      dispatch(persistFiltrosExecutivos({filtros, page, orderBy}));
    }
    if (refetchExecutivos) {
      executivoQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executivoQuery, dispatch, refetchExecutivos]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        startIcon={<Add />}
        onClick={() => navigate(`criar`)}
        toolbar="true"
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputV2
                  label="Nome"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  defaultValue={{label: 'Todos'}}
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Ativo'},
                    {value: false, label: 'Inativo'},
                  ]}
                  value={filtros.ativo}
                  onChange={(e) => setFiltros({...filtros, ativo: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="button"
                  value="Filtrar"
                  loading={executivoQuery.loading}
                  onClick={refetch}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell
                      key="acoes"
                      align="center"
                      sx={styles.columnHeadAcoes}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {executivos.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center' style={{width: '100px'}}>
                          <Grid
                            container
                            spacing={0.5}
                            width="100px">
                            <Grid item xs={6}>
                              <RoundTooltipButton
                                title={'Acessar executivo'}
                                id="icon-gray"
                                onClick={() => {
                                  setLoadingAccessComercial(row);
                                  painelService.acessarPainelComercial(row.id, setLoadingAccessComercial);
                                }}
                                loading={loadingAccessComercial?.id === row.id}
                                icon={<OpenInNew />}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <RoundTooltipButton
                                title="Visualizar"
                                onClick={() =>
                                  navigate(`${row.id}/editar`)
                                }
                                icon={<Visibility />}
                                id="icon-primary"
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalExecutivos}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  columnHeadAcoes: {
    cursor: 'default',
    fontWeight: 'bold',
    width: '135px'
  }
};

export default ConsultaExecutivo;
