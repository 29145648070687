import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import './styles.css';

export const confirmAlertCustom = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmLabel = 'Sim',
  cancelLabel = 'Não',
  ...props
}) => {
  return confirmAlert({
    title,
    message,
    customUI: (props) => {
      return (
        <CustomUI {...props} />
      );
    },
    buttons: [
      {
        label: confirmLabel,
        onClick: onConfirm,
        className: 'confirmButton'
      },
      {
        label: cancelLabel,
        onClick: onCancel,
        className: 'cancelButton'
      }
    ],
    ...props
  });
};

export const alertCustom = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmLabel = 'Ok',
  ...props
}) => {
  return confirmAlert({
    title,
    message,
    customUI: (props) => {
      return (
        <CustomUI {...props} />
      );
    },
    buttons: [
      {
        label: confirmLabel,
        onClick: onConfirm,
        className: 'confirmButton'
      },
    ],
    ...props
  });
};

const CustomUI = (props) => {
  return (
    <div className='react-confirm-alert'>
      <div className="react-confirm-alert-body">
        {props.title && (<h1>{props.title}</h1>)}
        <pre>{props.message}</pre>
        <div className='react-confirm-alert-button-group'>
          <button
            className='confirmButton'
            onClick={() => {
              props.buttons[0].onClick();
              props.onClose();
            }}
          >
            {props.buttons[0].label}
          </button>
          {props.buttons[1] && (
            <button
              className='cancelButton'
              onClick={() => {
                props.buttons[1]?.onClick && props.buttons[1].onClick();
                props.onClose();
              }}
            >
              {props.buttons[1].label}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}