import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCampanha from './consulta';
import EditarCampanha from './editar';

const Campanhas = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaCampanha />} />
      <Route path={`/:campanhaId/editar`} element={<EditarCampanha />} />
    </Routes>
  );
};

export default Campanhas;
