import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  selecionado: {},
  anunciantes: [],
  anunciantesHome: [],
  pageableDefault: {
    pageSize: 10,
    pageNumber: 0,
    sortDir: 'ASC',
    sortField: 'nomeFantasia',
  },
  filtrosAnunciante: {
    filtros: {cadastroCompleto: {label: 'Todos'}, comercialResponsavel: {label: 'Todos'}, tipo: {label: 'Todos'}},
    page: 0,
    orderBy: {campo: 'nomeFantasia', asc: true}
  },
  searchDTODefault: {},
  searchDTO: {
    anuncianteSemCampanha: false,
  },
  hasMore: false,
  totalAnunciantes: 0,
  nextPage: 0,
  hasMoreHome: false,
  totalAnunciantesHome: 0,
  nextPageHome: 0,
  refetchAnunciantes: false,
};

const acaoAtualizarAnunciantes = state => {
  state.refetchAnunciantes = true;
};

const acaoSelecionarAnunciante = (state, action) => {
  state.selecionado = action.payload;
};

const acaoPersistAnuncianteSearchDTO = (state, action) => {
  state.searchDTO = action.payload;
};

const acaoPersistAnunciantes = (state, action) => {
  const {content, last, totalElements, pageable} = action.payload || {};

  state.anunciantes = content || [];

  const currentPage = pageable && pageable.pageNumber ? pageable.pageNumber : 0;

  state.hasMore = !last;
  state.totalAnunciantes = totalElements || 0;
  state.nextPage = currentPage + 1;
  state.refetchAnunciantes = false;
};
const acaoPersistAnunciantesHome = (state, action) => {
  const {content, last, totalElements, pageable} = action.payload || {};

  state.anunciantesHome = content || [];

  const currentPage = pageable && pageable.pageNumber ? pageable.pageNumber : 0;

  state.hasMoreHome = !last;
  state.totalAnunciantesHome = totalElements || 0;
  state.nextPageHome = currentPage + 1;
  state.refetchAnunciantes = false;
};

const acaoPersistFiltrosAnunciante = (state, action) => {
  state.filtrosAnunciante = action.payload;
};

const resumeSlice = createSlice({
  name: 'anunciante',
  initialState: initialState,
  reducers: {
    selecionarAnunciante: acaoSelecionarAnunciante,
    persistAnunciantes: acaoPersistAnunciantes,
    persistAnuncianteSearchDTO: acaoPersistAnuncianteSearchDTO,
    atualizarAnunciantes: acaoAtualizarAnunciantes,
    persistFiltrosAnunciante: acaoPersistFiltrosAnunciante,
    persistAnunciantesHome: acaoPersistAnunciantesHome,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  selecionarAnunciante,
  persistAnuncianteSearchDTO,
  persistAnunciantes,
  atualizarAnunciantes,
  persistFiltrosAnunciante,
  persistAnunciantesHome
} = actions;
