import React from 'react';
import InputV2 from './mui-input-v2';

const InputV2Formik = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <InputV2
      {...field}
      {...props}
      value={field.value}
      error={errors[field.name] && touched[field.name] ? true : false}
      mensagemSuporte={
        errors[field.name] && touched[field.name] && errors[field.name]
      }
    />
  );
};

export default InputV2Formik;