import React, {useState, useMemo, useEffect} from 'react';
import {Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useDropzone} from 'react-dropzone';
import {
  CloudDownload,
  InsertDriveFile,
  GetApp,
  DeleteOutline,
} from '@mui/icons-material';
import {confirmAlertCustom} from '../components/confirm-alert/confirm-alert';
import File from '../utils/file';
import {Typography} from '@mui/material';

const DragNDrop = ({
  onChange,
  onDelete,
  fileName,
  file,
  id,
  disabled,
  style = {},
  label
}) => {
  const classes = useStyles();

  const [styleDragZone, setStyleDragZone] = useState({})
  const [styleDragText, setStyleDragText] = useState({})
  const [styleDragContainer, setStyleDragContainer] = useState({})
  const [styleDragIcon, setStyleDragIcon] = useState({})
  const [url, setUrl] = useState('');

  const {getRootProps, getInputProps, acceptedFiles, isDragAccept} =
    useDropzone();

  useMemo(
    () => {
      setStyleDragZone(isDragAccept ? {
        background: '#8995B0',
        color: '#fff ',
        border: 'none',
      } : {});
      setStyleDragContainer(isDragAccept ? {border: '2px dashed #fff'} : {});
      setStyleDragText(isDragAccept ? {color: '#fff'} : {});
      setStyleDragIcon(isDragAccept ? {color: '#fff'} : {});
    },
    [isDragAccept],
  );

  useEffect(() => {
    onChange && onChange(acceptedFiles[0]);
  }, [acceptedFiles, onChange]);

  useEffect(() => {
    if (file) {
      var blob = new Blob([file]);
      return setUrl(URL.createObjectURL(blob));
    }

    if (id) {
      setUrl(File.getFileAuth(id, fileName));
    }
  }, [file, id, fileName]);

  const handleDelete = () => {
    confirmAlertCustom({
      message: 'Deseja Excluir o arquivo?',
      onConfirm: onDelete,
    });
  };

  return (
    <section className="container" style={style}>
      {label && (<Typography className={classes.headerText}>{label}</Typography>)}
      {!fileName ? (
        <div {...getRootProps()} className={`${classes.dragZone} ${disabled ? classes.dragZoneDisabled : ''}`} style={styleDragZone}>
          <div
            className={classes.containerUpload}
            style={styleDragContainer}
          >
            <input {...getInputProps()} disabled={disabled} />
            <CloudDownload
              className={classes.iconCloud}
              style={styleDragIcon}
            />
            {!disabled && (
              <p
                className={classes.textDownload}
                style={styleDragText}>
                Arraste ou click aqui para subir o arquivo
              </p>
            )}
          </div>
        </div>
      ) : (
        <Grid className={classes.fileContainer}>
          <Grid container item xs={12} className={classes.fileArea}>
            <Grid item xs={10} className={classes.containerIconNomeArquivo}>
              <InsertDriveFile className={classes.iconFile} />
              <div className={classes.containerNomeArquivo}>
                <label className={classes.labelNome}> Nome do arquivo </label>
                <p className={classes.textNome}>{fileName}</p>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.icons}>
              <DeleteOutline
                onClick={handleDelete}
                className={classes.iconFileExcluir}
                style={{visibility: disabled ? 'hidden' : 'visible'}}
              />
              <a href={url} download={fileName} className={classes.anchorIcon}>
                <GetApp className={classes.iconFileDownload}> </GetApp>
              </a>
            </Grid>
          </Grid>
        </Grid>
      )}
    </section>
  );
};

const useStyles = makeStyles(() => ({
  containerUpload: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  iconCloud: {
    color: '#8995B0',
    height: '70px',
    width: '70px',
  },
  dragZone: {
    flex: 1,
    color: '#fff',
    display: 'flex',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    alignItems: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    backgroundColor: '#F8FCFF',
    border: '2px dashed #AFB7C8',
    height: '127px',
    marginTop: '16px',
    justifyContent: 'center',
    padding: '5px',
    '&:hover': {
      background: '#8995B0',
      color: '#fff',
      border: 'none',
      '&>div': {
        border: '2px dashed #fff !important',
        '&>p': {
          color: '#fff',
        },
        '&>svg': {
          color: '#fff',
        },
      },
    },
  },
  dragZoneDisabled: {
    pointerEvents: 'none',
    '& p': {
      color: '#e7e7e7 !important',
    },
    '& svg': {
      color: '#e7e7e7 !important',
    },
  },
  textDownload: {
    fontFamily: 'AktivGrotesk-Medium',
    fontWeight: '500',
    fontSize: '16px',
    LineHeight: '20.48px',
    cursor: 'inherit',
    color: '#0A2549',
    margin: '0px',
  },
  fileContainer: {
    height: '127px',
    background: '#FFFFFF',
    alignItems: 'flex-start',
    marginTop: '16px',
    cursor: 'default',
  },
  fileArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '64px',
    width: '100%',
  },
  containerIconNomeArquivo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px',
  },
  containerNomeArquivo: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelNome: {
    height: 'auto',
    padding: '0px',
    fontFamily: 'AktivGrotesk-Medium',
    fontWeight: '500',
    fontSize: '12px',
    color: '#657496',
  },
  textNome: {
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
    fontFamily: 'AktivGrotesk',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconFile: {
    color: '#657496',
    margin: '0px 14px 0px -14px',
    transform: 'scale(1.4)',
    borderRadius: '15px',
  },
  iconFileExcluir: {
    color: '#657496',
    marginRight: '20px',
    transform: 'scale(1.4)',
    borderRadius: '58px',
    padding: 3,
    '&:hover': {
      cursor: 'pointer !important',
      background: '#DC3131',
      color: '#fff',
      boxShadow: '3.15px 6.3px 20.475px rgba(252, 52, 79, 0.5)'
    },
  },
  iconFileDownload: {
    color: '#657496',
    marginRight: '14px',
    transform: 'scale(1.4)',
    borderRadius: '15px',
    padding: 3,
    '&:hover': {
      background: ' rgba(175, 183, 200, 0.4);',
    },
  },
  anchorIcon: {
    height: '0px',
    width: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    display: 'block',
    fontWeight: 'bold !important',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
}));

export default DragNDrop;
