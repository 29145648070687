import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {FormControlLabel, Grid, Paper, Radio, Typography} from '@mui/material';
import {Description, OpenInNew, Visibility} from '@mui/icons-material';
import {useLazyQuery} from '@apollo/client';
import {FIND_ALL_CIDADES_CAMPANHA} from '../../graphql/queries';
import Map from '../../google/Map';
import TransitLayer from '../../google/TransitLayer';
import InfoWindow from '../../google/InfoWindow';
import Marker from '../../google/Marker';
import {centroBrasil} from '../../utils/get-places';
import {RoundTooltipButton} from '../../components/mui-button';
import {persistFiltrosProposta} from '../../redux/Proposta/slice';
import {CAMPANHAS_ROUTE, PROPOSTAS_ROUTE} from '../../constants/routes';
import {setFiltroHome} from '../../redux/Home/slice';
import usePainelService from '../../services/PainelService';

const KmIcon = require('../../assets/icons/km-map-pin.svg');

const variables = {
  pageable: {pageNumber: 0, pageSize: -1},
  searchDTO: {}
}

const HomeComercial = () => {
  const dispatch = useDispatch();
  const {filtroHome} = useSelector((state) => state.Home);
  const {responsavelId: comercialLogado} = useSelector((store) => store.Auth);

  const [filtro, setFiltro] = useState(filtroHome);
  const [localizacoes, setLocalizacoes] = useState([]);

  const [loadCampanha, campanhaQuery] = useLazyQuery(FIND_ALL_CIDADES_CAMPANHA);
  const [loadCampanhaAtivas, campanhaAtivasQuery] = useLazyQuery(FIND_ALL_CIDADES_CAMPANHA);

  useEffect(() => {
    if (!comercialLogado) return;
    loadCampanha({variables: {...variables, searchDTO: {campanhaSituacoesIn: ['FINALIZADA'], comercialId: comercialLogado}}});
    loadCampanhaAtivas({variables: {...variables, searchDTO: {campanhaSituacoesIn: ['ATIVA', 'ATIVA_PARCIAL'], comercialId: comercialLogado}}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercialLogado]);

  useEffect(() => {
    if (campanhaQuery.loading) return;

    const data = filtro === 'FINALIZADAS' ? campanhaQuery.data : campanhaAtivasQuery.data;

    setLocalizacoes(data?.cidades?.content
      ?.map(({anunciante, cidade, campanha}) => ({
        id: campanha.id,
        anuncianteId: anunciante.id,
        latitude: cidade?.latitude + (Math.random() / 100),
        longitude: cidade?.longitude + (Math.random() / 100),
        nome: anunciante?.nomeFantasia,
      })));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaQuery, campanhaAtivasQuery, filtro]);

  const handeChangeFilter = value => {
    dispatch(setFiltroHome(value));
    if (value !== filtro)
      setLocalizacoes([]);
    setFiltro(value);
  }

  return (
    <>
      <Grid container item xs={12} sx={styles.filtroContainer}>
        <Grid item xs={12} display="inline-flex">
          <Typography sx={styles.titulo}>Campanhas:</Typography>
          <FormControlLabel
            control={<Radio />}
            checked={filtro === 'ATIVAS'}
            label={`Ativas (${new Set(campanhaAtivasQuery.data?.cidades?.content?.map(c => c?.campanha?.id)).size || 0})`}
            onChange={({target}) => handeChangeFilter('ATIVAS')}
            sx={[styles.checkbox, filtro === 'ATIVAS' && styles.checkboxSelected]}
          />
          <FormControlLabel
            control={<Radio />}
            checked={filtro === 'FINALIZADAS'}
            label={`Finalizadas (${new Set(campanhaQuery.data?.cidades?.content?.map(c => c?.campanha?.id)).size || 0})`}
            onChange={({target}) => handeChangeFilter('FINALIZADAS')}
            sx={[styles.checkbox, filtro === 'FINALIZADAS' && styles.checkboxSelected]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={styles.mapaContainer}>
        <MapaHome localizacoes={localizacoes} filtro={filtro} />
      </Grid>
    </>
  )
}

const MapaHome = ({localizacoes = []}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const painelService = usePainelService();
  const {filtrosProposta} = useSelector((store) => store.Proposta);

  const [centerMapLatLng] = useState({
    lat: centroBrasil.lat,
    lng: centroBrasil.lng,
  });
  const [transitLayerEnabled] = useState(false);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    dados: null,
    marker: null,
    map: null,
  });
  const [timeoutId, setTimeoutId] = useState(undefined);

  const dadosInfoWindow =
    infoWindowSettings && infoWindowSettings.dados
      ? infoWindowSettings.dados
      : null;

  const consultarFunil = (dados) => {
    dispatch(
      persistFiltrosProposta({
        ...filtrosProposta,
        filtros: {
          anunciante: {
            value: dados.anuncianteId,
            label: dados.nome,
          }
        },
      }),
    );
    navigate(PROPOSTAS_ROUTE);
  }

  const abrirFormulario = (item) => {
    return abrirCampanha(item);
  }

  const abrirCampanha = (dados) => {
    navigate(`${CAMPANHAS_ROUTE}/${dados.id}/editar`);
  }

  const handleMouseOut = () => {
    setTimeoutId(setTimeout((clearInfoWindow), 150));
  }

  const handleMouseIn = () => {
    clearTimeout(timeoutId);
  }

  const clearInfoWindow = () => {
    setInfoWindowSettings({
      visible: false,
      dados: null,
      marker: null,
      map: null,
    })
  }

  useEffect(() => {
    if (infoWindowSettings.visible) return;
    handleMouseIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoWindowSettings]);

  return (
    <Grid container sx={styles.mapa} component={Paper} elevation={0}>
      <Map
        zoom={4}
        center={centerMapLatLng}
        locations={localizacoes}
        withBounds={localizacoes.length > 1 ? true : false}
        options={{
          clickableIcons: false,
          disableDefaultUI: true,
          scrollwheel: true,
          zoomControl: true,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
      >
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div
            className="iw-condutor-container"
            onMouseLeave={clearInfoWindow}
            onMouseEnter={handleMouseIn}
          >
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <p>{dadosInfoWindow?.nome}</p>
              </div>
              <div className="iw-condutor-info-posicao">
                <Grid container direction="row" justifyContent="center">
                  <RoundTooltipButton
                    id="icon-gray"
                    title={'Acessar'}
                    icon={<OpenInNew />}
                    onClick={() => painelService.acessarPainelAnunciante(dadosInfoWindow.anuncianteId)}
                    tabIndex={-1}
                  />
                  <RoundTooltipButton
                    id="icon-primary"
                    title={'Visualizar'}
                    icon={<Visibility />}
                    onClick={() => abrirFormulario(dadosInfoWindow)}
                    tabIndex={-1}
                  />
                  <RoundTooltipButton
                    id="icon-yellow"
                    title={'Funil de vendas'}
                    icon={<Description />}
                    onClick={() => consultarFunil(dadosInfoWindow)}
                    tabIndex={-1}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </InfoWindow>
        {localizacoes?.map((localizacao, index) => {
          return (
            <Marker
              key={index}
              active={false}
              position={{
                lat: localizacao.latitude,
                lng: localizacao.longitude,
              }}
              icon={KmIcon}
              events={{
                onMouseOver: (map, marker) => {
                  handleMouseIn();
                  clearInfoWindow();
                  setInfoWindowSettings({
                    visible: true,
                    marker,
                    map,
                    dados: {
                      ...localizacao,
                    },
                  });
                },
                onMouseOut: handleMouseOut
              }}
            />
          );
        })}
      </Map >
    </Grid>
  );
};

const styles = {
  titulo: {
    color: theme => theme.palette.paterns.navyBlue,
    fontSize: '16px !important',
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: 'bold !important',
    marginRight: '16px'
  },
  filtroContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    height: '60px',
    overflowX: 'hidden',
    backgroundColor: '#fff',
    flexWrap: 'wrap',
    padding: '0px 22px',
    borderRadius: '4px',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  mapaContainer: {
    paddingTop: '8px',
    overflow: 'hidden',
    height: theme => `calc(100vh - 60px - 16px - ${theme.toolbarHeight})`,
  },
  mapa: {
    borderRadius: '4px',
    backgroundColor: theme => theme.palette.paterns.white,
    padding: '0',
    height: '100%',
    cursor: 'default',
    overflow: 'hidden'
  },
  checkbox: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(0deg, #EFF6FD, #EFF6FD)',
    borderRadius: '6px',
    paddingRight: '9px',
    whiteSpace: 'nowrap',
    margin: '0 16px 0 0 !important',

    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#636363',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Roboto'
    },
    '.MuiSvgIcon-root': {
      fill: '#0091FF !important'
    }
  },
  checkboxSelected: {
    background: '#031663 !important',

    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#fff !important',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    '.MuiSvgIcon-root': {
      fill: '#007BE1 !important'
    }
  },
}

export default HomeComercial;