import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Box,
  Grid
} from '@mui/material';
import {OpenInNew} from '@mui/icons-material';
import {useLazyQuery} from '@apollo/client';
import {FIND_ANUNCIANTES_DEMONSTRACAO} from '../../graphql/queries';
import {RoundTooltipButton} from '../../components/mui-button';
import usePainelService from '../../services/PainelService';

const columns = [
  {id: 'nomeFantasia', label: 'Nome fantasia'},
  {
    id: 'situacao', label: 'Situação das campanhas',
    format: (situacao) => situacao === 'ATIVA' ? 'Ativas' : 'Finalizadas'
  },
];

const ConsultaPainelAnunciante = () => {
  const painelService = usePainelService();

  const [anunciantes, setAnunciantes] = useState([]);
  const [loadingAccessAnunciante, setLoadingAccessAnunciante] = useState(null);

  const [loadAnunciantes, anuncianteQuery] = useLazyQuery(FIND_ANUNCIANTES_DEMONSTRACAO);

  useEffect(() => {
    loadAnunciantes();
  }, [loadAnunciantes]);

  useEffect(() => {
    if (anuncianteQuery.loading || anuncianteQuery.error) return;

    setAnunciantes(anuncianteQuery.data?.anunciantes || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anuncianteQuery]);

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} sx={styles.columnHead}>
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key="acoes"
                      align="right"
                      sx={styles.columnHead}
                      style={{width: '135px'}}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anunciantes.map(row => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={styles.columnHead}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <Grid sx={styles.actionButtonsContainer}>
                          <RoundTooltipButton
                            title={'Acessar painel'}
                            onClick={() => {
                              setLoadingAccessAnunciante(row);
                              painelService.acessarPainelAnunciante(row.id, setLoadingAccessAnunciante);
                            }}
                            loading={loadingAccessAnunciante?.id === row.id}
                            icon={<OpenInNew />}
                            id="icon-primary"
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  columnHead: {
    cursor: 'default',
    fontWeight: 'bold',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    padding: 0,
  },
};

export default ConsultaPainelAnunciante;
