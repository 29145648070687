import React, {useEffect, useRef, useState} from "react";
import {Field, Formik} from "formik";
import ReactToPrint from "react-to-print";
import {useLazyQuery} from "@apollo/client";
import {Grid, Paper} from "@mui/material";
import InputDate from "../../../components/mui-input-date";
import {SelectCampanhas} from "../../../containers/selects/campanhas";
import {SelectCidades} from "../../../containers/selects/cidades";
import InputV2Formik from "../../../components/input-v2/mui-input-v2-formik";
import {ButtonComponent} from "../../../components/mui-button";
import {FIND_EXTRATO_REPASSE_DO_COMERCIAL} from "../../../graphql/queries";
import Data from "../../../utils/data";
import {toastNotification} from "../../../components/toastify";
import ImpressaoRelatorioMotoristasPagos from "./impressao";

const pageStyle = `
@page { 
  size: A4; 
  margin: 5mm 10mm 5mm 10mm; 
}

@media all {
  .page-footer {
    display: block !important;
  }
}
`;

const RelatorioPagamentos = () => {
  const componentRef = useRef();
  const printButtonRef = useRef();

  const [filtros, setFiltros] = useState({dataPagamento: new Date()});
  const [loading, setLoading] = useState(false);
  const [movimentacoes, setMovimentacoes] = useState(undefined);

  const [loadExtratos] = useLazyQuery(FIND_EXTRATO_REPASSE_DO_COMERCIAL);

  const handleImprimir = () => {
    loadExtratos({
      variables: {
        pageable: {
          pageNumber: 0,
          pageSize: -1,
          sortField: 'dataPgto',
          sortDir: 'ASC',
        },
        searchDTO: {
          statusMovimentacaoFinanceira: 'FINALIZADO',
          campanhaId: filtros.campanha?.value,
          search: filtros.motorista || undefined,
          cidadeId: filtros.cidade?.value,
          movimentacaoOrigem: 'MOTORISTA',
          dataPagamentoInicio: Data.toMoment(filtros.dataPagamento).startOf("M").format('YYYY-MM-DD'),
          dataPagamentoFim: Data.toMoment(filtros.dataPagamento).endOf("M").format('YYYY-MM-DD'),
        },
      }
    })
      .then(({data}) => setMovimentacoes(data?.extrato?.content))
      .catch((error) => toastNotification({message: error.message, type: 'error'}))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (movimentacoes)
      printButtonRef.current.click();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movimentacoes]);

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Pagamentos ${Data.dataFormat(filtros.dataPagamento, 'MMMM[ ]YYYY')}`}
        trigger={() => (<button ref={printButtonRef} style={{display: 'none'}} />)}
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik
          initialValues={filtros}
          enableReinitialize
          onSubmit={handleImprimir}
        >
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={2.5}>
                <Field
                  component={InputDate}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  name="dataPagamento"
                  label="Pagamento"
                  onChange={dataPagamento => setFiltros(f => ({...f, dataPagamento}))}
                />
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  component={SelectCampanhas}
                  name="campanha"
                  label="Campanha"
                  onChange={campanha => setFiltros(f => ({...f, campanha}))}
                  isClearable
                />
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  component={SelectCidades}
                  name="cidade"
                  label="Cidade"
                  onChange={cidade => setFiltros(f => ({...f, cidade}))}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="motorista"
                  label="Motorista"
                  onChange={({target}) => setFiltros(f => ({...f, motorista: target?.value}))}
                />
              </Grid>
              <Grid item xs={1.5} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  id="button"
                  value="Imprimir"
                  loading={loading}
                  style={{minWidth: 0}}
                  onClick={handleImprimir}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid overflow="hidden" height="0px">
        <Grid ref={componentRef} background="#fff">
          <ImpressaoRelatorioMotoristasPagos
            movimentacoes={movimentacoes}
            periodo={filtros.dataPagamento}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatorioPagamentos;