import React from 'react';
import FormularioAnunciante from './formulario';

const CadastrarAnunciante = () => {
  return (
    <FormularioAnunciante />
  );
};

export default CadastrarAnunciante;
