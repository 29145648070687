import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  agencias: [],
  filtrosAgencias: {
    filtros: {
      search: '',
      cpfCnpj: '',
      ativo: {value: true, label: 'Ativo'},
      comercialResponsavel: {label: 'Todos'}
    },
    page: 0,
    orderBy: {campo: 'nomeFantasia', asc: true}
  },
  hasMore: false,
  totalAgencias: 0,
  refetchAgencias: false
};

const acaoAtualizarAgencias = state => {
  state.refetchAgencias = true;
};

const acaoPersistAgencias = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.agencias = content || [];
  state.hasMore = !last;
  state.totalAgencias = totalElements || 0;
};

const acaoPersistFiltrosAgencias = (state, action) => {
  state.filtrosAgencias = action.payload;
};

const resumeSlice = createSlice({
  name: 'agencia',
  initialState: initialState,
  reducers: {
    persistAgencias: acaoPersistAgencias,
    atualizarAgencias: acaoAtualizarAgencias,
    persistFiltrosAgencias: acaoPersistFiltrosAgencias
  }
});

export const {actions, reducer} = resumeSlice;
export const {persistAgencias, atualizarAgencias, persistFiltrosAgencias} = actions;
