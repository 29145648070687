import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  modeloAnuncios: [],
  filtrosModeloAnuncios: {
    filtros: {
      ativo: {value: true, label: 'Ativo'}
    },
    page: 0,
    orderBy: {campo: 'nome', asc: true}
  },
  totalModeloAnuncios: 0,
  refetchModeloAnuncios: false
};

const acaoAtualizarModeloAnuncios = state => {
  state.refetchModeloAnuncios = true;
};

const acaoPersistModeloAnuncios = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.modeloAnuncios = content;
  state.hasMore = !last;
  state.totalModeloAnuncios = totalElements || 0;
  state.refetchModeloAnuncios = false;
};
const acaoPersistFiltrosModeloAnuncios = (state, action) => {
  state.filtrosModeloAnuncios = action.payload;
};

const resumeSlice = createSlice({
  name: 'modeloAnuncio',
  initialState: initialState,
  reducers: {
    persistModeloAnuncios: acaoPersistModeloAnuncios,
    atualizarModeloAnuncios: acaoAtualizarModeloAnuncios,
    persistFiltrosModeloAnuncios: acaoPersistFiltrosModeloAnuncios
  }
});


export const {actions, reducer} = resumeSlice;
export const {persistModeloAnuncios, atualizarModeloAnuncios, persistFiltrosModeloAnuncios} = actions;
