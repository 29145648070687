import {useEffect, useState} from 'react';

export default function TransitLayer({enabled, maps, map}) {
  const [transitLayer, setTransitLayer] = useState();
  useEffect(() => {
    setTransitLayer(new maps.TransitLayer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (transitLayer) {
        enabled ? transitLayer.setMap(map) : transitLayer.setMap(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled]
  );

  return null;
}