import {useMutation} from '@apollo/client';
import {
  ArrowForward,
  Campaign,
  Close,
  NoteAdd,
  Save,
} from '@mui/icons-material';
import {Box, Checkbox, Grid, Modal, Slide, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {confirmAlertCustom} from '../../../components/confirm-alert/confirm-alert';
import DragNDrop from '../../../components/drag-n-drop';
import ImpressaoPI from '../../../components/impressao/impressao-pi';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../../components/mui-button';
import InputDateV2 from '../../../components/mui-input-date';
import Select from '../../../components/select/mui-select';
import {toastNotification} from '../../../components/toastify';
import {faturarPor, formaPagamento} from '../../../constants/enum-labels';
import {
  GERAR_PEDIDO,
  UPDATE_ORCAMENTO_FATURAMENTO,
} from '../../../graphql/mutation';
import {uploadAmazonS3Arquivo} from '../../../services/UploadService';
import {getSidebarWidth} from '../../../theme/mui-theme';
import Data from '../../../utils/data';
import Number from '../../../utils/number';

const pageStyle = `
  @page {
    size: A4;
    margin: 3mm 10mm 3mm 10mm;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
      overflow: initial !important;
    }
  }
  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const GerarCampanhaModal = ({orcamento, proposta, isOpen, onClose}) => {
  const navigate = useNavigate();

  const componentRef = useRef();
  const printButtonRef = useRef();

  const [gerandoPI, setGerandoPI] = useState(false);
  const [gerandoCampanhas, setGerandoCampanhas] = useState(false);
  const [formData, setFormData] = useState(orcamento);
  const [file, setFile] = useState(undefined);
  const [fileProducao, setFileProducao] = useState(undefined);
  const [fieldErro, setFieldErro] = useState([]);

  const [gerarPedido] = useMutation(GERAR_PEDIDO);
  const [updateFaturamento] = useMutation(UPDATE_ORCAMENTO_FATURAMENTO);

  useEffect(() => {
    setFormData({
      ...orcamento,
      dataInicio: Data.toDate(orcamento.dataInicio),
      repasseTerceirosValor: Number.currencyFormat(orcamento.comissaoTerceiros),
      repasseTerceiros: Number.currencyFormat(
        Number.regraDeTresPorcentagem(
          orcamento.comissaoTerceiros,
          Number.stringToFloat(orcamento.valorTotalVeiculacaoMensal) -
            Number.stringToFloat(orcamento.comissaoAgencia),
        ),
      ),
      formaDePagamento: orcamento.formaPagamentoVeiculacao
        ? {
            value: orcamento.formaPagamentoVeiculacao,
            label: formaPagamento[orcamento.formaPagamentoVeiculacao],
          }
        : undefined,
      parcelas: orcamento.parcelasVeiculacao || undefined,
      vencimentoParcelas: Data.toDate(orcamento.dataVencimentoVeiculacao),
      dataEmitirNota: Data.toDate(orcamento.dataEmissaoNotaVeiculacao),
      faturarPor: orcamento.faturarPor
        ? {value: orcamento.faturarPor, label: faturarPor[orcamento.faturarPor]}
        : undefined,
      faturarProducao: orcamento.faturarProducao === false ? false : true,
      formaDePagamentoProducao: orcamento.formaPagamentoProducao
        ? {
            value: orcamento.formaPagamentoProducao,
            label: formaPagamento[orcamento.formaPagamentoProducao],
          }
        : undefined,
      parcelasProducao: orcamento.parcelasProducao || undefined,
      vencimentoParcelasProducao: Data.toDate(orcamento.dataVencimentoProducao),
      dataEmitirNotaProducao: Data.toDate(orcamento.dataEmissaoNotaProducao),
      pedidoInsercao: orcamento.piVeiculacao || undefined,
      pedidoInsercaoProducao: orcamento.piProducao || undefined,
    });
  }, [orcamento]);

  useEffect(() => {
    if (formData.faturarProducao) return;

    setFormData((f) => ({
      ...f,
      formaDePagamentoProducao: null,
      parcelasProducao: undefined,
      vencimentoParcelasProducao: undefined,
      dataEmitirNotaProducao: undefined,
    }));
  }, [formData.faturarProducao]);

  const handleOnClose = () => {
    onClose && onClose(formData);
  }

  const handleChangeFieldValor = (
    field,
    percentField,
    valor = 0,
    valorReferencia = 0,
  ) => {
    setFormData({
      ...formData,
      [field]: valor,
      [percentField]: Number.currencyFormat(
        Number.regraDeTresPorcentagem(valor, valorReferencia),
      ),
    });
  };

  const handleChangeFieldPercent = (
    field,
    percentField,
    valor = 0,
    valorReferencia = 0,
  ) => {
    const valorCalculo = Number.regraDeTresValor(valorReferencia, valor);
    setFormData({
      ...formData,
      [percentField]: valor,
      [field]: Number.currencyFormat(valorCalculo),
    });

    return valorCalculo;
  };

  const validarCampos = () => {
    const erros = [];

    erros.push(
      !orcamento?.id ? 'orcamentoId' : '',
      !formData.titulo?.trim() ? 'titulo' : '',
      !formData.dataInicio ? 'dataInicio' : '',
      !formData.formaDePagamento ? 'formaDePagamento' : '',
      !formData.faturarPor ? 'faturarPor' : '',
      !formData.parcelas ? 'parcelas' : '',
      !formData.dataEmitirNota ? 'dataEmitirNota' : '',
      !formData.vencimentoParcelas ? 'vencimentoParcelas' : '',
      !formData.repasseTerceiros ? 'repasseTerceiros' : '',
    );

    if (formData.faturarProducao) {
      erros.push(
        !formData.dataEmitirNotaProducao ? 'dataEmitirNotaProducao' : '',
        !formData.parcelasProducao ? 'parcelasProducao' : '',
        !formData.formaDePagamentoProducao ? 'formaDePagamentoProducao' : '',
        !formData.vencimentoParcelasProducao
          ? 'vencimentoParcelasProducao'
          : '',
      );
    }

    setFieldErro(erros);
    return erros;
  };

  const handleClickGerarCampanhas = () => {
    if (gerandoCampanhas) return;

    const erros = validarCampos();

    if (erros.filter((e) => !!e).length > 0) {
      toastNotification({
        message: 'Preencha todos os campos corretamente!',
        type: 'error',
      });
      return;
    }

    confirmAlertCustom({
      title: 'Confirmar pedido?',
      message: `Cliente: ${proposta?.anunciante?.nomeFantasia} \nCidades: ${
        orcamento.itens?.length || 0
      } cidade${
        orcamento.itens?.length !== 1 ? 's' : ''
      } \nTotal de veículos: ${orcamento.itens
        ?.map((i) => i.quantidadeVeiculos)
        .reduce((total, item) => total + item, 0)} veículos.`,
      onConfirm: handleGerarCampanhas,
      onCancel: () => null,
      onClickOutside: () => null,
    });
  };

  const handleGerarCampanhas = async () => {
    setGerandoCampanhas(true);

    let response = undefined;
    let responseProducao = undefined;
    try {
      if (file) {
        response = await uploadAmazonS3Arquivo(file);
      }
      if (formData.faturarProducao && fileProducao) {
        responseProducao = await uploadAmazonS3Arquivo(fileProducao);
      }
    } catch (error) {
      console.log(error);
    }

    salvarDados(response, responseProducao).then(
      gerarPedido({
        variables: {
          orcamento: {
            id: orcamento.id,
          },
        },
      })
        .then(() => {
          setGerandoCampanhas(false);
          toastNotification({
            message: 'Proposta enviada para aprovação!',
            type: 'success',
          });
          navigate('/propostas');
        })
        .catch((error) => {
          setGerandoCampanhas(false);
          toastNotification({message: error.message, type: 'error'});
        }),
    );
  };

  const handleSalvarDados = async () => {
    if (gerandoCampanhas) return;

    const erros = validarCampos();

    if (erros.filter((e) => !!e).length > 0) {
      toastNotification({
        message: 'Preencha todos os campos corretamente!',
        type: 'error',
      });
      return;
    }

    setGerandoCampanhas(true);

    let response = undefined;
    let responseProducao = undefined;
    try {
      if (file) {
        response = await uploadAmazonS3Arquivo(file);
      }
      if (formData.faturarProducao && fileProducao) {
        responseProducao = await uploadAmazonS3Arquivo(fileProducao);
      }
    } catch (error) {
      console.log(error);
    }

    salvarDados(response, responseProducao)
      .then(handleOnClose)
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setGerandoCampanhas(false));
  };

  const salvarDados = async (pedidoInsercao, pedidoInsercaoProducao) => {
    updateFaturamento({
      variables: {
        orcamento: {
          id: orcamento.id,
          titulo: formData.titulo?.trim(),
          dataInicio: Data.dataFormat(
            formData.dataInicio,
            'YYYY-MM-DD',
          ),
          comissaoTerceiros: Number.stringToFloat(
            formData.repasseTerceirosValor,
          ),
          formaPagamentoVeiculacao: formData.formaDePagamento?.value,
          parcelasVeiculacao: formData.parcelas,
          dataVencimentoVeiculacao: Data.dataFormat(
            formData.vencimentoParcelas,
            'YYYY-MM-DD',
          ),
          dataEmissaoNotaVeiculacao: Data.dataFormat(
            formData.dataEmitirNota,
            'YYYY-MM-DD',
          ),
          faturarPor: formData.faturarPor?.value,
          faturarProducao: formData.faturarProducao,
          formaPagamentoProducao: formData.formaDePagamentoProducao?.value,
          parcelasProducao: formData.parcelasProducao,
          dataVencimentoProducao: Data.dataFormat(
            formData.vencimentoParcelasProducao,
            'YYYY-MM-DD',
          ),
          dataEmissaoNotaProducao: Data.dataFormat(
            formData.dataEmitirNotaProducao,
            'YYYY-MM-DD',
          ),
          piVeiculacao:
            pedidoInsercao?.id || formData.pedidoInsercao?.id
              ? {id: pedidoInsercao?.id || formData.pedidoInsercao?.id}
              : undefined,
          piProducao:
            pedidoInsercaoProducao?.id || formData.pedidoInsercaoProducao?.id
              ? {
                  id:
                    pedidoInsercaoProducao?.id ||
                    formData.pedidoInsercaoProducao?.id,
                }
              : undefined,
        },
      },
    })
      .then(() =>
        toastNotification({
          message: 'Dados de faturamento salvos!',
          type: 'success',
        }),
      )
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Modal
      componentsProps={{
        backdrop: {
          sx: {
            background: 'rgb(0, 0, 0, 0.0)',
          },
        },
      }}
      onClose={handleOnClose}
      open={isOpen}>
      <Slide direction="left" in={isOpen} appear>
        <Box sx={styles.modal}>
          <Grid sx={styles.container}>
            <ReactToPrint
              pageStyle={pageStyle}
              documentTitle={`KM Money - PI ${
                proposta?.anunciante?.nomeFantasia || 'Anunciante'
              }`}
              onBeforePrint={() => setGerandoPI(false)}
              onBeforeGetContent={() => setGerandoPI(true)}
              trigger={() => (
                <button ref={printButtonRef} style={{display: 'none'}} />
              )}
              content={() => componentRef.current}
            />
            <Grid sx={styles.header}>
              <ArrowForward sx={styles.arrowBack} onClick={handleOnClose} />
              <Typography sx={styles.headerTitle}>Faturamento</Typography>
            </Grid>
            <Grid className="styled-scroll" sx={styles.content}>
              <Grid container sx={styles.formulario}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputV2
                      label="Título"
                      value={formData.titulo || ''}
                      onChange={({target}) =>
                        setFormData({...formData, titulo: target.value})
                      }
                      error={fieldErro.includes('titulo')}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputDateV2
                      label="Previsão de início"
                      selected={formData.dataInicio}
                      minDate={new Date()}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          dataInicio: new Date(e),
                        })
                      }
                      error={fieldErro.includes('dataInicio')}
                    />
                  </Grid>
                  <Grid container item xs={3} spacing={0}>
                    <Grid item xs={5}>
                      <InputV2
                        label="Terceiros"
                        name="repasseTerceiros"
                        mask="porcento"
                        value={formData.repasseTerceiros}
                        onChange={({target}) =>
                          handleChangeFieldPercent(
                            'repasseTerceirosValor',
                            'repasseTerceiros',
                            target.value,
                            Number.stringToFloat(
                              formData.valorTotalVeiculacaoMensal,
                            ) - Number.stringToFloat(formData.comissaoAgencia),
                          )
                        }
                        error={fieldErro.includes('repasseTerceiros')}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <InputV2
                        label="Valor / mês"
                        name="repasseTerceirosValor"
                        mask="moeda"
                        value={formData.repasseTerceirosValor}
                        onChange={({target}) =>
                          handleChangeFieldValor(
                            'repasseTerceirosValor',
                            'repasseTerceiros',
                            target.value,
                            Number.stringToFloat(
                              formData.valorTotalVeiculacaoMensal,
                            ) - Number.stringToFloat(formData.comissaoAgencia),
                          )
                        }
                        error={fieldErro.includes('repasseTerceiros')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={styles.subtitle}>
                      Faturamento da veiculação
                    </Typography>
                    <Grid container spacing={2} pt={2}>
                      <Grid item xs={4}>
                        <Select
                          label="Formas de Pagamento"
                          options={[
                            {value: 'CARTAO', label: 'Cartão'},
                            {value: 'BOLETO', label: 'Boleto'},
                            {
                              value: 'TRANSFERENCIA_BANCARIA',
                              label: 'Transferência Bancária',
                            },
                            {value: 'PERMUTA', label: 'Permuta'},
                          ]}
                          value={formData.formaDePagamento}
                          onChange={(e) =>
                            setFormData({...formData, formaDePagamento: e})
                          }
                          error={fieldErro.includes('formaDePagamento')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputV2
                          label="Parcelas"
                          type="number"
                          value={formData.parcelas}
                          onChange={({target}) =>
                            setFormData({...formData, parcelas: target.value})
                          }
                          error={fieldErro.includes('parcelas')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputDateV2
                          label={'Vencimento da 1ª parcela'}
                          selected={formData.vencimentoParcelas}
                          minDate={new Date()}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              vencimentoParcelas: new Date(e),
                            })
                          }
                          error={fieldErro.includes('vencimentoParcelas')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputDateV2
                          label={'Emissão 1ª NF'}
                          selected={formData.dataEmitirNota}
                          minDate={new Date()}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dataEmitirNota: new Date(e),
                            })
                          }
                          error={fieldErro.includes('dataEmitirNota')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Select
                          label="Faturar por"
                          options={[
                            {value: 'VALOR_BRUTO', label: 'Valor bruto'},
                            {value: 'VALOR_LIQUIDO', label: 'Valor líquido'},
                          ]}
                          value={formData.faturarPor}
                          onChange={(e) =>
                            setFormData({...formData, faturarPor: e})
                          }
                          error={fieldErro.includes('faturarPor')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                      <Typography sx={styles.subtitle}>
                        Faturamento da produção
                      </Typography>
                      <Checkbox
                        checked={formData.faturarProducao}
                        onChange={() =>
                          setFormData((f) => ({
                            ...f,
                            faturarProducao: !f.faturarProducao,
                          }))
                        }
                        sx={styles.checkbox}
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      pt={2}
                      style={{
                        visibility: formData.faturarProducao
                          ? 'visible'
                          : 'hidden',
                      }}>
                      <Grid item xs={4}>
                        <Select
                          label="Formas de Pagamento"
                          options={[
                            {value: 'CARTAO', label: 'Cartão'},
                            {value: 'BOLETO', label: 'Boleto'},
                            {
                              value: 'TRANSFERENCIA_BANCARIA',
                              label: 'Transferência Bancária',
                            },
                            {value: 'PERMUTA', label: 'Permuta'},
                          ]}
                          value={formData.formaDePagamentoProducao}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              formaDePagamentoProducao: e,
                            })
                          }
                          error={fieldErro.includes('formaDePagamentoProducao')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputV2
                          label="Parcelas"
                          type="number"
                          value={formData.parcelasProducao}
                          onChange={({target}) =>
                            setFormData({
                              ...formData,
                              parcelasProducao: target.value,
                            })
                          }
                          error={fieldErro.includes('parcelasProducao')}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputDateV2
                          label={'Vencimento da 1ª parcela'}
                          selected={formData.vencimentoParcelasProducao}
                          minDate={new Date()}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              vencimentoParcelasProducao: new Date(e),
                            })
                          }
                          error={fieldErro.includes(
                            'vencimentoParcelasProducao',
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputDateV2
                          label={'Emissão 1ª NF'}
                          selected={formData.dataEmitirNotaProducao}
                          minDate={new Date()}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dataEmitirNotaProducao: new Date(e),
                            })
                          }
                          error={fieldErro.includes('dataEmitirNotaProducao')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={styles.labelFaturamento}>
                      P.I. (Veiculação)
                    </Typography>
                    <DragNDrop
                      id={formData.pedidoInsercao?.id}
                      file={file}
                      onChange={setFile}
                      fileName={file?.name || formData.pedidoInsercao?.nome}
                      onDelete={() => {
                        setFile(undefined);
                        setFormData((f) => ({...f, pedidoInsercao: undefined}));
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      visibility: formData.faturarProducao
                        ? 'visible'
                        : 'hidden',
                    }}>
                    <Typography sx={styles.labelFaturamento}>
                      A.P. (Produção)
                    </Typography>
                    <DragNDrop
                      id={formData.pedidoInsercaoProducao?.id}
                      file={fileProducao}
                      onChange={setFileProducao}
                      fileName={
                        fileProducao?.name ||
                        formData.pedidoInsercaoProducao?.nome
                      }
                      onDelete={() => {
                        setFileProducao(undefined);
                        setFormData((f) => ({
                          ...f,
                          pedidoInsercaoProducao: undefined,
                        }));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={styles.footer}>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-start">
                  <ButtonComponent
                    id="button-cancel-empty"
                    type="button"
                    value="Cancelar"
                    icon={<Close />}
                    sx={styles.buttons}
                    onClick={onClose}
                    disabled={gerandoCampanhas}
                  />
                  <ButtonComponent
                    id="button"
                    type="button"
                    value="Imprimir PI padrão"
                    loading={gerandoPI}
                    icon={<NoteAdd />}
                    sx={styles.buttons}
                    onClick={() => {
                      salvarDados();
                      printButtonRef.current.click();
                    }}
                    style={{marginLeft: 8}}
                    disabled={gerandoPI}
                  />
                </Grid>
                {orcamento?.situacao?.value !== 'APROVADO' && (
                  <ButtonComponent
                    id="button-green"
                    type="button"
                    value="Enviar para aprovação"
                    loading={gerandoCampanhas}
                    icon={<Campaign />}
                    sx={styles.buttonsAprovar}
                    onClick={handleClickGerarCampanhas}
                    disabled={gerandoCampanhas}
                  />
                )}
                {orcamento?.situacao?.value === 'APROVADO' && (
                  <ButtonComponent
                    id="button-green"
                    type="button"
                    value="Salvar"
                    loading={gerandoCampanhas}
                    icon={<Save />}
                    sx={styles.buttons}
                    onClick={handleSalvarDados}
                    disabled={gerandoCampanhas}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <div style={{overflow: 'hidden', height: '0px'}}>
            <Grid ref={componentRef} style={{background: '#fff'}}>
              <ImpressaoPI
                proposta={proposta}
                orcamento={orcamento}
                faturamento={formData}
              />
            </Grid>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};

const styles = {
  modal: {
    width: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    height: (theme) => `calc(100% - 8px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,

    '@media only screen and (max-width: 64em)': {
      flexBasis: '100%',
      maxWidth: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    },
  },
  container: {
    width: '100%',
  },
  header: {
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2rem 1rem',
  },
  arrowBack: {
    color: '#657496',
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: '18px',
    textAlign: 'left',
    color: '#657496',
    marginLeft: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 110px)',
    overflowY: 'auto',
    padding: '1rem 2rem 2rem 2rem',
  },
  buttons: {
    width: '100%',
    marginBottom: '12px',
    whiteSpace: 'nowrap',

    '@media (min-width: 1024px)': {
      width: '185px',
    },
  },
  buttonsAprovar: {
    width: '100%',
    marginBottom: '12px',

    '@media (min-width: 1024px)': {
      width: '210px',
    },
  },
  buttonIconLeft: {
    fontSize: 20,
    margin: '0 10px 0 -20px',
  },
  buttonIconRight: {
    fontSize: 20,
    margin: '0 0 3px 10px',
  },
  formulario: {
    marginBottom: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    paddingTop: 4,

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  form: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 50px)',
    overflowX: 'hidden',

    '@media (min-width: 1024px)': {
      paddingRight: '15px',
      overflowX: 'auto',
    },
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  subtitle: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    marginBottom: '3px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  labelFaturamento: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    marginBottom: '3px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
  checkbox: {
    padding: 0,
    margin: '0 10px',
    marginBottom: '-5px',

    '&.Mui-checked': {
      color: '#8995b1',
    },
  },
};

export default GerarCampanhaModal;
