import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CadastrarExecutivo from './cadastrar';
import ConsultaExecutivo from './consulta';
import EditarExecutivo from './editar';

const Executivo = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaExecutivo />} />
      <Route path={`/:executivoId/editar`} element={<EditarExecutivo />} />
      <Route path={`/criar`} element={<CadastrarExecutivo />} />
    </Routes>
  );
};

export default Executivo;
