import React from 'react';
import {Grid, Modal} from '@mui/material';
import {Cancel} from '@mui/icons-material';

const ModalComponent = ({
  children,
  title,
  showCloseButton = true,
  onClose,
  visible,
  ...props
}) => (
  <Modal
    open={visible}
    onClose={onClose}
    aria-labelledby="server-modal-title"
    aria-describedby="server-modal-description"
    sx={styles.modal}
  >
    <Grid container sx={styles.modalContent}>
      <Grid sx={styles.header}>
        {showCloseButton && (
          <Cancel sx={styles.buttonClose} onClick={onClose} />
        )}
      </Grid>
      {children}
    </Grid>
  </Modal>
);

const styles = {
  modal: {
    zIndex: '10 !important'
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    minHeight: '90%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '16px',
    flexDirection: 'column',

    '@media (min-width: 1024px)': {
      width: '70%',
    },
    '@media (min-width: 1367px)': {
      minHeight: '80%',
    },
    '@media (min-width: 1920px)': {
      width: '50%',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: '28px',
  },
  buttonClose: {
    color: '#0083e6',
    cursor: 'pointer',
  },
};

export default ModalComponent;
