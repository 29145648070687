import {useMutation} from '@apollo/client';
import {ChevronLeft, Edit, Person, Save} from '@mui/icons-material';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {confirmAlertCustom} from '../../components/confirm-alert/confirm-alert';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {
  ButtonComponent,
  ButtonOutlineComponent,
} from '../../components/mui-button';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import {toastNotification} from '../../components/toastify';
import {SelectCidades} from '../../containers/selects/cidades';
import {SelectSegmentos} from '../../containers/selects/segmento';
import {
  ATUALIZAR_ANUNCIANTE,
  GERAR_USUARIO_SENHA,
} from '../../graphql/mutation';
import {coordenadasPorEndereco} from '../../services/GeocodingService';
import {uploadAmazonS3} from '../../services/UploadService';
import Objects from '../../utils/objects';
import string from '../../utils/string';

const camposObrigatorios = [
  'nomeRazao',
  'nomeFantasia',
  'cnpj',
  'cep',
  'cidade',
  'logradouro',
  'bairro',
  'numero',
  'telefone',
  'email',
  'marketingNome',
  'marketingTelefone',
  'marketingEmail',
];

const FormularioAnunciante = ({anunciante = {}, refetch}) => {
  const navigate = useNavigate();

  const [readOnly, setReadOnly] = useState(!!anunciante.id);
  const [fotoPerfil, setFotoPerfil] = useState(anunciante.fotoPerfil);
  const [salvando, setSalvando] = useState(false);
  const [uploadImage, setUploadImage] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);

  const [atualizarAnunciante] = useMutation(ATUALIZAR_ANUNCIANTE);
  const [gerarUsuario, {loading: loadingUsuario}] =
    useMutation(GERAR_USUARIO_SENHA);

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleClickSalvar = async ({
    cep,
    logradouro,
    cidade,
    numero,
    complemento,
    bairro,
    agencia,
    segmentoAtuacao,
    ...values
  }) => {
    const dadosForm = {
      ...values,
      nomeFantasia: values?.nomeFantasia?.trim(),
      cnpj: string.removeSpecialChars(values.cnpj),
      telefone: string.removeSpecialChars(values.telefone),
      celular: string.removeSpecialChars(values.celular),
      marketingTelefone: string.removeSpecialChars(values.marketingTelefone),
      financeiroTelefone: string.removeSpecialChars(values.financeiroTelefone),
      marketingTelefoneAdicional: string.removeSpecialChars(
        values.marketingTelefoneAdicional,
      ),
      financeiroTelefoneAdicional: string.removeSpecialChars(
        values.financeiroTelefoneAdicional,
      ),
      agencia: Objects.jsToEntityId(agencia),
      segmentoAtuacao: Objects.jsToEntityId(segmentoAtuacao),
      endereco: {
        cep: string.removeSpecialChars(cep),
        logradouro,
        cidade: Objects.jsToEntityId(cidade),
        numero,
        complemento,
        bairro,
      },
    };

    setSalvando(true);

    try {
      let uploadData = null;
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }

      const coordenadasEndereco = await coordenadasPorEndereco({
        ...dadosForm.endereco,
        cidade,
      });

      const dadosAnunciante = {
        ...dadosForm,
        id: anunciante.id,
        endereco: {
          ...dadosForm.endereco,
          latitude: coordenadasEndereco?.lat,
          longitude: coordenadasEndereco?.lng,
        },
        fotoPerfil: uploadData?.id || anunciante?.fotoPerfil,
      };

      const {data} = await atualizarAnunciante({
        variables: {
          anunciante: {
            ...dadosAnunciante,
            cadastroCompleto: !camposNaoPreenchidos({
              ...values,
              cep,
              cidade,
              logradouro,
              bairro,
              numero,
            }),
          },
        },
      });

      if (!anunciante?.id && data?.updateAnunciante?.id)
        navigate(`/anunciantes/${data?.updateAnunciante?.id}/editar`);

      setReadOnly(true);
      refetch && refetch();
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSalvando(false);
    }
  };

  const onClickVoltar = () => {
    if (readOnly) {
      navigate('/anunciantes');
    } else {
      confirmAlertCustom({
        message: 'Deseja descartar as alterações?',
        onConfirm: () => navigate('/anunciantes'),
        onCancel: () => null,
      });
    }
  };

  const gerarUsuarioSenhaAnunciante = (values) => {
    if (camposNaoPreenchidos(values)) {
      toastNotification({
        message: 'Complete o cadastro para gerar o usuário',
        type: 'error',
      });
      return;
    }

    if (!values.emailUsuario) {
      toastNotification({
        message: 'Preencha o e-mail do usuário para gerar o usuário',
        type: 'error',
      });
      return;
    }

    gerarUsuario({
      variables: {
        anunciante: {
          id: anunciante.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.gerarUsuarioSenhaAnunciante}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.gerarUsuarioSenhaAnunciante);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const camposNaoPreenchidos = (values) => {
    return camposObrigatorios.filter((campo) => !values[campo]).length > 0;
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={ClienteScheme}
          initialValues={{
            nomeRazao: anunciante.nomeRazao || '',
            nomeFantasia: anunciante.nomeFantasia || '',
            cnpj: anunciante.cnpj || '',
            inscricaoEstadual: anunciante.inscricaoEstadual || '',
            cep: anunciante.endereco?.cep || '',
            cidade: anunciante.endereco?.cidade,
            segmentoAtuacao: anunciante.segmentoAtuacao,
            logradouro: anunciante.endereco?.logradouro || '',
            bairro: anunciante.endereco?.bairro || '',
            numero: anunciante.endereco?.numero || '',
            complemento: anunciante.endereco?.complemento || '',
            nomeContato: anunciante.nomeContato || '',
            telefone: anunciante.telefone || '',
            email: anunciante.email || '',
            comercial: {id: anunciante.comercial?.id},
            agencia: anunciante.agencia,
            celular: anunciante.celular || '',
            site: anunciante.site || '',
            financeiroNome: anunciante.financeiroNome || '',
            financeiroEmail: anunciante.financeiroEmail || '',
            financeiroTelefone: anunciante.financeiroTelefone || '',
            financeiroNomeAdicional: anunciante.financeiroNomeAdicional || '',
            financeiroTelefoneAdicional:
              anunciante.financeiroTelefoneAdicional || '',
            financeiroEmailAdicional: anunciante.financeiroEmailAdicional || '',
            marketingNome: anunciante.marketingNome || '',
            marketingEmail: anunciante.marketingEmail || '',
            marketingTelefone: anunciante.marketingTelefone || '',
            marketingNomeAdicional: anunciante.marketingNomeAdicional || '',
            marketingTelefoneAdicional:
              anunciante.marketingTelefoneAdicional || '',
            marketingEmailAdicional: anunciante.marketingEmailAdicional || '',
            emailUsuario: anunciante.emailUsuario || '',
          }}
          enableReinitialize
          onSubmit={handleClickSalvar}>
          {({handleSubmit, values}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <UploadableFotoPerfil
                    image={previewImage || fotoPerfil}
                    setImage={setFotoPerfil}
                    handleChangeUploadImage={handleChangeUploadImage}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  direction={'row'}
                  spacing={2}
                  alignContent={'space-around'}>
                  <Grid item xs={12}>
                    <Field
                      component={InputV2Formik}
                      name="nomeRazao"
                      label="Razão social"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={InputV2Formik}
                      name="nomeFantasia"
                      label="Nome fantasia"
                      sx={!values.nomeFantasia && styles.campoObrigatorio}
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="cnpj"
                    label="CNPJ"
                    mask="cnpj"
                    sx={!values.cnpj && styles.campoObrigatorio}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="inscricaoEstadual"
                    label="Insrição estadual"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={SelectSegmentos}
                    name="segmentoAtuacao"
                    label="Segmento de atuação"
                    isClearable
                    sx={!values.segmentoAtuacao && styles.campoObrigatorio}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone / Empresa"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="site"
                    label="Site"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="email"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Endereço
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={SelectCidades}
                    name="cidade"
                    label="Cidade/Estado"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Marketing
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingNome"
                    label="Contato / Marketing"
                    sx={!values.marketingNome && styles.campoObrigatorio}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingTelefone"
                    label="Telefone"
                    mask="phone"
                    sx={!values.marketingTelefone && styles.campoObrigatorio}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingEmail"
                    label="E-mail"
                    sx={!values.marketingEmail && styles.campoObrigatorio}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingNomeAdicional"
                    label="Contato / Marketing 2"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingTelefoneAdicional"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingEmailAdicional"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Financeiro
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroNome"
                    label="Contato / Financeiro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroTelefone"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroEmail"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroNomeAdicional"
                    label="Contato / Financeiro 2"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroTelefoneAdicional"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroEmailAdicional"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="emailUsuario"
                    label="E-mail usuário"
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 25,
                }}>
                <Grid sx={styles.buttonContainer}>
                  <Grid item xs={6}>
                    <ButtonOutlineComponent
                      type="button"
                      value="Voltar"
                      leftIcon={<ChevronLeft />}
                      onClick={onClickVoltar}
                      sx={styles.button}
                    />
                  </Grid>
                </Grid>
                <Grid sx={styles.buttonContainer}>
                  <Grid item xs={6}>
                    <ButtonComponent
                      id="button-green"
                      type="button"
                      value="Gerar usuário"
                      icon={<Person />}
                      loading={loadingUsuario}
                      onClick={() => gerarUsuarioSenhaAnunciante(values)}
                      sx={[styles.button, {marginRight: 2}]}
                      disabled={!readOnly || loadingUsuario}
                    />
                  </Grid>
                  {readOnly && (
                    <Grid item xs={6}>
                      <ButtonComponent
                        id="button"
                        type="button"
                        value="Editar"
                        icon={<Edit />}
                        onClick={() => setReadOnly(false)}
                        sx={styles.button}
                      />
                    </Grid>
                  )}
                  {!readOnly && (
                    <Grid item xs={6}>
                      <ButtonComponent
                        id="button"
                        type="submit"
                        value="Salvar"
                        loading={salvando}
                        icon={<Save />}
                        sx={styles.button}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const ClienteScheme = Yup.object().shape({
  nomeFantasia: Yup.string().required('Campo obrigatório'),
  cnpj: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cnpj', 'CNPJ inválido', (value) => string.validaCNPJ(value)),
  marketingNome: Yup.string().required('Campo obrigatório'),
  marketingTelefone: Yup.string().required('Campo obrigatório'),
  marketingEmail: Yup.string().required('Campo obrigatório'),
  segmentoAtuacao: Yup.object().nullable().required('Campo obrigatório'),
});

const styles = {
  button: {
    width: 200,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: ' 14px',
    fontWeight: '900',
    marginBottom: '16px',
  },
  campoObrigatorio: {
    '& input': {
      border: '1px solid #ff5252',
    },
    '& .react-select__control': {
      border: '1px solid #ff5252',
    },
  },
};

export default FormularioAnunciante;
