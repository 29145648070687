import React from 'react';
import styled from '@emotion/styled';
import {Tooltip, IconButton, Button} from '@mui/material';
import {ChevronLeftRounded, ChevronRightRounded} from '@mui/icons-material';
import LottieIcon from '../components/lottie-icon';
import {LoadingCircle, LoadingCircleGrey} from '../assets/lotties';

const ArrowLeftSmallIconStyled = styled(ChevronLeftRounded)`
  color: #0083e6;
  margin-right: 5px;
`;
const ArrowRightSmallIconStyled = styled(ChevronRightRounded)`
  color: #fff;
  margin-left: 5px;
`;

const ButtonStyled = styled.button`
  height: ${(props) => getHeightButtonBySize(props.size)};
  line-height: ${(props) => getHeightButtonBySize(props.size)};
  border: 0;
  font-size: ${(props) => getFontSizeButtonBySize(props.size)};
  background-color: #0083e6;
  color: ${(props) => (props.disabled ? '#cecece' : '#ffffff')};
  width: 100%;
  min-width: 185px;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

const ButtonOutline = styled(ButtonStyled)`
  border: solid 1px #0083e6;
  background-color: #fff;
  color: #0083e6;
`;

export const ButtonComponent = ({
  loading,
  onClick,
  id = 'button',
  value,
  icon,
  sx,
  ...props
}) => (
  <Button
    id={id}
    endIcon={loading ? <LottieIcon icon={LoadingCircle} /> : icon}
    fullWidth
    sx={sx}
    style={{textTransform: 'none'}}
    onClick={onClick}
    {...props}
  >
    {value}
  </Button>
);

export const ButtonWithIcon = ({
  loading,
  onClick,
  endIcon,
  startIcon,
  id = 'button',
  value,
  ...props
}) => (
  <Button
    id={id}
    endIcon={endIcon && loading ? <LottieIcon icon={LoadingCircle} /> : endIcon}
    startIcon={startIcon && loading ? <LottieIcon icon={LoadingCircle} /> : startIcon}
    onClick={onClick}
    {...props}
  >
    {value}
  </Button>
);

export const ButtonOutlineComponent = ({
  value,
  leftIcon,
  rightIcon,
  ...props
}) => (
  <ButtonOutline {...props}>
    {leftIcon}
    {value}
    {rightIcon}
  </ButtonOutline>
);

export const RoundTooltipButton = ({
  title,
  icon,
  className,
  loading,
  width = '40px',
  height = '40px',
  loadingColor,
  hidden,
  ...props
}) => {
  if (hidden) return;

  return (
    <Tooltip title={title} placement="top">
      <IconButton
        {...props}
        sx={{width: `${width}`, height: `${height}`, ...props.sx}}>
        {loading ? <LottieIcon icon={loadingColor === 'grey' ? LoadingCircleGrey : LoadingCircle} /> : icon}
      </IconButton>
    </Tooltip>
  );
};

export const BackButton = ({outline = true, ...props}) => {
  const Button = outline ? ButtonOutlineComponent : ButtonComponent;

  return <Button leftIcon={<ArrowLeftSmallIconStyled />} {...props} />;
};

export const NextButton = ({outline = false, ...props}) => {
  const Button = outline ? ButtonOutlineComponent : ButtonComponent;

  return <Button rightIcon={<ArrowRightSmallIconStyled />} {...props} />;
};

const getHeightButtonBySize = (size) => {
  switch (size) {
    case 'xl':
      return '56px';
    case 'lg':
      return '48px';
    case 'md':
      return '40px';
    case 'sm':
      return '40px';
    case 'xs':
      return '32px';
    default:
      return '40px';
  }
};

export const ButtonTooltip = ({title, value, sx, onClick, ...props}) => {
  return (
    <Tooltip title={title} placement="top">
      <Button type="button" onClick={onClick} sx={sx} {...props}>
        {value}
      </Button>
    </Tooltip>
  );
};

const getFontSizeButtonBySize = (size) => {
  switch (size) {
    case 'xl':
      return '16px';
    case 'lg':
      return '16px';
    case 'md':
      return '14px';
    case 'sm':
      return '14px';
    case 'xs':
      return '12px';
    default:
      return '14px';
  }
};
