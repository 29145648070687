import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Data from '../../utils/data';
import Number from '../../utils/number';

const Divisor = require('../../assets/images/divisor-relatorio.png');
const ImgMidiaInterna = require('../../assets/images/mockup-midia-interna.png');
const ImgCircuito = require('../../assets/images/mockup-circuito.png');
const ImgPlus = require('../../assets/images/mockup-plus.png');
const ImgStandard = require('../../assets/images/mockup-standard.png');
const ImgLight = require('../../assets/images/mockup-light.png');

const getMockupImage = (modelo = {}) => {
  const conditions = [
    {check: modelo.frente && modelo.laterais && modelo.traseira && modelo.interno && modelo.interativo, image: ImgCircuito},
    {check: modelo.laterais && modelo.traseira, image: ImgPlus},
    {check: modelo.lateraisRecorte && modelo.traseira, image: ImgStandard},
    {check: modelo.traseira, image: ImgLight},
  ];

  const mockup = conditions.find((condition) => condition.check);
  return mockup ? mockup.image : ImgMidiaInterna;
};

const ImpressaoOrcamento = ({orcamento, orcamentoItens}) => {
  const [formData, setFormData] = useState({});
  const [itens, setItens] = useState([]);

  useEffect(() => {
    setFormData(orcamento);
    setItens(orcamentoItens);
  }, [orcamento, orcamentoItens]);

  const numeroCidade = itens.length;
  let totalValorDescontadoTodosVeiculos = 0;
  let totaisVeiculosMesaisTotais = 0;
  let totaisValorTotalCidade = 0;
  let totaisValorTotalCidadeTodosPeriodos = 0;
  let totalKmEstimado = 0;
  let totalImpactosExternosEstimados = 0;
  let totalImpactosInternosEstimados = 0;
  let somaTotaisImpactos = 0;
  let custoTotalCPM = 0;
  const valorReservaTecnica = (formData?.totalVeiculos * 0.1).toFixed();
  const dataProducao = [
    {
      totalVeiculos: formData?.totalVeiculos,
      reservaTecnica: `(10%)`,
      carrosProducaoFinal: Number.safeParseFloat(formData?.totalVeiculos) + Number.safeParseFloat(valorReservaTecnica),
      valorProducaoLiquido: Number.currencyFormat(1.1 * Number.currencyToFloat(formData?.valorTotalProducao)),
    },
  ];
  for (var c = 1; c < formData.quantidadeMeses; c++) {
    dataProducao.push({
      totalVeiculos: 0,
      reservaTecnica: `(10%)`,
      carrosProducaoFinal: valorReservaTecnica,
      valorProducaoLiquido: Number.currencyFormat(0.1 * Number.currencyToFloat(formData?.valorTotalProducao)),
    });
  }

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img alt={'divisor'} style={{width: '100%', height: '2px', margin: '10px 0'}} src={Divisor} />
      </Grid>
      <Grid container item xs={12} sx={styles.reportHeader}>
        <Grid item xs={6}>
          <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
            Proposta:
          </Typography>
          {formData?.anunciante && <Typography sx={styles.titleReport}>{`Cliente: ${formData?.anunciante?.nomeFantasia || ''}`}</Typography>}
          <Typography sx={styles.titleReport}>{`Título da campanha: ${formData?.titulo || ''}`}</Typography>
          {formData?.dataInicio && <Typography sx={styles.titleReport}>{`Início da veiculação: ${Data.dataFormat(formData?.dataInicio)}`}</Typography>}
          <Typography sx={styles.titleReport}>{`Período de veiculação: ${formData?.quantidadeMeses * formData?.diasPeriodo?.value} dias`}</Typography>
        </Grid>
        <Grid item xs={3} mr="auto">
          <img alt={'Mockup do modelo de anúncio'} style={{height: '100px', margin: '10px 0 0 0'}} src={getMockupImage(itens?.[0]?.modeloAnuncio)} />
          <Typography sx={styles.mockupLabel}>{itens?.[0]?.modeloAnuncio?.nome || ''}</Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '100%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
          Métricas estimadas da campanha:
        </Typography>
        <Table stickyHeader aria-label="sticky table" sx={styles.tableVeiculacao}>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHead}>Cidades</TableCell>
              <TableCell sx={styles.tableHead}>Numero de carros</TableCell>
              <TableCell sx={styles.tableHead}>Quilometragem estimada</TableCell>
              <TableCell sx={styles.tableHead}>Impactos externos estimados</TableCell>
              <TableCell sx={styles.tableHead}>Impactos internos estimados</TableCell>
              <TableCell sx={styles.tableHead}>Total de impactos</TableCell>
              <TableCell sx={styles.tableHead}>CPM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((item, index) => {
              const {kmEstimado, impactosExternosEstimados, impactosInternosEstimados, totalImpactos} = Number.calcularMetricas(item, formData?.quantidadeMeses);
              const custoCidade = formData?.quantidadeMeses * Number.stringToFloat(item?.valorTotalCidade);

              // Atualização dos totais
              totalKmEstimado += kmEstimado;
              totalImpactosExternosEstimados += impactosExternosEstimados;
              totalImpactosInternosEstimados += impactosInternosEstimados;
              custoTotalCPM += custoCidade;
              somaTotaisImpactos += totalImpactos;
              return (
                <TableRow tabIndex={-1} key={`estimado${index}`} sx={styles.tableRowPrincipal}>
                  <TableCell>{item?.cidade?.nome}</TableCell>
                  <TableCell>{item?.quantidadeVeiculos}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(kmEstimado))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(impactosExternosEstimados))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(impactosInternosEstimados))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(totalImpactos))}</TableCell>
                  <TableCell>{Number.currencyFormat((custoCidade / totalImpactos) * 1000)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell sx={styles.tableHead} align="left">
                Total de cidades : {numeroCidade}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {formData?.totalVeiculos}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalKmEstimado))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalImpactosExternosEstimados))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalImpactosInternosEstimados))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(somaTotaisImpactos))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.currencyFormat((custoTotalCPM / somaTotaisImpactos) * 1000)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell rowSpan={1} colSpan={8} sx={styles.footerSpace}>
                <Typography sx={styles.footerText} style={{height: 5}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid item xs={12} sx={styles.table}>
        <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
          Precificação
        </Typography>
        <Table stickyHeader aria-label="sticky table" sx={styles.tableVeiculacao}>
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell sx={styles.tableHead} align={'left'}>
                Cidades
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Produto
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Bruto unitário
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Nº de carros
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Total bruto
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Desconto
              </TableCell>
              {formData?.quantidadeMeses > 1 && (
                <TableCell sx={styles.tableHead} align={'left'}>
                  Total bruto negociado por período
                </TableCell>
              )}
              <TableCell sx={styles.tableHead} align={'left'}>
                Total bruto negociado {formData?.quantidadeMeses > 1 ? 'todos os períodos' : ''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((item, index) => {
              const valorTotalMensalVeiculo = Number.stringToFloat(item?.quantidadeVeiculos) * Number.stringToFloat(item?.valorMensalVeiculo);
              const totalNegociadoTodosPeriodos = formData?.quantidadeMeses * Number.stringToFloat(item?.valorTotalCidade);
              const valorDescontadoTodosVeiculos = Number.stringToFloat(item?.quantidadeVeiculos) * Number.stringToFloat(item?.valorDescontoPorVeiculo);
              totalValorDescontadoTodosVeiculos = totalValorDescontadoTodosVeiculos + valorDescontadoTodosVeiculos;
              totaisVeiculosMesaisTotais = totaisVeiculosMesaisTotais + valorTotalMensalVeiculo;
              totaisValorTotalCidade = totaisValorTotalCidade + (Number.stringToFloat(item?.valorTotalCidade) || 0);
              totaisValorTotalCidadeTodosPeriodos = totaisValorTotalCidadeTodosPeriodos + totalNegociadoTodosPeriodos;
              return (
                <TableRow tabIndex={-1} key={`itemA${index}`} sx={styles.tableRowPrincipal}>
                  <TableCell>{`${item?.cidade?.nome}/${item?.cidade?.uf}`}</TableCell>
                  <TableCell>{item?.modeloAnuncio?.nome}</TableCell>
                  <TableCell>{item?.valorMensalVeiculo}</TableCell>
                  <TableCell>{item?.quantidadeVeiculos}</TableCell>
                  <TableCell>{Number.currencyFormat(valorTotalMensalVeiculo)}</TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>{`${Number.currencyFormat(valorDescontadoTodosVeiculos)} (${Number.stringToFloat(item?.desconto)}%)`}</TableCell>
                  {formData?.quantidadeMeses > 1 && <TableCell align={'left'}>{item?.valorTotalCidade}</TableCell>}
                  <TableCell align={'left'}>{Number.currencyFormat(totalNegociadoTodosPeriodos)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow tabIndex={-1} key={`itemAfinal`}>
              <TableCell sx={styles.tableHead} align="left" style={{whiteSpace: 'nowrap'}}>
                Total de cidades: {numeroCidade}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell sx={styles.tableHead}>{formData?.totalVeiculos}</TableCell>
              <TableCell sx={styles.tableHead}>{Number.currencyFormat(totaisVeiculosMesaisTotais)}</TableCell>
              <TableCell sx={styles.tableHead}>{Number.currencyFormat(totalValorDescontadoTodosVeiculos)}</TableCell>
              {formData?.quantidadeMeses > 1 && (
                <TableCell sx={styles.tableHead} align="left">
                  {Number.currencyFormat(totaisValorTotalCidade)}
                </TableCell>
              )}
              <TableCell sx={styles.tableHead} align="left">
                {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell rowSpan={1} colSpan={8} sx={styles.footerSpace}>
                <Typography sx={styles.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid container sx={styles.tableResumoVeiculação}>
        <Grid item xs={4} sx={styles.tableHead}>
          Total bruto negociado
        </Grid>
        <Grid item xs={8} sx={styles.tablecellResumoVeiculacao}>
          {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
        </Grid>
        <Grid item xs={4} sx={styles.tableHead}>
          Comissão da agência
        </Grid>
        <Grid item xs={8} sx={styles.tablecellResumoVeiculacao}>
          {Number.currencyFormat(Number.currencyToFloat(formData?.comissaoAgencia) * formData.quantidadeMeses)}
        </Grid>
        <Grid item xs={4} sx={styles.tableHead} style={{borderBottom: '1px solid #E0E0E0'}}>
          Total líquido
        </Grid>
        <Grid item xs={8} sx={styles.tablecellResumoVeiculacao} style={{borderBottom: '1px solid #E0E0E0'}}>
          {Number.currencyFormat(Number.currencyToFloat(totaisValorTotalCidadeTodosPeriodos) - Number.currencyToFloat(formData?.comissaoAgencia) * formData.quantidadeMeses)}
        </Grid>
      </Grid>
      {formData?.observacoes && (
        <Grid item xs={12} sx={styles.observacoes}>
          <Typography sx={styles.tableHead}>Observações:</Typography>
          <Typography component="pre">{formData?.observacoes}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={styles.table}>
        <Typography gutterBottom sx={{fontWeight: 'bold'}} pt={3}>
          NORMAS E PROCEDIMENTOS DE COMERCIALIZAÇÃO KM MÍDIA OOH
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Prazo de Pagamento
        </Typography>
        <Typography paragraph>30 dias após o término da veiculação.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Prazo de Entrega
        </Typography>
        <Typography paragraph>
          Até 7 dias úteis após o recebimento do material e a aprovação da prova de cor a ser veiculado dentro das especificações técnicas da KM Mídia OOH para cidades com população acima de 100.000
          habitantes. Para cidades com população inferior a 100.000 habitantes, o prazo será de 10 dias úteis.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Pré-requisito
        </Typography>
        <Typography paragraph>O PI deverá ser entregue na aprovação do projeto, devidamente assinada pelo cliente e/ou agência.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Produção do Material
        </Typography>
        <Typography paragraph>A criação do material é de total responsabilidade do cliente. A impressão e instalação são de responsabilidade da KM Mídia OOH.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Especificações Técnicas
        </Typography>
        <Typography paragraph>
          No momento da aprovação da veiculação, a KM Mídia OOH enviará ao cliente um arquivo contendo todas as especificações técnicas dos materiais a serem produzidos. A KM Mídia OOH não aceitará
          materiais fora da especificação, sendo responsabilidade do cliente ou agência realizar os ajustes necessários.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Cancelamentos e Alterações
        </Typography>
        <Typography paragraph>Campanhas ativas não serão canceladas. Campanhas podem ser canceladas com até 30 dias de antecedência, a contar do início da veiculação.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Restrições de Exibição
        </Typography>
        <Typography paragraph>
          A publicidade veiculada não poderá conter propaganda político-partidária, de cunho religioso, de empresas que exploram o trabalho infantil ou que prejudicam o meio ambiente. Anúncios que
          promovam discriminação ou desrespeitem a legislação vigente também serão vetados, assim como aqueles restritos pelo Conselho Nacional de Autorregulamentação Publicitária (CONAR).
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Informações Obrigatórias no PI
        </Typography>
        <Typography>Todo PI deverá incluir as seguintes informações, conforme proposta acordada entre o cliente e a KM Mídia OOH:</Typography>
        <Typography component="ul" paragraph>
          <li>Quilometragem estimada no período;</li>
          <li>Impactos externos estimados no período;</li>
          <li>Corridas estimadas no período;</li>
          <li>Impactos internos estimados no período.</li>
        </Typography>

        <Typography component="p" pt={3} sx={{fontWeight: 'bold', pageBreakBefore: 'always'}}>
          Check-in
        </Typography>
        <Typography paragraph>
          No momento da instalação, serão tiradas fotos que constarão no painel do anunciante, incluindo as seguintes informações por motorista: nome do motorista, cor do veículo, ano e modelo de
          fabricação, placa do veículo, odômetro inicial, quilometragem individual de cada motorista, data, horário, latitude e longitude, e a propaganda já adesivada conforme o modelo KM Mídia OOH
          contratado pelo cliente.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Check-out
        </Typography>
        <Typography paragraph>
          A desinstalação dos adesivos é de responsabilidade da KM Mídia OOH e será realizada no dia seguinte ao término da campanha. Todas as fotos estarão disponíveis no painel do anunciante em até
          5 dias úteis após o término da campanha, incluindo a foto do odômetro final, data, horário, latitude e longitude.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Login e Senha
        </Typography>
        <Typography paragraph>
          Cada anunciante receberá um login e senha exclusivos para acompanhar em tempo real a geração de todas as métricas e a evolução da campanha através do painel do anunciante, acessível pelo
          site: <a href="www.kmmidia.com.br">www.kmmidia.com.br</a>, na aba "Login Anunciante".
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Falhas de Exibição
        </Typography>
        <Typography paragraph>
          Qualquer falha na exibição detectada pelo cliente deve ser comunicada à KM Mídia, que terá o prazo de 48 horas para apresentar uma solução durante o período de veiculação. Após a finalização
          da veiculação, não serão aceitas reivindicações (ex.: adesivos danificados).
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Entrega de Resultados
        </Typography>
        <Typography paragraph>
          A KM Mídia OOH comercializa, através de seu dashboard, métricas como quilometragem, impactos externos, impactos internos, corridas e CPM, atrelados a um número total de carros para cada
          cidade. Todas as métricas, bem como a sugestão de quantidade de carros para o alcance das metas, são baseadas em pesquisas do Instituto Americano Nielsen e densidades demográficas por cidade
          (Fonte: IBGE). Será considerada uma entrega de 100% a campanha que alcançar 100% das métricas propostas e documentadas no PI, considerando a entrega total de carros.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Geração de Métricas KM Mídia
        </Typography>
        <Typography paragraph>
          A geração das métricas tem como base inicial a quilometragem real gerada pelos veículos, comprovada pelas fotos do odômetro inicial e final registradas no check-in e check-out. A partir da
          quilometragem, são geradas as demais métricas.
        </Typography>
      </Grid>
      <Grid item xs={12} className={`page-footer`} sx={styles.footer} style={{display: 'none'}}>
        <Grid item xs={12} sx={styles.footerLogoContainer}>
          <Typography sx={styles.footerSubText}>{`Proposta gerada no dia ${moment().format('DD [de] MMMM [de] YYYY [às] HH[h]mm')}, válida por 30 dias`}</Typography>
          <KmMidiaLogo style={styles.footerLogo} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  tableVeiculacao: {
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  mockupLabel: {
    fontWeight: '700',
    fontSize: '0.7rem',
    textAlign: 'center'
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '0.7rem',
  },
  table: {
    pageBreakBefore: 'always',
    breakInside: 'avoid',
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  tableResumoVeiculação: {
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: '50px',
  },
  tablecellResumoVeiculacao: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 15px',
    fontSize: '0.7rem',
    lineHeight: '0.8rem',
    backgroundColor: '#fff',
    borderBottom: 'none',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '770px',
  },
  footerSpace: {
    whiteSpace: 'nowrap',
    borderTop: '1px solid #E0E0E0',
  },
  footerLogo: {
    height: '25px',
    width: '110px',
    margin: '6px 20px 0px -5px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
  },
  footerSubText: {
    fontSize: 11,
    paddingRight: '10px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    marginTop: '30px',
    paddingBottom: '100px',
    zIndex: 999,
  },
  observacoesText: {
    whiteSpace: 'normal',
  },
  tableRowPrincipal: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingBottom: '5px',
    },
  },
};

export default ImpressaoOrcamento;
