import {LIGHT_COLORS as colors} from './colors/mui-colors';
import {typography} from './mui-typography';

export const buttonsStyles = {
  primary: {
    background: colors.primary,
    textTransform: 'capitalize',
    color: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.primaryHover,
      boxShadow: '3px 4px 13px rgba(0, 131, 230, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  primaryEmpty: {
    background: `#fff `,
    color: `${colors.primary} !important`,
    border: `1px solid ${colors.primary}`,
    borderRadius: '4px',
    textTransform: 'capitalize',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.primaryEmptyHover,
      boxShadow: '4.79602px 6.3947px 20.7828px rgba(0, 131, 230, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
      background: `${colors.primary} !important`,
      color: '#fff !important',
    },
  },
  blueGray: {
    background: colors.blueGray,
    color: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    textTransform: 'capitalize',
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.blueGrayHover,
      boxShadow: '3px 4px 13px rgba(106, 149, 232, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  green: {
    background: colors.green,
    color: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    height: '40px',
    textTransform: 'capitalize',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.greenHover,
      boxShadow: '3px 4px 13px rgba(37, 225, 138, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  greenCancel: {
    background: '#fff',
    textTransform: 'capitalize',
    color: colors.green,
    border: `1px solid ${colors.green}`,
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '& .MuiSvgIcon-root': {
      color: colors.cancel,
    },
    '&:hover': {
      color: '#fff !important',
      background: colors.cancel,
      border: `1px solid ${colors.cancel}`,
      boxShadow: '3px 4px 13px rgba(255, 82, 82, 0.5)',
      '& .MuiSvgIcon-root': {
        color: '#fff',
      },
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  cancel: {
    background: colors.cancel,
    color: '#fff',
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    textTransform: 'capitalize',
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.cancelHover,
      boxShadow: '3px 4px 13px rgba(255, 82, 82, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  cancelEmpty: {
    background: '#fff',
    color: colors.cancel,
    border: `1px solid ${colors.cancel}`,
    textTransform: 'capitalize',
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.cancelEmpty,
      boxShadow: '3px 4px 13px rgba(255, 82, 82, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  yellow: {
    background: colors.yellow,
    color: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    textTransform: 'capitalize',
    borderRadius: '4px',
    height: '40px',
    fontFamily: typography.fontFamily.patternBold,
    '&:hover': {
      background: colors.yellowHover,
      boxShadow: '3px 4px 13px rgba(255, 186, 90, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
};
