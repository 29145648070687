export const LOGIN_ROUTE = '/login';
export const ESQUECI_SENHA_ROUTE = '/esqueceu-sua-senha';
export const DASHBOARD_ROUTE = '/home';
export const CAMPANHAS_ROUTE = '/campanhas';
export const PERFIL_ROUTE = '/perfil';
export const EXTRATO_RECEBER_ROUTE = '/extrato-receber';
export const EXTRATO_PAGAR_ROUTE = '/extrato-pagar';
export const ANUNCIANTES_ROUTE = '/anunciantes';
export const PROPOSTAS_ROUTE = '/propostas';
export const EXECUTIVOS_ROUTE = '/executivos';
export const PAINEL_ANUNCIANTE_ROUTE = '/painel-anunciante';
export const TABELA_PRECO_ROUTE = '/tabela-preco';
export const MATERIAIS_VENDA_ROUTE = '/materiais-venda';
export const MODELO_ANUNCIOS_ROUTE = '/modelo-anuncios';

export const routeLabel = {
  [LOGIN_ROUTE]: 'Login',
  [ESQUECI_SENHA_ROUTE]: 'Esqueceu sua senha',
  [DASHBOARD_ROUTE]: 'Home',
  [CAMPANHAS_ROUTE]: 'Campanhas',
  [PERFIL_ROUTE]: 'Perfil',
  [EXTRATO_RECEBER_ROUTE]: 'Financeiro',
  [EXTRATO_PAGAR_ROUTE]: 'Financeiro',
  [ANUNCIANTES_ROUTE]: 'Anunciantes',
  [PROPOSTAS_ROUTE]: 'Funil de vendas',
  [EXECUTIVOS_ROUTE]: 'Executivos',
  [PAINEL_ANUNCIANTE_ROUTE]: 'Painel do anunciante para demonstração',
  [TABELA_PRECO_ROUTE]: 'Tabela de preços',
  [MATERIAIS_VENDA_ROUTE]: 'Materiais de venda',
  [MODELO_ANUNCIOS_ROUTE]: 'Custos modelos de anúncios',
};
