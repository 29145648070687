import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Paper, Tabs, Tab, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Campaign, DriveEta, ImageSearch} from '@mui/icons-material';
import {LoadingPage} from '../../components/loading-page';
import {FIND_BY_ID_CAMPANHA} from '../../graphql/queries';
import FormularioCampanha from './formulario';
import TabPanel from '../../components/tab-painel';
import Cidades from './cidades';
import ImagensCampanha from './imagens-campanha';

const EditarCampanha = () => {
  const classes = useStyles();
  const {campanhaId} = useParams();

  const [tabIndex, setTabIndex] = useState(0);

  const campanhaQuery = useQuery(FIND_BY_ID_CAMPANHA, {
    variables: {
      id: campanhaId,
    },
  });

  const {data = {}} = campanhaQuery;
  const {findByIdCampanha: campanha} = data;

  const refetch = () => {
    campanhaQuery.refetch();
  };

  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  if (campanhaQuery.loading || campanhaQuery.error)
    return (<LoadingPage loading={campanhaQuery.loading} error={campanhaQuery.error} />);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          variant="standard"
          aria-label="nav tabs campanhas"
          className="tabs"
        >
          <Tab
            iconPosition="start"
            className="tab-button"
            icon={<Campaign />}
            label={<p className="tab-label">Campanha</p>}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            icon={<DriveEta />}
            label={<p className="tab-label">Cidades</p>}
            {...a11yProps(1)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            icon={<ImageSearch />}
            label={<p className="tab-label">Check-in / Check-out</p>}
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel className={classes.tabPainel} value={tabIndex} index={0}>
          <FormularioCampanha campanha={campanha} refetch={refetch} />
        </TabPanel>
        <TabPanel className={classes.tabPainel} value={tabIndex} index={1}>
          <Cidades
            campanha={campanha}
            refetch={campanhaQuery.loading}
          />
        </TabPanel>
        <TabPanel className={classes.tabPainel} value={tabIndex} index={2}>
          <ImagensCampanha campanha={campanha} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  tabPainel: {
    minHeight: 'calc(100vh - 185px)',
    margin: '10px 0',
    overflowY: 'auto',

    ...theme.styledScroll,
  },
}));

export default EditarCampanha;
