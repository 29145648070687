import {useEffect, useRef, useState} from 'react';
import {Loader} from '@googlemaps/js-api-loader';
import config from '../config/config';

const apiKey = config.GOOGLE_MAPS_API_KEY;
const eventsMapping = {
  onCenterChanged: ['center_changed', map => map.getCenter()],
  onBoundsChangerd: ['bounds_changed', map => map.getBounds()],
};

//  options: zoomControl, fullscreenControl, mapTypeControl, rotateControl, scaleControl
export default function useGoogleMap({
  zoom,
  center,
  mapTypeId = 'roadmap',
  events = {},
  options = {}
}) {
  const [mapState, setMapState] = useState({loading: true});
  const mapRef = useRef();

  const loader = new Loader({
    apiKey,
    version: "weekly",
    libraries: ["visualization"]
  });

  useEffect(() => {
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(mapRef.current, {
          zoom,
          center,
          mapTypeId,
          streetViewControl: false,
          ...options
        });
        Object.keys(events).forEach(eventName =>
          map.addListener(eventsMapping[eventName][0], () =>
            events[eventName](eventsMapping[eventName][1](map)),
          ),
        );

        setMapState({maps: google.maps, map, loading: false});
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {mapRef, ...mapState};
}
