import React from 'react';
import {FIND_ALL_SEGMENTOS as query} from '../../graphql/queries';
import {SelectBase} from '../../components/select/mui-base';

const getVariables = ({pageNumber, search = '', pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'descricao', sortDir: 'ASC'},
    searchDTO: {search},
  };
};

export const SelectSegmentos = (props) => {
  return (
    <SelectBase
      label="Segmento"
      responseObjectName="segmentos"
      labelProp="descricao"
      query={query}
      getVariables={getVariables}
      {...props}
    />
  );
};
