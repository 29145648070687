import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
  Box,
  TableContainer,
  Button
} from '@mui/material';
import {Add, OpenInNew, Visibility} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {Field, Formik} from 'formik';
import {FIND_ANUNCIANTE_BY_COMERCIAL} from '../../graphql/queries';
import string from '../../utils/string';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectEstados} from '../../containers/selects/estados';
import {SelectCidades} from '../../containers/selects/cidades';
import TableCellOrdered from '../../components/table-cell-ordered';
import {persistAnunciantes, persistFiltrosAnunciante} from '../../redux/Anunciante/slice';
import {SelectSegmentos} from '../../containers/selects/segmento';
import Data from '../../utils/data';
import usePainelService from '../../services/PainelService';

const columns = [
  {id: 'nomeFantasia', label: 'Nome fantasia'},
  {
    id: 'segmentoAtuacao', label: 'Segmento', campo: 'segmentoAtuacao.descricao',
    format: segmento => segmento?.descricao,
    style: {width: '350px'}
  },
  {
    id: 'dataCadastro', label: 'Data cadastro',
    format: data => Data.dataFormat(data),
    style: {width: '150px'}
  }
];

const ConsultaAnunciante = ({pageSize = 20}) => {
  const {anunciantes, totalAnunciantes, refetchAnunciantes, filtrosAnunciante} =
    useSelector((store) => store.Anunciante);
  const comercialLogado = useSelector((store) => store.Auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const painelService = usePainelService();

  const [page, setPage] = useState(filtrosAnunciante.page);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState(filtrosAnunciante.orderBy);
  const [filtros, setFiltros] = useState(filtrosAnunciante.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [loadingAccessAnunciante, setLoadingAccessAnunciante] = useState(null);


  const anuncianteQuery = useQuery(FIND_ANUNCIANTE_BY_COMERCIAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        cpfCnpj: searchDTO.cpfCnpj,
        cidadeId: searchDTO.cidade?.id,
        estadoId: searchDTO.estado?.id,
        segmentoAtuacao: searchDTO.segmento?.id,
      },
    },
  });

  useEffect(() => {
    if (
      !anuncianteQuery.loading &&
      !anuncianteQuery.error &&
      anuncianteQuery.data
    ) {
      dispatch(persistAnunciantes(anuncianteQuery.data.anunciantes));
      dispatch(persistFiltrosAnunciante({filtros, page, orderBy}));
    }
    if (refetchAnunciantes) {
      anuncianteQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anuncianteQuery, dispatch, refetchAnunciantes]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      {['LICENCA_COMERCIAL', 'COMERCIAL'].includes(comercialLogado?.role) && (
        <Button
          id="button-green"
          startIcon={<Add />}
          onClick={() => navigate(`criar`)}
          toolbar="true"
        >
          Cadastrar novo
        </Button>
      )}
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <InputV2
                  label="Nome fantasia / Razão social"
                  value={filtros.search}
                  onChange={(e) => setFiltros({...filtros, search: e.target.value})}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectSegmentos}
                  name="segmento"
                  className="select"
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      segmento: e || null,
                    })
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <InputV2
                  label="CNPJ"
                  value={filtros.cpfCnpj}
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      cpfCnpj: string.removeSpecialChars(e.target.value),
                    })
                  }
                  className="input-filtro"
                  mask="cnpj"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  className="select"
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      estado: e || null,
                      cidade: null,
                    })
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  className="select"
                  onChange={(e) =>
                    setFiltros({...filtros, cidade: e || null})
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  className="button"
                  value="Filtrar"
                  loading={anuncianteQuery.loading}
                  onClick={refetch}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        style={column.style}
                      />
                    ))}
                    <TableCell
                      key="acoes"
                      align="center"
                      sx={styles.columnHeadAcoes}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anunciantes.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                          >
                            <RoundTooltipButton
                              title={'Acessar painel'}
                              onClick={() => {
                                setLoadingAccessAnunciante(row);
                                painelService.acessarPainelAnunciante(row.id, setLoadingAccessAnunciante);
                              }}
                              loading={loadingAccessAnunciante?.id === row.id}
                              icon={<OpenInNew />}
                              id="icon-gray"
                            />
                            <RoundTooltipButton
                              title={'Visualizar'}
                              onClick={() => navigate(`${row.id}/editar`)}
                              icon={<Visibility />}
                              id="icon-primary"
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalAnunciantes}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior'
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página'
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid >
    </Grid >
  );
};

const styles = {
  columnHeadAcoes: {
    cursor: 'default',
    width: '100px',
    fontWeight: 'bold',
  },
};

export default ConsultaAnunciante;
