import {
  AccountBalanceWalletRounded,
  DescriptionRounded,
  FlagRounded,
  HomeRounded,
} from '@mui/icons-material';
import React from 'react';
import {
  ANUNCIANTES_ROUTE,
  CAMPANHAS_ROUTE,
  DASHBOARD_ROUTE,
  EXECUTIVOS_ROUTE,
  EXTRATO_PAGAR_ROUTE,
  EXTRATO_RECEBER_ROUTE,
  MATERIAIS_VENDA_ROUTE,
  MODELO_ANUNCIOS_ROUTE,
  PAINEL_ANUNCIANTE_ROUTE,
  PROPOSTAS_ROUTE,
  TABELA_PRECO_ROUTE,
} from '../constants/routes';

const comercial = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Vendas',
    icon: <DescriptionRounded />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
      {
        title: 'Materiais de vendas',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Painéis demonstração',
        route: PAINEL_ANUNCIANTE_ROUTE,
      },
      {
        title: 'Tabela de preços',
        route: TABELA_PRECO_ROUTE,
      },
    ],
  },
  {
    title: 'Anunciantes',
    route: ANUNCIANTES_ROUTE,
    icon: <FlagRounded />,
  },
];

const administrador = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Vendas',
    icon: <DescriptionRounded />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
      {
        title: 'Materiais de vendas',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Painéis demonstração',
        route: PAINEL_ANUNCIANTE_ROUTE,
      },
      {
        title: 'Tabela de preços',
        route: TABELA_PRECO_ROUTE,
      },
      {
        title: 'Custos modelos de anúncios',
        route: MODELO_ANUNCIOS_ROUTE,
      },
    ],
  },
  {
    title: 'Cadastros',
    icon: <FlagRounded />,
    options: [
      {
        title: 'Anunciantes',
        route: ANUNCIANTES_ROUTE,
      },
      {
        title: 'Executivos',
        route: EXECUTIVOS_ROUTE,
      },
    ],
  },
  {
    title: 'Financeiro',
    icon: <AccountBalanceWalletRounded />,
    options: [
      {
        title: 'Contas a pagar',
        route: EXTRATO_PAGAR_ROUTE,
      },
      {
        title: 'Contas a receber',
        route: EXTRATO_RECEBER_ROUTE,
      },
    ],
  },
];

const SidebarRoutes = {
  getSidebarRoutes: (role) => {
    switch (role) {
      case 'COMERCIAL':
      case 'LICENCA_COMERCIAL':
        return comercial;
      case 'LICENCA_ADMINISTRADOR':
        return administrador;
      default:
        return [];
    }
  },
};

export default SidebarRoutes;
