import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  executivos: [],
  filtrosExecutivos: {
    filtros: {
      ativo: {label: 'Ativo', value: true},
    },
    page: 0,
    orderBy: {campo: 'nomeRazao', asc: true},
  },
  hasMore: false,
  totalExecutivos: 0,
  refetchExecutivos: false
};

const acaoAtualizarExecutivos = state => {
  state.refetchExecutivos = true;
};

const acaoPersistExecutivos = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.executivos = content || [];
  state.hasMore = !last;
  state.totalExecutivos = totalElements || 0;
};

const acaoPersistFiltrosExecutivos = (state, action) => {
  state.filtrosExecutivos = action.payload;
};

const resumeSlice = createSlice({
  name: 'executivo',
  initialState: initialState,
  reducers: {
    persistExecutivos: acaoPersistExecutivos,
    atualizarExecutivos: acaoAtualizarExecutivos,
    persistFiltrosExecutivos: acaoPersistFiltrosExecutivos
  }
});

export const {actions, reducer} = resumeSlice;
export const {persistExecutivos, atualizarExecutivos, persistFiltrosExecutivos} = actions;
