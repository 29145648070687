import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_PROPOSTA_BY_ID} from '../../graphql/queries';
import FormularioProposta from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarProposta = () => {
  const {propostaId} = useParams();

  const propostaQuery = useQuery(FIND_PROPOSTA_BY_ID, {
    variables: {
      id: propostaId
    }
  });

  const {data = {}} = propostaQuery;
  const {proposta = {}} = data;

  const refetch = () => {
    propostaQuery.refetch();
  }

  if (propostaQuery.loading || propostaQuery.error)
    return (<LoadingPage loading={propostaQuery.loading} error={propostaQuery.error} />);

  return (
    <FormularioProposta proposta={proposta} refetch={refetch} />
  );
};

export default EditarProposta;
