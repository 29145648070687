import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {Grid, Modal, Typography, Box} from '@mui/material';
import {BAIXAR_MULTIPLAS_MOVIMENTACOES} from '../../../graphql/mutation';
import {ButtonComponent} from '../../../components/mui-button';
import {toastNotification} from '../../../components/toastify';
import DragNDrop from '../../../components/drag-n-drop';
import {uploadAmazonS3Arquivo} from '../../../services/UploadService';

const DarBaixaExtratosMultiplos = ({
  isOpen,
  onClose,
  onBaixa,
  selecionados = [],
}) => {
  const [file, setFile] = useState(undefined);
  const [baixaLoading, setBaixaLoading] = useState(false);

  const [baixaMovimentacaoFinanceira] = useMutation(BAIXAR_MULTIPLAS_MOVIMENTACOES);

  const handleClickDarBaixa = async () => {
    setBaixaLoading(true);

    let response = undefined;
    try {
      if (file) {
        response = await uploadAmazonS3Arquivo(file);
      }
    } catch (error) {
      console.log(error);
    }

    baixaMovimentacaoFinanceira({
      variables: {
        ids: selecionados,
        documento: response?.id ? {id: response.id} : undefined
      },
    })
      .then(() => {
        setFile(undefined);
        onBaixa && onBaixa();
        onClose();
        toastNotification({
          message: 'As movimentações foram baixadas!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setBaixaLoading(false));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
    >
      <Box sx={styles.content}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={styles.header}>
              <Typography sx={styles.headerText}>
                Baixar movimentações
              </Typography>
              <Close sx={styles.closeIcon} onClick={onClose} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <Typography sx={styles.selecionadosText}>
                    {selecionados.length} movimentações selecionadas
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <DragNDrop
                    label="Documento"
                    file={file}
                    onChange={setFile}
                    fileName={file?.name}
                    onDelete={() => {
                      setFile(undefined);
                    }}
                    style={{marginBottom: '15px'}}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.buttonsContainer}>
              <ButtonComponent
                id="button-cancel-empty"
                value={'Cancelar'}
                onClick={onClose}
                disabled={baixaLoading}
                sx={styles.button}
              />
              <ButtonComponent
                value={'Dar Baixa'}
                loading={baixaLoading}
                onClick={handleClickDarBaixa}
                disabled={baixaLoading}
                sx={styles.button}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const styles = {
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0',
  },
  closeIcon: {
    color: '#0083e6',
    fontSize: '30px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '24px',
  },
  inputWrapper: {
    padding: '0 5px',

    '& .react-datepicker-wrapper': {
      width: '100% !important',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 5px',
  },
  button: {
    width: '200px',
  },
  selecionadosText: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
};

export default DarBaixaExtratosMultiplos;
