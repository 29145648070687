import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import Map from '../../google/Map';
import Marker from '../../google/Marker';
import TransitLayer from '../../google/TransitLayer';
import InfoWindow from '../../google/InfoWindow';
import {centroBrasil} from '../../utils/get-places';

const MapaCidades = ({localizacoes = [], ...props}) => {
  const {endereco} = useSelector(store => store.Auth);

  const [centerMapLatLng, setCenterMapLatLng] = useState({
    lat: centroBrasil.lat,
    lng: centroBrasil.lng,
  });
  const [transitLayerEnabled] = useState(false);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    dados: null,
    marker: null,
    map: null,
  });

  useEffect(() => {
    if (endereco) {
      setCenterMapLatLng({
        lat: endereco?.cidade?.latitude,
        lng: endereco?.cidade?.longitude
      });
    }
  }, [endereco]);

  const dadosInfoWindow =
    infoWindowSettings && infoWindowSettings.dados
      ? infoWindowSettings.dados
      : null;

  useEffect(() => {
    if (localizacoes) {
      const centerMap = () => {
        const localizacao = localizacoes.find(l => !!l);

        if (localizacao && localizacao.latitude && localizacao.longitude) {
          return {lat: localizacao.latitude, lng: localizacao.longitude};
        } else if (localizacao && localizacao.lat && localizacao.lng) {
          return {lat: localizacao.lat, lng: localizacao.lng};
        }
        return centerMapLatLng;
      };
      const newCenterMapLatLng = centerMap();
      if (
        centerMapLatLng.lat !== newCenterMapLatLng.lat &&
        centerMapLatLng.lng !== newCenterMapLatLng.lng
      ) {
        setCenterMapLatLng(centerMap());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizacoes]);

  return (
    <Grid {...props}>
      <Map
        center={centerMapLatLng}
        locations={localizacoes}
        withBounds={localizacoes.length > 1 ? true : false}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false
        }}
      >
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div className="iw-condutor-container">
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <label>Cidade</label>
                <p>
                  {dadosInfoWindow
                    ? `${dadosInfoWindow.nome} / ${dadosInfoWindow.uf}`
                    : ''}
                </p>
              </div>
              <div className="iw-condutor-info-posicao">
                <label>Qtd. Campanhas</label>
                <p>{dadosInfoWindow && dadosInfoWindow.campanhas}</p>
              </div>
            </div>
          </div>
        </InfoWindow>
        {localizacoes &&
          localizacoes.map((localizacao, index) => {
            return (
              <Marker
                key={index}
                active={false}
                position={{
                  lat: localizacao.latitude,
                  lng: localizacao.longitude,
                }}
                events={{
                  onMouseOver: (map, marker) => {
                    setInfoWindowSettings({
                      visible: true,
                      marker,
                      map,
                      dados: {
                        ...localizacao,
                      },
                    });
                  },
                  onMouseOut: () => {
                    setInfoWindowSettings({
                      visible: false,
                      dados: null,
                      marker: null,
                      map: null,
                    });
                  },
                }}
              />
            );
          })}
      </Map>
    </Grid>
  );
};

export default MapaCidades;
