import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMutation, useLazyQuery} from '@apollo/client';
import {Grid, Typography, Modal, Box, Slide} from '@mui/material';
import {ArrowForward, Close, GetApp, Publish} from '@mui/icons-material';
import {FIND_BY_ID_MOVIMENTACAO_FINANCEIRA} from '../../graphql/queries';
import {ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA} from '../../graphql/mutation';
import {RoundTooltipButton} from '../../components/mui-button';
import {atualizarExtratoRepasse} from '../../redux/ExtratoRepasseComercial/slice';
import DarBaixaExtrato from './modais/dar-baixa';
import {confirmAlertCustom} from '../../components/confirm-alert/confirm-alert';
import {toastNotification} from '../../components/toastify';
import DetalhesMotorista from './detalhes/detalhes-motorista';
import DetalhesInstalador from './detalhes/detalhes-instalador';
import ReprovarExtrato from './modais/reprovar-movimentacao';
import styles from './stylesModal';
import DetalhesComercial from './detalhes/detalhes-comercial';
import DetalhesAnunciante from './detalhes/detalhes-anunciante';
import DetalhesRoyalties from './detalhes/detalhes-royalties';

const DetalhesExtrato = ({
  openModal,
  onClose,
  movimentacaoFinanceira = {},
  ...props
}) => {
  const [estornarBaixaLoading, setEstornarBaixaLoading] = useState(false);
  const [openBaixa, setOpenBaixa] = useState(false);
  const [openReprovar, setOpenReprovar] = useState(false);
  const [dados, setDados] = useState({});

  const dispatch = useDispatch();

  const [estornarBaixaMovimentacaoFinanceira] = useMutation(
    ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA,
  );

  const [loadMovimentacao, movimentacaoQuery] = useLazyQuery(
    FIND_BY_ID_MOVIMENTACAO_FINANCEIRA,
  );

  useEffect(() => {
    if (!movimentacaoFinanceira?.id) return;

    loadMovimentacao({
      variables: {
        id: movimentacaoFinanceira.id,
      },
    });
  }, [movimentacaoFinanceira, loadMovimentacao]);

  useEffect(() => {
    if (movimentacaoQuery.loading) return;

    const movimentacao =
      movimentacaoQuery.data?.findByIdMovimentacaoFinanceira || {};

    setDados(movimentacao);
  }, [movimentacaoQuery]);

  const refetch = () => {
    dispatch(atualizarExtratoRepasse());
    movimentacaoQuery.refetch();
  };

  const handleEstornarBaixa = (lancamento) => {
    setEstornarBaixaLoading(lancamento.id);

    estornarBaixaMovimentacaoFinanceira({
      variables: {
        id: lancamento.id,
      },
    })
      .then(() => {
        refetch();
        toastNotification({message: 'A baixa foi estornada!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setEstornarBaixaLoading(null));
  };

  const handleClickEstornarBaixa = (lancamento) => {
    confirmAlertCustom({
      message: 'Deseja estornar a baixa?',
      onConfirm: () => handleEstornarBaixa(lancamento),
      onCancel: () => null,
    });
  };

  const renderDetalhesPorTipo = () => {
    switch (dados.movimentacaoOrigem) {
      case 'MOTORISTA':
        return <DetalhesMotorista dados={dados} />;
      case 'COMERCIAL':
        return <DetalhesComercial dados={dados} />;
      case 'INSTALADOR':
        return <DetalhesInstalador dados={dados} />;
      case 'ANUNCIANTE':
        return <DetalhesAnunciante dados={dados} />;
      case 'ROYALTIES':
        return <DetalhesRoyalties dados={dados} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      componentsProps={{
        backdrop: {
          sx: {
            background: 'rgb(0, 0, 0, 0.0)',
          },
        },
      }}
      onClose={onClose}
      open={openModal}
    >
      <Slide direction="left" in={openModal} appear>
        <Box sx={styles.modal}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={styles.header}>
                <Box sx={styles.headerText}>
                  <ArrowForward sx={styles.arrowBack} onClick={onClose} />
                  <Typography sx={styles.headerTitle}>
                    Detalhes da movimentação
                  </Typography>
                </Box>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {['FINALIZADO', 'REPROVADO'].includes(dados.situacao) && (
                        <RoundTooltipButton
                          id="icon-cancel"
                          title={'Estornar'}
                          loading={estornarBaixaLoading}
                          onClick={() => handleClickEstornarBaixa(dados)}
                          icon={<Publish />}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {['PENDENTE', 'APROVADO'].includes(dados.situacao) && (
                        <RoundTooltipButton
                          id="icon-green"
                          title={'Dar baixa'}
                          onClick={() => setOpenBaixa(true)}
                          icon={<GetApp />}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {['PENDENTE', 'APROVADO'].includes(dados.situacao) && (
                        <RoundTooltipButton
                          id="icon-cancel"
                          title={'Reprovar'}
                          onClick={() => setOpenReprovar(true)}
                          icon={<Close />}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {renderDetalhesPorTipo()}
            </Grid>
            <DarBaixaExtrato
              isOpen={openBaixa}
              onClose={() => setOpenBaixa(false)}
              onBaixa={refetch}
              movimentacaoFinanceira={dados}
            />
            <ReprovarExtrato
              isOpen={openReprovar}
              onClose={() => setOpenReprovar(false)}
              onBaixa={refetch}
              movimentacaoFinanceira={dados}
            />
          </Grid>
        </Box>
      </Slide>
    </Modal>
  );
};

export default DetalhesExtrato;
