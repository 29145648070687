import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import pt_BR from 'date-fns/locale/pt-BR';
import styled from '@emotion/styled';
import {Cancel, Today} from '@mui/icons-material';
import {Box, Icon} from '@mui/material';
import InputV2 from './input-v2/mui-input-v2';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', pt_BR);

const InputDate = ({
  inputProps,
  iconWidth = 24,
  dateFormat = 'dd/MM/yyyy',
  label,
  form,
  selected,
  value,
  field,
  isClearable,
  onChange,
  ...props
}) => {
  const renderIcon = () => {
    if (selected && isClearable)
      return (
        <CancelIconStyled >
          <Cancel onClick={() => onChange(undefined)} />
        </CancelIconStyled>
      );

    return (
      <DateIconStyled fontSize="medium">
        <Today fontSize="medium" />
      </DateIconStyled>
    );
  };

  return (
    <Box sx={styles.container}>
      <DatePicker
        locale="pt-BR"
        dateFormat={dateFormat}
        placeholderText={props.placeholder}
        selected={selected || field?.value}
        onChange={e => {
          !!form?.setFieldValue && form.setFieldValue(field.name, e);
          !!onChange && onChange(e);
        }}
        {...props}
        customInput={
          <Input renderRight={renderIcon} label={label} {...inputProps} />
        }
      />
    </Box>
  );
};

const Input = React.forwardRef((props, ref) => (
  <InputV2 innerRef={ref} {...props} />
));

const styles = {
  container: {
    '& .react-datepicker-wrapper': {
      width: '100%'
    }
  }
};

const CancelIconStyled = styled(Icon)`
  color: #8995b1;
  cursor: pointer;
  margin: 5px;
  position: absolute;
  height: 30px;
  width: 30px !important;
  top: 4px;
  right: -10px;
`;

const DateIconStyled = styled(Icon)`
  color: #8995b1;
  fill: #8995b1;
  position: absolute;
  height: 30px;
  width: 30px !important;
  top: 9px !important;
  right: 0px;
  margin-right: -9px;
  background-color: white;
`;

export default InputDate;
