import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Grid, Modal, Typography} from '@mui/material';
import {Save, Close} from '@mui/icons-material';
import {UPDATE_REPROVADO_MOTIVO} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import Textarea from '../../../components/textarea/mui-textarea';
import {ButtonComponent} from '../../../components/mui-button';

const MotivoReprovacaoProposta = ({proposta, isOpen, onSave, onClose}) => {
  const [motivo, setMotivo] = useState('');

  const [updateMotivo, {loading}] = useMutation(UPDATE_REPROVADO_MOTIVO);

  const handleSubmitMotivo = () => {
    if (motivo.length < 10) {
      toastNotification({message: 'Informe o motivo que a proposta foi reprovada (mínimo de 10 caracteres)', type: 'warning'});
      return;
    }

    updateMotivo({
      variables: {
        proposta: {
          id: proposta.id
        },
        motivo
      },
    })
      .then(() => {
        setMotivo('');
        onSave && onSave();
      })
      .catch(error => toastNotification({message: error.message, type: 'error'}));
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      onClose={onClose}
      sx={styles.modal}
    >
      <Grid sx={styles.content}>
        <Grid sx={styles.header}>
          <Typography sx={styles.headerText}>Motivo da proposta ser reprovada</Typography>
        </Grid>
        <Grid>
          <Textarea
            value={motivo}
            sx={styles.textArea}
            placeholder="Informe o motivo"
            onChange={({target}) => setMotivo(target.value)}
          />
        </Grid>
        <Grid sx={styles.buttons}>
          <ButtonComponent
            id="button-cancel-empty"
            value={'Cancelar'}
            sx={styles.buttonCancel}
            disabled={loading}
            icon={<Close />}
            onClick={onClose}
          />
          <ButtonComponent
            id="button"
            sx={styles.button}
            type="button"
            value="Salvar"
            disabled={loading}
            loading={loading}
            icon={<Save />}
            onClick={handleSubmitMotivo}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

const styles = {
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0'
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '1000px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '24px'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    width: '271px',
    height: '48px',
    backgroundColor: '#0091FF'
  },
  buttonCancel: {
    width: '271px',
    height: '48px',
    fontWeight: 'bold',
  },
  buttonIconRight: {
    fontSize: 20,
    margin: '0 -20px 0 10px'
  },
  textArea: {
    '& textarea': {
      borderRadius: '8px !important',
      color: '#262626 !important',
      height: '130px !important',
      overflowY: 'hidden !important',
      fontFamily: 'AktivGrotesk',
    }
  },
};

export default MotivoReprovacaoProposta;