import React from 'react';
import {Typography} from '@mui/material';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastNotification = ({titulo, message, ...props}) => {
  return toast((
    <div>
      <Typography>
        {titulo}
        {titulo && (<br />)}
        {message?.replace('GraphQL error:', '')}
      </Typography>
    </div>
  ), {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    ...props
  });
}