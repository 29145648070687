import {combineReducers} from 'redux';
import {ReducerAgencia as Agencia} from './Agencia';
import {ReducerAnunciante as Anunciante} from './Anunciante';
import {ReducerApollo as Apollo} from './Apollo';
import {AuthReducer as Auth} from './Auth';
import {ReducerCampanha as Campanha} from './Campanha';
import {ReducerExecutivo as Executivo} from './Executivo';
import {ReducerExtratoRepasseComercial as ExtratoRepasseComercial} from './ExtratoRepasseComercial';
import {ReducerHome as Home} from './Home';
import {ReducerMateriaisVenda as MateriaisVenda} from './MateriaisVenda';
import {ReducerModeloAnuncio as ModeloAnuncio} from './ModeloAnuncio';
import {ReducerMotoristaCampanha as MotoristaCampanha} from './MotoristaCampanha';
import {ReducerProposta as Proposta} from './Proposta';
import {ResumeReducer as Resume} from './Resume';
import {ReducerSidebar as Sidebar} from './Sidebar';

const appReducer = combineReducers({
  Auth,
  Resume,
  Apollo,
  Campanha,
  Anunciante,
  Proposta,
  ExtratoRepasseComercial,
  MotoristaCampanha,
  Agencia,
  Executivo,
  MateriaisVenda,
  Sidebar,
  Home,
  ModeloAnuncio,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
