import React from 'react';
import {Grid, Link} from '@mui/material';

const Footer = () => (
  <Grid container sx={styles.container}>
    <Link href="https://wa.me/5544999150120" target="_blank" sx={styles.link}>
      Fale com o Suporte KM Mídia
    </Link>
  </Grid>
);

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: '20px',
    
    '@media (min-width: 1024px)': {
      paddingBottom: '50px',
      justifyContent: 'flex-start',
      paddingLeft: '130px',
      paddingRight: '100px',
    },
  },
  link: {
    color: '#0083e6',
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

export default Footer;
