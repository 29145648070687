import React from 'react';
import {useSelector} from 'react-redux';
import config from '../config/config';
import {Box} from '@mui/material';

const Image = ({id, alt, ...props}) => {
  const {token} = useSelector(state => state.Auth);

  const src = id ? `${config.AMAZON_S3_BASE_URL}${id}?access_token=${token}` : '';
  return <Box component="img" {...props} src={src} alt={alt} />;
};

export default Image;
