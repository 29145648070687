import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_ANUNCIANTE} from '../../graphql/queries';
import FormularioAnunciante from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarAnunciante = () => {
  const {anuncianteId} = useParams();

  const anuncianteQuery = useQuery(FIND_BY_ID_ANUNCIANTE, {
    variables: {
      id: anuncianteId
    }
  });

  const {data = {}} = anuncianteQuery;
  const {anunciante = {}} = data;

  const refetch = () => {
    anuncianteQuery.refetch();
  }

  if (anuncianteQuery.loading || anuncianteQuery.error)
    return (<LoadingPage loading={anuncianteQuery.loading} error={anuncianteQuery.error} />);

  return (
    <FormularioAnunciante anunciante={anunciante} refetch={refetch} />
  );
};

export default EditarAnunciante;
