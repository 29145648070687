import config from '../config/config';

export const getImageAuth = idFoto => {
  const Auth = JSON.parse(window.localStorage.getItem('auth') || '{}');

  if (Auth.access_token) {
    return `${config.AMAZON_S3_BASE_URL}${idFoto}?access_token=${Auth.access_token}`;
  } else {
    return '';
  }
};
