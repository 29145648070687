import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Grid, Typography} from '@mui/material';
import {ArrowDropDown, Close, Search} from '@mui/icons-material';
import {ButtonComponent} from '../../components/mui-button';

const FiltroMotoristas = ({handleFiltrar}) => {
  const classes = useStyles();

  const [showFiltros, setShowFiltros] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [search, setSearch] = useState('');

  const changeShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  const onCancel = () => {
    limparFiltros();
    setShowFiltros(false);
  };

  const onFiltrar = () => {
    const keysFiltered = Object.keys(filtros).filter(key => key !== 'nomeMotorista' && filtros[key]);
    const search = keysFiltered?.length > 0 ? keysFiltered
      .map(key => `${key}=${filtros[key]}`)
      .reduce((acc, valor) => `${acc} | ${valor}`) : '';

    handleFiltrar(filtros);
    setSearch(search);
    setShowFiltros(false);
  };

  const limparFiltros = () => {
    setFiltros(undefined);
    setSearch('');
    handleFiltrar({});
  };

  const _handleKeyUp = ({key}) => {
    if (key === 'Enter') {
      onFiltrar();
    }
  }

  return (
    <Grid className={classes.filtroInputContainer} id="filtroMotoristaSearch" style={showFiltros ? {backgroundColor: '#fff', borderRadius: '6px 6px 0 0', boxShadow: '0px 1px 2px rgb(0 0 0 / 10%), 0px 8px 16px rgb(0 0 0 / 20%)'} : null}>
      <Search className={classes.filtroSearchIcon} onClick={onFiltrar} />
      <input
        className={classes.filtroInput}
        value={filtros?.nomeMotorista || ''}
        placeholder={search}
        name="nome"
        onChange={({target}) => setFiltros(prev => ({...prev, nomeMotorista: target.value}))}
        onKeyUp={_handleKeyUp}
      />
      {(filtros?.nomeMotorista || search) && (
        <Close className={classes.filtroCloseIcon} onClick={limparFiltros} />
      )}
      <ArrowDropDown className={classes.filtroDropIcon} style={showFiltros ? {transform: 'rotate(180deg)'} : null} onClick={changeShowFiltros} />
      <Grid container className={classes.filtrosModal} style={showFiltros ? null : {display: 'none'}}>
        <Grid item xs={12} className={classes.filtrosModalHeader}>
          <Typography className={classes.filtrarLabel}>Filtrar</Typography>
          <Typography className={classes.redefinirLabel} onClick={limparFiltros}>Redefinir filtros</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.inputLabel}>Status do Anúncio</Typography>
            <select
              value={filtros?.anuncioSituacao || ''}
              className={classes.filtroModalInput}
              onChange={({target}) => setFiltros(prev => ({...prev, anuncioSituacao: target.value || null}))}
            >
              <option value="">Todas</option>
              <option value="ATIVO">Ativo</option>
              <option value="AGUARDANDO_REMOCAO">Aguardando remoção</option>
              <option value="FINALIZADO">Finalizado</option>
              <option value="SUSPENSO">Suspenso</option>
              <option value="AGUARDANDO_IMPRESSAO">Aguardando impressão</option>
              <option value="AGUARDANDO_INSTALACAO">Aguardando instalação</option>
              <option value="AGUARDANDO_AGENDAMENTO_INSTALACAO">Aguardando agendamento da instalação</option>
              <option value="AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO">Aguardando aceite do agendamento da instalação</option>
              <option value="AGUARDANDO_TROCA_MOTORISTA">Aguardando troca de motorista</option>
              <option value="AGUARDANDO_ACEITE_MOTORISTA">Aguardando aceite do motorista</option>
              <option value="AGUARDANDO_IMAGEM_INSTALACAO">Aguardando imagem de instalação</option>
            </select>
          </Grid>
          <Grid item xs={6} style={{paddingRight: 8}}>
            <Typography className={classes.inputLabel}>Marca</Typography>
            <input
              className={classes.filtroModalInput}
              value={filtros?.nomeMarcaVeiculo || ''}
              name="nomeMarcaVeiculo"
              onChange={({target}) => setFiltros(prev => ({...prev, nomeMarcaVeiculo: target.value}))}
            />
          </Grid>
          <Grid item xs={6} style={{paddingLeft: 8}}>
            <Typography className={classes.inputLabel}>Modelo</Typography>
            <input
              className={classes.filtroModalInput}
              value={filtros?.nomeVeiculo || ''}
              name="nomeVeiculo"
              onChange={({target}) => setFiltros(prev => ({...prev, nomeVeiculo: target.value}))}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <ButtonComponent className={classes.cancelButton} onClick={onCancel} value="Cancelar" />
          </Grid>
          <Grid item xs={6}>
            <ButtonComponent className={classes.filtrarButton} onClick={onFiltrar} value="Filtrar" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  filtroInputContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    height: 48,
    width: '40%',
    maxWidth: 500,
    borderRadius: '8px',
    background: '#F2F2F2',
  },
  filtroSearchIcon: {
    color: '#868686',
    width: 44,
    height: 44,
    padding: '10px',
    transition: 'background-color 800ms',
    borderRadius: '16px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#efecec'
    },
    '&:active': {
      backgroundColor: '#a0a0a0'
    }
  },
  filtroCloseIcon: {
    color: '#868686',
    width: 22,
    height: 22,
  },
  filtroDropIcon: {
    color: '#868686',
    width: 44,
    height: 44,
    padding: '10px',
    cursor: 'pointer',
    transition: 'transform 300ms',
  },
  filtroInput: {
    height: 48,
    width: '100%',
    color: '#868686',
    fontSize: '14px',
    background: 'none',
    border: 'none'
  },
  filtrosModal: {
    position: 'absolute',
    width: '100%',
    height: 300,
    background: '#fff',
    top: '50px',
    zIndex: 10,
    borderRadius: '0 0 6px 6px',
    padding: '24px',
    paddingBottom: 0,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
  filtrarLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#5F6368',
  },
  redefinirLabel: {
    fontSize: '14px',
    textDecorationLine: 'underline',
    color: '#404040',
    cursor: 'pointer'
  },
  inputLabel: {
    fontSize: '14px',
    color: '#868686'
  },
  filtroModalInput: {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #868686',
    color: '#333',
    fontSize: '16px',
    height: 36,
    paddingRight: '16px',
  },
  filtrosModalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '30px'
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: '#505050',
    fontWeight: '500'
  },
  filtrarButton: {
    backgroundColor: '#0083E6',
    color: '#fff',
    fontWeight: '500',
    borderRadius: '6px'
  }
}));

export default FiltroMotoristas;