import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Header from './components/header';
import Footer from './components/footer';
import {login} from '../../redux/Auth';
import {ESQUECI_SENHA_ROUTE} from '../../constants/routes';
import {ButtonComponent} from '../../components/mui-button';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {loading, loginError} = useSelector((state) => state.Auth);

  const [fields, setFields] = useState({
    username: '',
    password: '',
  });

  const submitLogin = (e) => {
    e.preventDefault();

    const {username, password} = fields;

    dispatch(
      login({
        username,
        password,
      }),
    );
  };

  return (
    <Grid sx={styles.container}>
      <Header hiddenBackButton />
      <Grid sx={styles.content}>
        <Box component="form" onSubmit={submitLogin}>
          <Typography sx={styles.pageTitle}>
            Faça vendas, gerencie clientes, campanhas e resultados
          </Typography>
          <Typography sx={styles.pageSubtitle}>
            Faça o login para acessar sua conta
          </Typography>
          <Grid container direction="column">
            <TextField
              label="E-mail"
              margin="normal"
              type="email"
              name="username"
              value={fields.username}
              autoComplete="email"
              onChange={(e) => {
                setFields({
                  ...fields,
                  username: e.target.value,
                });
              }}
            />
            <TextField
              label="Senha"
              margin="normal"
              type="password"
              name="password"
              autoComplete="current-password"
              value={fields.password}
              onChange={(e) => {
                setFields({
                  ...fields,
                  password: e.target.value,
                });
              }}
            />
            {loginError && (
              <Typography sx={styles.error}>
                Usuário ou Senha inválido
              </Typography>
            )}
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              margin={'30px 0'}>
              <FormControlLabel
                control={<Checkbox value="1" color="primary" />}
                label="Lembrar senha"
              />
              <Link
                onClick={() => navigate(ESQUECI_SENHA_ROUTE)}
                sx={styles.link}>
                Esqueceu sua senha?
              </Link>
            </Grid>
            <ButtonComponent
              id="button"
              type="submit"
              value={'Entrar'}
              loading={loading}
            />
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    paddingLeft: '130px',
    width: '545px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      width: '100%',
      paddingLeft: 0,
    },
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#262626',
    marginTop: 0,
    marginBottom: 2
  },
  pageSubtitle: {
    color: '#657496',
    fontSize: '16px',
  },
  link: {
    color: '#0083e6',
    fontSize: '14px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
  },
};

export default Login;
