import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
  Box,
  TableContainer
} from '@mui/material';
import {saveAs} from 'file-saver';
import {useQuery} from '@apollo/client';
import {GetApp} from '@mui/icons-material';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {FIND_ALL_ARQUIVOS} from '../../graphql/queries';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import TableCellOrdered from '../../components/table-cell-ordered';
import {
  persistMateriaisVenda,
  persistFiltrosMateriaisVenda,
} from '../../redux/MateriaisVenda/slice';
import File from '../../utils/file';
import Data from '../../utils/data';

const columns = [
  {id: 'titulo', label: 'Titulo'},
  {id: 'descricao', label: 'Descrição'},
  {
    id: 'dataAlteracao',
    label: 'Data',
    format: (arquivo) => Data.dataFormat(arquivo.dataAlteracao),
  },
];

const ConsultaMateriaisVenda = ({pageSize = 20}) => {
  const {midiaKit, totalMateriaisVenda, filtrosMateriaisVenda, refetchMateriaisVenda} =
    useSelector((store) => store.MateriaisVenda);

  const [page, setPage] = useState(filtrosMateriaisVenda.page);
  const [rowsPerPage] = useState(pageSize);
  const [filtros, setFiltros] = useState(filtrosMateriaisVenda.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosMateriaisVenda.orderBy);

  const dispatch = useDispatch();

  const arquivosQuery = useQuery(FIND_ALL_ARQUIVOS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search || '',
      },
    },
  });

  useEffect(() => {
    if (!arquivosQuery.loading && !arquivosQuery.error && arquivosQuery.data) {
      dispatch(persistMateriaisVenda(arquivosQuery.data.findAllArquivos));
      dispatch(persistFiltrosMateriaisVenda({filtros, page, orderBy}));
    }
    if (refetchMateriaisVenda) {
      arquivosQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arquivosQuery, dispatch, refetchMateriaisVenda]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  const onDownload = (arquivo) => {
    if (arquivo.urlArquivo) {
      window.open(arquivo.urlArquivo);
      return;
    }

    saveAs(
      File.getFileAuth(arquivo.arquivo.id, arquivo.arquivo.nome),
      arquivo.arquivo.nome,
    );
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <InputV2
                  label="Buscar"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  className="button"
                  value="Filtrar"
                  loading={arquivosQuery.loading}
                  onClick={() => refetch()}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell
                    key="acoes"
                    align="center"
                    sx={styles.columnHeadAcoes}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {midiaKit.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={styles.columnHeadAcoes}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        align="center"
                        sx={styles.actionButtonsContainer}>
                        <RoundTooltipButton
                          title={row?.arquivo?.nome || ''}
                          onClick={() => onDownload(row)}
                          sx={styles.button}
                          icon={<GetApp />}
                          id="icon-primary"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalMateriaisVenda}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'próxima página',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    flexGrow: 1,
    alignContent: 'flex-start',
    background: theme => theme.palette.paterns.white,
    overflow: 'auto'
  },
  columnHeadAcoes: {
    cursor: 'default',
    fontWeight: 'bold',
    width: '135px'
  }
};

export default ConsultaMateriaisVenda;
