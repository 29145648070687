import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Add,
  Check,
  Close,
  DeleteForever,
  Edit,
  FileCopyOutlined,
  Person,
  Report,
  Save,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {confirmAlertCustom} from '../../components/confirm-alert/confirm-alert';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import Textarea from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import {orcamentoSituacao} from '../../constants/enum-labels';
import CadastroOrcamentoModal from '../../containers/modais/cadastro-orcamento/cadastro-orcamento-modal';
import MotivoReprovacaoProposta from '../../containers/modais/cadastro-proposta/motivo-reprovacao-proposta';
import {SelectAnunciantes} from '../../containers/selects/anunciantes';
import {
  CRIAR_PROPOSTA,
  DELETE_PROPOSTA,
  DUPLICAR_ORCAMENTO_PROPOSTA,
  GERAR_USUARIO_SENHA,
} from '../../graphql/mutation';
import {FIND_ALL_ORCAMENTOS_PROPOSTA} from '../../graphql/queries';
import Data from '../../utils/data';
import Number from '../../utils/number';

const FormularioProposta = ({proposta, refetch}) => {
  const navigate = useNavigate();

  const [readOnly, setReadOnly] = useState(true);
  const [excluindo, setExcluindo] = useState(false);
  const [motivoOpen, setMotivoOpen] = useState(false);
  const [motivoInformado, setMotivoInformado] = useState(false);
  const [anunciante, setAnunciante] = useState(proposta.anunciante);
  const [formData, setFormData] = useState({
    ...proposta,
    situacao: {
      value: proposta.situacao,
      label: orcamentoSituacao[proposta.situacao],
    },
  });
  const [isSubmitting, setSubmitting] = useState(false);

  const [updateProposta] = useMutation(CRIAR_PROPOSTA);
  const [deleteProposta] = useMutation(DELETE_PROPOSTA);
  const [gerarUsuario] = useMutation(GERAR_USUARIO_SENHA);

  useEffect(() => {
    if (!proposta?.id) return;

    setFormData({
      ...proposta,
      situacao: {
        value: proposta.situacao,
        label: orcamentoSituacao[proposta.situacao],
      },
    });

    setMotivoInformado(!!proposta.reprovadoMotivo);
  }, [proposta]);

  useEffect(() => {
    if (!motivoInformado || readOnly) return;

    handleSubmitDadosCampanha();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motivoInformado]);

  const handleSubmitDadosCampanha = (event) => {
    if (formData.situacao?.value === 'REPROVADO' && !motivoInformado) {
      setMotivoOpen(true);
      return;
    }

    setSubmitting(true);
    updateProposta({
      variables: {
        proposta: {
          titulo: formData.titulo,
          id: proposta.id,
          anunciante: {
            id: anunciante.id,
            nomeFantasia: anunciante.nomeFantasia,
          },
          dataCadastro: proposta?.id
            ? proposta.dataCadastro
            : moment().format('YYYY-MM-DD'),
          dataAlteracao: moment().format('YYYY-MM-DDTHH:mm'),
          situacao: formData.situacao?.value,
          observacao: formData.observacao,
        },
      },
    })
      .then(() => {
        setSubmitting(false);
        setReadOnly(true);
        toastNotification({
          message: 'Proposta salva com sucesso',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) => {
        setSubmitting(false);
        toastNotification({message: error.message, type: 'error'});
      });
  };

  const handleClickDeleteOrcamento = () => {
    confirmAlertCustom({
      message:
        'Deseja realmente excluir a negociação e todas as propostas nela?',
      onConfirm: () => handleDeleteOrcamento(),
      onCancel: () => null,
    });
  };

  const handleDeleteOrcamento = () => {
    setExcluindo(true);
    deleteProposta({
      variables: {
        proposta: {
          id: proposta.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Excluído!', type: 'success'});
        navigate('/propostas');
      })
      .catch((error) => {
        toastNotification({message: error.message, type: 'error'});
        setExcluindo(false);
      });
  };

  const gerarUsuarioSenhaAnunciante = (anunciante) => {
    if (!anunciante.emailUsuario) {
      toastNotification({
        message: 'Preencha o e-mail do usuário para gerar o usuário',
        type: 'error',
      });
      return;
    }

    gerarUsuario({
      variables: {
        anunciante: {
          id: anunciante.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.gerarUsuarioSenhaAnunciante}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.gerarUsuarioSenhaAnunciante);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={OrcamentoScheme}
          enableReinitialize
          initialValues={{
            ...formData,
            anunciante,
          }}
          onSubmit={handleSubmitDadosCampanha}>
          {({handleSubmit}) => (
            <Box component="form" sx={styles.form}>
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      key="anunciante"
                      name="anunciante"
                      value={formData.anunciante}
                      placeholder="Selecione"
                      component={SelectAnunciantes}
                      onChange={(anunciante) => setAnunciante(anunciante)}
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputV2
                      label="Título"
                      value={formData.titulo || ''}
                      onChange={({target}) =>
                        setFormData({...formData, titulo: target.value})
                      }
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Orcamentos
                  proposta={proposta}
                  readOnly={readOnly}
                  refetch={refetch}
                />
                <Grid item xs={12}>
                  <Textarea
                    sx={styles.textAreaObservacoes}
                    name="observacao"
                    label="Observações"
                    value={formData.observacao || ''}
                    onChange={({target}) =>
                      setFormData({...formData, observacao: target.value})
                    }
                    disabled={readOnly}
                  />
                </Grid>
                {proposta.reprovadoMotivo && (
                  <Grid item xs={12}>
                    <Textarea
                      sx={styles.textAreaObservacoes}
                      name="reprovadoMotivo"
                      label="Motivo da proposta ser reprovada"
                      value={proposta.reprovadoMotivo || ''}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container style={{marginTop: 'auto'}}>
                {['APROVADO'].includes(proposta.situacao) && (
                  <Grid
                    container
                    sx={styles.buttonContainer}
                    justifyContent="flex-end">
                    <ButtonComponent
                      id="button-green"
                      type="button"
                      value="Gerar usuário"
                      icon={<Person />}
                      onClick={() =>
                        gerarUsuarioSenhaAnunciante(proposta.anunciante)
                      }
                      sx={styles.button}
                    />
                  </Grid>
                )}
                {!['APROVADO', 'AGUARDANDO_APROVACAO'].includes(
                  proposta.situacao,
                ) && (
                  <Grid
                    container
                    sx={styles.buttonContainer}
                    justifyContent="space-between">
                    <ButtonComponent
                      type="button"
                      id="button-cancel"
                      value="Excluir"
                      loading={excluindo}
                      icon={<DeleteForever />}
                      onClick={handleClickDeleteOrcamento}
                      sx={styles.button}
                      disabled={!readOnly}
                    />
                    {readOnly && (
                      <ButtonComponent
                        id="button"
                        type="button"
                        value="Editar"
                        icon={<Edit />}
                        onClick={() => setReadOnly(false)}
                        sx={styles.button}
                      />
                    )}
                    {!readOnly && (
                      <ButtonComponent
                        id="button"
                        type="button"
                        value="Salvar"
                        loading={isSubmitting}
                        icon={<Save />}
                        sx={styles.button}
                        onClick={handleSubmit}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
        <MotivoReprovacaoProposta
          isOpen={motivoOpen}
          proposta={proposta}
          onSave={() => {
            setMotivoOpen(false);
            setMotivoInformado(true);
          }}
        />
      </Paper>
    </Grid>
  );
};

const OrcamentoScheme = Yup.object().shape({
  anunciante: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
});

const Orcamentos = ({proposta = {}, readOnly, refetch}) => {
  const navigate = useNavigate();
  const {role} = useSelector((store) => store.Auth);

  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamento, setOrcamento] = useState(undefined);
  const [cadOrcamentoOpen, setCadOrcamentoOpen] = useState(false);

  const [duplicar, duplicarQuery] = useMutation(DUPLICAR_ORCAMENTO_PROPOSTA);
  const [loadOrcamentos, orcamentosQuery] = useLazyQuery(
    FIND_ALL_ORCAMENTOS_PROPOSTA,
    {
      variables: {
        proposta: {
          id: proposta.id,
        },
      },
    },
  );

  useEffect(() => {
    if (!proposta.id) return;
    loadOrcamentos();
  }, [proposta, loadOrcamentos]);

  useEffect(() => {
    if (orcamentosQuery.loading || orcamentosQuery.error) return;

    setOrcamentos(orcamentosQuery.data?.orcamentos || []);

    if (orcamento?.id) {
      setOrcamento(
        orcamentosQuery.data?.orcamentos.find((o) => o.id === orcamento.id),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamentosQuery]);

  useEffect(() => {
    setCadOrcamentoOpen(!!orcamento);
  }, [orcamento]);

  if (proposta?.situacao === 'REPROVADO' && proposta.orcamentos?.length === 0) {
    return null;
  }

  const handleClickDuplicar = (orcamento) => {
    confirmAlertCustom({
      message: 'Deseja realmente duplicar esta proposta?',
      onConfirm: () => handleDuplicar(orcamento),
      onCancel: () => null,
    });
  };

  const handleDuplicar = (orcamento) => {
    duplicar({
      variables: {
        orcamento: {
          id: orcamento.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Proposta duplicada', type: 'success'});
        orcamentosQuery.refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleViewClick = (orcamento) => {
    if (
      proposta.situacao === 'AGUARDANDO_APROVACAO' &&
      role === 'LICENCA_ADMINISTRADOR'
    )
      return navigate(`/propostas/${orcamento.id}/aprovar`);

    setOrcamento(orcamento);
  };

  if (orcamentosQuery.loading) {
    return (
      <Grid item xs={12} sx={styles.cidadesContainer}>
        <Grid item xs={12} sx={styles.criarOrcamentoContainer}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sx={styles.cidadesContainer}>
      {orcamentos.length === 0 && (
        <Grid item xs={12} sx={styles.criarOrcamentoContainer}>
          <ButtonComponent
            id="button"
            type="button"
            value="Criar proposta"
            onClick={() => setCadOrcamentoOpen(true)}
            sx={styles.button}
          />
        </Grid>
      )}
      {orcamentos.length > 0 && (
        <>
          <Grid container item xs={12} justifyContent="space-between">
            <Typography sx={styles.headerText}>Propostas</Typography>
            {readOnly &&
              !['APROVADO', 'REPROVADO', 'AGUARDANDO_APROVACAO'].includes(
                proposta.situacao,
              ) && (
                <ButtonComponent
                  id="button-primary-empty"
                  value="Nova proposta"
                  type="button"
                  icon={<Add />}
                  onClick={() => setCadOrcamentoOpen(true)}
                  style={{width: 185, height: 28}}
                />
              )}
          </Grid>
          <Grid>
            <Table>
              <TableHead>
                <TableRow sx={styles.tableRow}>
                  <TableCell key="cidades" sx={styles.tableCellHeader}>
                    Cidades
                  </TableCell>
                  <TableCell key="veiculos" sx={styles.tableCellHeader}>
                    Veículos
                  </TableCell>
                  <TableCell key="periodo" sx={styles.tableCellHeader}>
                    Períodos
                  </TableCell>
                  <TableCell key="valor" sx={styles.tableCellHeader}>
                    Valor total
                  </TableCell>
                  <TableCell key="cadastro" sx={styles.tableCellHeader}>
                    Cadastro
                  </TableCell>
                  <TableCell
                    key="acoes"
                    align="center"
                    width={100}
                    sx={styles.tableCellHeader}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orcamentos.map((orcamento) => (
                  <TableRow
                    key={orcamento.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={[
                      styles.tableRow,
                      orcamento.situacao === 'APROVADO' &&
                        styles.tableRowAprovado,
                    ]}>
                    <TableCell sx={styles.tableCell}>
                      {orcamento?.itens.length}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {orcamento.itens
                        ?.map((i) => i.quantidadeVeiculos)
                        .reduce((total, item) => total + item, 0)}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {`${orcamento.quantidadeMeses} de ${orcamento.diasPeriodo} dias`}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {Number.currencyFormat(orcamento.valorTotalCampanha)}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {Data.dataFormat(orcamento.dataCadastro, 'DD/MM/YY')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Grid
                        style={{
                          position: 'relative',
                          display: 'flex',
                          padding: '0 10px',
                        }}>
                        <RoundTooltipButton
                          type="button"
                          title="Visualizar"
                          icon={<Visibility />}
                          onClick={() => handleViewClick(orcamento)}
                          style={{
                            color: '#0083e6',
                            background: 'transparent',
                            margin: 0,
                          }}
                        />
                        <RoundTooltipButton
                          type="button"
                          title="Duplicar"
                          icon={<FileCopyOutlined />}
                          onClick={() => handleClickDuplicar(orcamento)}
                          style={{
                            color: '#2CBDA5',
                            background: 'transparent',
                            margin: 0,
                            visibility: ['APROVADO', 'REPROVADO'].includes(proposta.situacao) ? 'hidden' : 'visible'
                          }}
                          loading={duplicarQuery.loading}
                        />
                        {orcamento.situacao === 'APROVADO' && (
                          <RoundTooltipButton
                            type="button"
                            title="Aprovado"
                            icon={<Check />}
                            sx={styles.statusIcon}
                            style={{color: '#55AB23'}}
                          />
                        )}
                        {orcamento.situacao === 'REPROVADO' && (
                          <RoundTooltipButton
                            type="button"
                            title="Reprovado"
                            icon={<Close />}
                            sx={styles.statusIcon}
                            style={{color: '#DC3131'}}
                          />
                        )}
                        {orcamento.reprovadoMotivo && (
                          <RoundTooltipButton
                            type="button"
                            title="Pedido reprovado"
                            icon={<Report />}
                            sx={styles.statusIcon}
                            style={{color: '#DC3131'}}
                          />
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={styles.tableRow}>
                  <TableCell key="cidades" sx={styles.tableCellHeader}>
                    {`Total: ${orcamentos.length} proposta${
                      orcamentos.length > 1 ? 's' : ''
                    }`}
                  </TableCell>
                  <TableCell key="veiculos" sx={styles.tableCellHeader} />
                  <TableCell key="periodo" sx={styles.tableCellHeader} />
                  <TableCell key="valor" sx={styles.tableCellHeader}>
                    {Number.currencyFormat(
                      orcamentos
                        .map((o) => o.valorTotalCampanha)
                        .reduce((v1, v2) => v1 + v2, 0),
                    )}
                  </TableCell>
                  <TableCell key="cadastro" sx={styles.tableCellHeader} />
                  <TableCell key="acoes" sx={styles.tableCellHeader} />
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </>
      )}
      <CadastroOrcamentoModal
        openModal={cadOrcamentoOpen}
        proposta={proposta}
        orcamento={orcamento}
        onClose={() => {
          setOrcamento(undefined);
          setCadOrcamentoOpen(false);
          refetch();
          loadOrcamentos();
        }}
      />
    </Grid>
  );
};

const styles = {
  headerText: {
    fontWeight: 700,
    color: (theme) => theme.palette.paterns.navyBlue,
    padding: '6px 8px',
  },
  tableCell: {
    padding: '0 16px !important',
    borderBottom: `1px solid #0004`,
  },
  tableCellHeader: {
    zIndex: '0 !important',
    fontFamily: 'AktivGrotesk-Medium',
    borderBottom: `1px solid #0004`,
  },
  tableRow: {
    cursor: 'default',
    backgroundColor: 'transparent !important',
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  anunciante: {
    display: 'flex',
  },
  criarOrcamentoContainer: {
    display: 'flex',
    borderRadius: '4px',
    padding: '1rem 0 !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cidadesContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#e4e9f3bf',
    padding: '8px',
    flexDirection: 'column',
    margin: '24px 0',
  },
  button: {
    width: 200,
  },
  buttonRight: {
    float: 'right',
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  textAreaObservacoes: {
    paddingTop: '12px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '80px',
    marginBottom: 0,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  statusIcon: {
    position: 'absolute',
    background: 'transparent',
    top: 0,
    left: '75px',
    cursor: 'default',

    '& svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
  tableRowAprovado: {
    background: '#25e18a26 !important',
  },
  form: {
    minHeight: 'calc(100vh - 56px - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default FormularioProposta;
