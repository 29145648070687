import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaTabelaPreco from './consulta';

const TabelaPreco = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaTabelaPreco />} />
    </Routes>
  );
};

export default TabelaPreco;
