import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  motoristas: [],
  hasMore: false,
  totalMotoristas: 0,
  refetchMotoristas: false,
};

const acaoAtualizarMotoristasDaCampanha = state => {
  state.refetchMotoristas = true;
};

const acaoPersistMotoristasDaCampanha = (state, action) => {
  const {content, last, totalElements} = action.payload || {};

  state.motoristas = content || [];
  state.hasMore = !last;
  state.totalMotoristas = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'motoristas',
  initialState: initialState,
  reducers: {
    persistMotoristasDaCampanha: acaoPersistMotoristasDaCampanha,
    atualizarMotoristasDaCampanha: acaoAtualizarMotoristasDaCampanha,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistMotoristasDaCampanha,
  atualizarMotoristasDaCampanha,
} = actions;
