import {useMutation} from '@apollo/client';
import {Close, Save} from '@mui/icons-material';
import {Box, Grid, Modal, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import InputV2Formik from '../../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../../components/mui-button';
import {toastNotification} from '../../../components/toastify';
import {CRIAR_ANUNCIANTE} from '../../../graphql/mutation';
import Objects from '../../../utils/objects';
import string from '../../../utils/string';
import {SelectSegmentos} from '../../selects/segmento';

const CadastroClienteSimplificadoModal = ({
  isOpen,
  onClose,
  onSave,
  cnpjValue = '',
}) => {
  const [criarAnunciante] = useMutation(CRIAR_ANUNCIANTE);

  async function handleSubmitNovoCliente(
    {marketingTelefone, segmentoAtuacao, cnpj, ...values},
    {setSubmitting},
  ) {
    const anunciante = {
      ...values,
      nomeRazao: '',
      cnpj: string.removeSpecialChars(cnpj),
      marketingTelefone: string.removeSpecialChars(marketingTelefone),
      segmentoAtuacao: Objects.jsToEntity(segmentoAtuacao),
    };

    try {
      const response = await criarAnunciante({
        variables: {
          anunciante: {
            ...anunciante,
          },
        },
      });

      toastNotification({message: 'Cadastrado com sucesso!', type: 'success'});
      onSave({
        ...anunciante,
        id: response.data.createAnunciante.id,
        value: response.data.createAnunciante.id,
        label: anunciante.nomeFantasia,
      });
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}>
      <Grid sx={styles.content}>
        <Grid sx={styles.header}>
          <Typography sx={styles.headerText}>Novo cliente</Typography>
          <Close sx={styles.closeIcon} onClick={onClose} />
        </Grid>
        <Formik
          validationSchema={ClienteScheme}
          initialValues={{
            nomeFantasia: '',
            cnpj: cnpjValue,
            marketingNome: '',
            marketingEmail: '',
            marketingTelefone: '',
            segmentoAtuacao: undefined,
          }}
          onSubmit={handleSubmitNovoCliente}>
          {({isSubmitting, handleSubmit, values}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="nomeFantasia"
                    label="Nome fantasia"
                    placeholder="Digite o nome fantasia"
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    component={InputV2Formik}
                    name="cnpj"
                    label="CNPJ"
                    mask="cnpj"
                    placeholder="Digite o CNPJ"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={SelectSegmentos}
                    name="segmentoAtuacao"
                    label="Segmento de atuação"
                    maxMenuHeight={180}
                    isClearable
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Field
                    component={InputV2Formik}
                    name="marketingNome"
                    label="Contato / Marketing"
                    placeholder="Digite o nome do responsável pelo marketing"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={InputV2Formik}
                    name="marketingTelefone"
                    label="Telefone"
                    placeholder="(00) 0000-0000"
                    mask="phone"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    component={InputV2Formik}
                    name="marketingEmail"
                    label="Email"
                    placeholder="marketing@exemplo.com"
                  />
                </Grid>
              </Grid>
              <Grid sx={styles.footer}>
                <ButtonComponent
                  id="button-cancel-empty"
                  sx={styles.buttons}
                  type="button"
                  value="Cancelar"
                  disabled={isSubmitting}
                  icon={<Close />}
                  onClick={onClose}
                />
                <ButtonComponent
                  id="button"
                  sx={styles.buttons}
                  type="submit"
                  value="Salvar"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  icon={<Save />}
                />
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

const ClienteScheme = Yup.object().shape({
  nomeFantasia: Yup.string().required('Campo obrigatório'),
  marketingNome: Yup.string().required('Campo obrigatório'),
  marketingTelefone: Yup.string().required('Campo obrigatório'),
  marketingEmail: Yup.string().required('Campo obrigatório'),
  cnpj: Yup.string().nullable().test('validar-cnpj', 'CNPJ inválido', (value) => string.isEmpty(value) || string.validaCNPJ(value)),
});

const styles = {
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0',
  },
  closeIcon: {
    color: '#0083e6',
    fontSize: '30px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '1000px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '16px',
  },
  buttons: {
    width: '100%',
    marginBottom: '12px',

    '@media (min-width: 1024px)': {
      width: '185px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    marginTop: '70px',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
};

export default CadastroClienteSimplificadoModal;
