import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  client: undefined
};

const actionSetApollo = (state, action) => {
  state.client = action.payload;
};

const resumeSlice = createSlice({
  name: 'apollo',
  initialState: initialState,
  reducers: {
    setApollo: actionSetApollo
  }
});

export const { actions, reducer } = resumeSlice;
export const { setApollo } = actions;