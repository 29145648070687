import React from 'react';
import {Grid, Typography, Modal} from '@mui/material';
import CadastroProposta from './cadastro-proposta';
import {Close} from '@mui/icons-material';

const CadastroPropostaModal = ({isOpen, onClose, ...props}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Grid sx={styles.content}>
        <Grid sx={styles.header}>
          <Typography sx={styles.headerText}>Nova negociação</Typography>
          <Close sx={styles.closeIcon} onClick={onClose} />
        </Grid>
        <Grid sx={styles.detalhesContainer} style={{height: 'calc(100% - 46px)'}}>
          <CadastroProposta onClose={onClose} />
        </Grid>
      </Grid>
    </Modal>
  );
};

const styles = {
  modal: {
    zIndex: '10 !important'
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0'
  },
  closeIcon: {
    color: '#0083e6',
    fontSize: '30px',
    cursor: 'pointer'
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '16px'
  },
  avatarLogo: {
    display: 'flex',
    width: '135px',
    height: '135px',
    borderRadius: '4px',
    margin: '2px 10px',
    backgroundColor: '#cfd4df',
    justifyContent: 'center',
    alignItems: 'center',
  },
  anuncianteContainer: {
    width: 'calc(100% - 155px)',
  },
};

export default CadastroPropostaModal;
