import React from 'react';
import styled from '@emotion/styled';
import ModalComponent from '../../components/modal';
import Image from '../../components/image';

const ImagemModal = ({id, titulo, ...props}) => {
  return (
    <ModalComponent {...props}>
      <Container>
        <Header>
          <HeaderTitle>{titulo}</HeaderTitle>
        </Header>
        <ImagemStyled id={id} />
      </Container>
    </ModalComponent>
  );
};

const Container = styled.div`
  margin-top: -28px;
  position: relative;
  z-index: -1;
  text-align: center;
`;

const ImagemStyled = styled(Image)`
  height: 60vh;
  margin-top: 30px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #0083e6;
`;

export default ImagemModal;
