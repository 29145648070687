import moment from 'moment';

const Data = {
  hoje: (format = 'DD/MM/YYYY') => {
    return moment().format(format);
  },
  dataFormat: (data, format = 'DD/MM/YYYY') => {
    if (!data) return undefined;

    return moment(data).format(format);
  },
  toDate: (data) => {
    if (!data) return undefined;

    return moment(data).toDate();
  },
  toMoment: (data) => {
    if (!data) return undefined;

    return moment(data);
  },
  dataFinalCampanha: (dataInicio, dias, meses, format) => {
    if (!dataInicio || !dias || !meses) return '';

    const data = moment(dataInicio).add(dias * meses, 'days').subtract(1, 'days');

    if (format)
      return data.format('DD/MM/YYYY');

    return data;
  },
  dataAtualOuFinalCampanha: (dataInicio, dias, meses) => {
    let dataFinal = moment(dataInicio).add(dias * meses, 'days').subtract(1, 'days');
    let hoje = moment();

    if (hoje.diff(dataFinal, 'days') < 0)
      return hoje;

    return dataFinal;
  },
  periodoDaCampanha: (dataInicio, dias, meses) => {
    if (!dataInicio) return 1;

    let inicio = moment(dataInicio);
    let hoje = moment();

    const periodo = parseInt((hoje.diff(inicio, 'days') / dias) + 1);

    if (periodo >= meses)
      return meses;

    return periodo;
  },
  dataInicioPeriodoAtual: (dataInicio, dias, meses) => {
    if (!dataInicio || !meses)
      return '';
    const periodo = Data.periodoDaCampanha(dataInicio, dias, meses);

    return moment(dataInicio).add(dias * (periodo - 1), 'days');
  },
  dataFimPeriodoAtual: (dataInicio, dias) => {
    if (!dataInicio) return '';

    return moment(dataInicio).add(dias - 1, 'days');
  },
  diasPassadosPeriodo: (dataInicio) => {
    if (!dataInicio)
      return 1;

    let inicio = moment(dataInicio);
    let hoje = moment();

    return hoje.diff(inicio, 'days') + 1;
  },
  dataPassada: (data) => {
    if (!data) return false;

    return moment(data).isBefore(moment());
  },
  dataFutura: (data) => {
    if (!data) return false;

    return moment(data).isSameOrAfter(moment());
  }
};

export default Data;
