import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  selecionado: {},
  campanhas: [],
  notFound: false,
  filtrosCampanha: {
    filtros: {
      campanhaSituacao: {label: 'Todas'},
    },
    page: 0,
    orderBy: {campo: 'dataInicioVeiculacao', asc: false},
  },
  pageableDefault: {
    pageSize: 10,
    pageNumber: 0,
    sortDir: 'DESC',
    sortField: 'dataCompra',
  },
  searchDTODefault: {},
  searchDTO: {},
  hasMore: false,
  totalCampanhas: 0,
  nextPage: 0,
  refetchCampanhas: false,
  ultimasLocalizacoesDaCampanha: [],
};

const acaoPersistUtimasLocalizacoesDaCampanha = (state, {payload}) => {
  state.ultimasLocalizacoesDaCampanha = payload || [];
};

const acaoAtualizarCampanhas = state => {
  state.refetchCampanhas = true;
};

const acaoSelecionarCampanha = (state, action) => {
  state.selecionado = action.payload;
  state.ultimasLocalizacoesDaCampanha = [];
};

const acaoPersistCampanhaSearchDTO = (state, action) => {
  state.searchDTO = action.payload;
};

const acaoPersistFiltrosCampanha = (state, action) => {
  state.filtrosCampanha = action.payload;
};

const acaoPersistCampanhas = (state, action) => {
  const {content = [], last, totalElements, pageable} = action.payload || {};

  state.notFound = content.length === 0;

  state.campanhas = content.map(campanha => {
    return {
      ...campanha
    };
  });

  const currentPage = pageable && pageable.pageNumber ? pageable.pageNumber : 0;

  state.selecionado = {};
  state.ultimasLocalizacoesDaCampanha = [];
  state.hasMore = !last;
  state.totalCampanhas = totalElements || 0;
  state.nextPage = currentPage + 1;
  state.refetchCampanhas = false;
};

const resumeSlice = createSlice({
  name: 'campanha',
  initialState: initialState,
  reducers: {
    selecionarCampanha: acaoSelecionarCampanha,
    persistCampanhas: acaoPersistCampanhas,
    persistCampanhaSearchDTO: acaoPersistCampanhaSearchDTO,
    atualizarCampanhas: acaoAtualizarCampanhas,
    persistUtimasLocalizacoesDaCampanha: acaoPersistUtimasLocalizacoesDaCampanha,
    persistFiltrosCampanha: acaoPersistFiltrosCampanha,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  selecionarCampanha,
  persistCampanhas,
  atualizarCampanhas,
  persistCampanhaSearchDTO,
  persistUtimasLocalizacoesDaCampanha,
  persistFiltrosCampanha
} = actions;
