import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import ReactExport from 'react-export-excel';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  Typography,
  Checkbox,
  Box
} from '@mui/material';
import {
  GetApp,
  Publish,
  Visibility,
  Close,
  Download,
  Feed,
} from '@mui/icons-material';
import {
  atualizarExtratoRepasse,
  persistExtratoRepasse,
  persistFiltrosExtratoRepasse,
} from '../../redux/ExtratoRepasseComercial/slice';
import {FIND_EXTRATO_REPASSE_DO_COMERCIAL} from '../../graphql/queries';
import Number from '../../utils/number';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../components/mui-button';
import Select from '../../components/select/mui-select';
import {SelectAnunciantes} from '../../containers/selects/anunciantes';
import {SelectCampanhas} from '../../containers/selects/campanhas';
import {SelectCidades} from '../../containers/selects/cidades';
import DetalhesExtrato from './detalhes';
import DarBaixaExtrato from './modais/dar-baixa';
import {
  ESTORNAR_MULTIPLAS_MOVIMENTACOES,
  ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA,
  REPROVAR_MULTIPLAS_MOVIMENTACOES,
  MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES,
} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {confirmAlertCustom} from '../../components/confirm-alert/confirm-alert';
import TableCellOrdered from '../../components/table-cell-ordered';
import ReprovarExtrato from './modais/reprovar-movimentacao';
import InputV2 from '../../components/input-v2/mui-input-v2';
import DarBaixaExtratosMultiplos from './modais/dar-baixa-multiplas';
import getColunas from './constantes/colunas';
import getDestinosRecebimento from './constantes/destinos-recebimento';
import Data from '../../utils/data';
import InputDate from '../../components/mui-input-date';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const getFiltroOrigem = (origem) => {
  switch (origem) {
    case 'ROYALTIES': return {movimentacaoOrigem: origem, royaltiesKm: true};
    case 'ROYALTIES_EXPORTACAO': return {movimentacaoOrigem: 'ROYALTIES', royaltiesKm: false, movimentacaoParcelaTipo: 'ROYALTY_EXPORTACAO'};
    case 'ROYALTIES_IMPORTACAO': return {movimentacaoOrigem: 'ROYALTIES', royaltiesKm: false, movimentacaoParcelaTipo: 'ROYALTY_IMPORTACAO'};
    default: return {movimentacaoOrigem: origem};
  }
}

const ConsultaExtratoRepasse = ({pageSize = 20, tipoLancamento}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    filtrosExtrato,
    extratoRepasseList,
    totalExtratoRepasse,
    refetchExtratoRepasse,
  } = useSelector((store) => store.ExtratoRepasseComercial);

  const [page, setPage] = useState(filtrosExtrato.page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [filtros, setFiltros] = useState({
    ...filtrosExtrato.filtros,
    movimentacaoOrigem: {
      value: tipoLancamento === 'PAGAR' ? 'MOTORISTA' : 'ANUNCIANTE',
      label: tipoLancamento === 'PAGAR' ? 'Motoristas' : 'Campanhas',
    },
    dataPrevisaoPagamentoInicio:
      filtrosExtrato.filtros?.dataPrevisaoPagamentoInicio &&
      Data.toMoment(JSON.parse(filtrosExtrato.filtros?.dataPrevisaoPagamentoInicio))
        ?.startOf('month')
        ?.toDate(),
    dataInicial:
      filtrosExtrato.filtros?.dataInicial &&
      Data.toMoment(JSON.parse(filtrosExtrato.filtros?.dataInicial))
        ?.startOf('month')
        ?.toDate(),
  });
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosExtrato.orderBy);
  const [selecionado, setSelecionado] = useState(undefined);
  const [openBaixa, setOpenBaixa] = useState(false);
  const [openBaixasMultiplas, setOpenBaixasMultiplas] = useState(false);
  const [openReprovar, setOpenReprovar] = useState(false);
  const [isOpenDetalhes, setIsOpenDetalhes] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [chavesPix, setChavesPix] = useState([]);
  const [columns, setColumns] = useState([]);

  const [estornarBaixaMovimentacao] = useMutation(
    ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA,
  );
  const [reprovarMultiplasMovimentacoes, {loading: reprovarLoading}] =
    useMutation(REPROVAR_MULTIPLAS_MOVIMENTACOES);
  const [estornarMultiplasMovimentacoes, {loading: estornarLoading}] =
    useMutation(ESTORNAR_MULTIPLAS_MOVIMENTACOES);
  const [errorMultiplasMovimentacoes, {loading: errorLoading}] =
    useMutation(MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES);

  const extratoRepasseQuery = useQuery(FIND_EXTRATO_REPASSE_DO_COMERCIAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        dataPrevisaoPagamentoInicio: Data.dataFormat(searchDTO.dataPrevisaoPagamentoInicio, 'YYYY-MM-DD'),
        dataInicial: Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD'),
        statusMovimentacaoFinanceira: searchDTO.movimentacaoOrigem?.value?.includes('ROYALTIES') ?
          undefined : searchDTO.statusMovimentacaoFinanceira?.value,
        campanhaId: searchDTO.campanha?.value,
        anuncianteId: searchDTO.anunciante?.value,
        comercialId: searchDTO.comercial?.value,
        search: searchDTO.search,
        instaladorId: searchDTO.instalador?.value,
        cidadeId: searchDTO.cidade?.value,
        ...getFiltroOrigem(searchDTO.movimentacaoOrigem?.value),
      },
    },
  });

  useEffect(() => {
    if (
      !extratoRepasseQuery.loading &&
      !extratoRepasseQuery.error &&
      extratoRepasseQuery.data
    ) {
      setSelecionados([]);
      dispatch(
        persistExtratoRepasse(extratoRepasseQuery.data?.extrato || []),
      );
      dispatch(
        persistFiltrosExtratoRepasse({
          filtros: {
            ...filtros,
            dataPrevisaoPagamentoInicio:
              filtros.dataPrevisaoPagamentoInicio &&
              JSON.stringify(filtros.dataPrevisaoPagamentoInicio),
            dataInicial:
              filtros.dataInicial && JSON.stringify(filtros.dataInicial),
          },
          page,
          orderBy,
        }),
      );
    }
    if (refetchExtratoRepasse) {
      extratoRepasseQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extratoRepasseQuery, dispatch, refetchExtratoRepasse]);

  useEffect(() => {
    if (!filtros.movimentacaoOrigem) return [];
    setColumns(getColunas(filtros.movimentacaoOrigem.value));
  }, [filtros.movimentacaoOrigem]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickExtrato = (movimentacaoFinanceira) => {
    setIsOpenDetalhes(true);
    setSelecionado(movimentacaoFinanceira);
  };

  const handleCloseDetalhes = () => {
    setIsOpenDetalhes(false);
    setSelecionado(undefined);
  };

  const handleClickBaixa = (movimentacao) => {
    setSelecionado(movimentacao);
    setOpenBaixa(true);
  };

  const handleClickReprovar = (movimentacao) => {
    setSelecionado(movimentacao);
    setOpenReprovar(true);
  };

  const handleEstornarBaixa = (movimentacao) => {
    setSelecionado(movimentacao);

    estornarBaixaMovimentacao({
      variables: {
        id: movimentacao.id,
      },
    })
      .then(() => {
        extratoRepasseQuery.refetch();
        toastNotification({message: 'A baixa foi estornada!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleClickBaixarMovimentacoes = () => {
    confirmAlertCustom({
      message: `Deseja dar baixa em ${selecionados.length} movimentações?`,
      onConfirm: handleBaixarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickEstornarMovimentacoes = () => {
    confirmAlertCustom({
      message: `Deseja estornar ${selecionados.length} movimentações?`,
      onConfirm: handleEstornarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickReprovarMovimentacoes = () => {
    confirmAlertCustom({
      message: `Deseja reprovar ${selecionados.length} movimentações?`,
      onConfirm: handleReprovarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickErrorMovimentacoes = () => {
    confirmAlertCustom({
      message: `Deseja marcar ${selecionados.length} movimentações como pagamento inválido?`,
      onConfirm: handleErrorMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleBaixarMovimentacoes = () => {
    setOpenBaixasMultiplas(true);
  };

  const handleEstornarMovimentacoes = () => {
    estornarMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.estornos} movimentações estornadas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleErrorMovimentacoes = () => {
    errorMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.movimentacoes} movimentações alteradas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleReprovarMovimentacoes = () => {
    reprovarMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.reprovados} movimentações reprovadas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleClickEstornar = (movimentacao) => {
    confirmAlertCustom({
      message: 'Deseja estornar a baixa?',
      onConfirm: () => handleEstornarBaixa(movimentacao),
      onCancel: () => null,
    });
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleDestinoChange = (event) => {
    var dataPrevisaoPagamentoInicio =
      filtros.dataPrevisaoPagamentoInicio || new Date();

    setFiltros({
      ...filtros,
      dataPrevisaoPagamentoInicio,
      movimentacaoOrigem: event,
    });
    setSearchDTO({
      ...searchDTO,
      dataPrevisaoPagamentoInicio,
      movimentacaoOrigem: event,
    });
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  useEffect(() => {
    if (chavesPix.length === 0) return;

    setTimeout(() => setChavesPix([]), 1500);

    // eslint-disable-next-line
  }, [chavesPix]);

  const handleSelectAll = () => {
    if (selecionados.length === extratoRepasseList.length) return setSelecionados([]);

    setSelecionados(extratoRepasseList.map((e) => e.id));
  };

  const exportarChavesPix = () => {
    setChavesPix(
      extratoRepasseList.map((e) => ({
        'pix': e.motorista?.dadoBancario?.chavePix?.toString(),
        'valor': Number.currencyFormat(e.valor)?.replace('R$', ''),
        'descricao': e.motorista?.nome,
      })),
    );
  };

  const reduceValorSelecionados = () => {
    const valor = extratoRepasseList.filter(e => selecionados.includes(e.id)).map(e => e.valor).reduce((v1, v2) => v1 + v2);
    return Number.currencyFormat(valor);
  }

  return (
    <Grid id="page-container" container>
      <RoundTooltipButton
        id="icon-toolbar"
        title={'Exportar para Excel'}
        onClick={exportarChavesPix}
        loading={extratoRepasseQuery.loading}
        icon={<Download />}
        loadingColor="grey"
        hidden={filtros.movimentacaoOrigem?.value !== 'MOTORISTA'}
      />
      <RoundTooltipButton
        id="icon-toolbar-secondary"
        title={'Relatório de pagamentos'}
        onClick={() => navigate('relatorio-pagamentos')}
        loading={extratoRepasseQuery.loading}
        icon={<Feed />}
        loadingColor="grey"
        hidden={filtros.movimentacaoOrigem?.value !== 'MOTORISTA'}
      />
      {chavesPix.length > 0 && (
        <ExcelFile hideElement={true} filename="Motoristas">
          <ExcelSheet data={chavesPix} name="Motoristas">
            <ExcelColumn label="Chave Pix" value="pix" />
            <ExcelColumn label="Valor" value="valor" />
            <ExcelColumn label="Descrição ( até 50 caracteres )" value="descricao" />
          </ExcelSheet>
        </ExcelFile>
      )}
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, anunciante: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectCidades}
                  label="Cidade"
                  name="cidade"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCampanhas
                      searchDTO={{
                        anuncianteId: filtros.anunciante?.value,
                        cidadeCampanhaId: filtros.cidade?.value,
                      }}
                      {...props}
                    />
                  )}
                  label="Campanha"
                  name="campanha"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, campanha: e})}
                />
              </Grid>
              {filtros.movimentacaoOrigem?.value === 'MOTORISTA' && (
                <Grid item xs={3}>
                  <InputV2
                    label="Motorista"
                    value={filtros.search}
                    name="motorista"
                    onChange={({target}) =>
                      setFiltros({...filtros, search: target.value})
                    }
                  />
                </Grid>
              )}
              <Grid item xs={1} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  id="button"
                  value="Filtrar"
                  loading={extratoRepasseQuery.loading}
                  onClick={refetch}
                  style={{minWidth: 0}}
                />
              </Grid>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={3}>
                  <Select
                    options={getDestinosRecebimento(tipoLancamento)}
                    label="Destino Recebimento"
                    value={filtros.movimentacaoOrigem}
                    onChange={handleDestinoChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputDate
                    label="Período de pagamento"
                    selected={filtros.dataPrevisaoPagamentoInicio}
                    dateFormat="MMMM/yyyy"
                    showMonthYearPicker
                    onChange={(e) => {
                      setFiltros({
                        ...filtros,
                        dataPrevisaoPagamentoInicio: e,
                      });
                      setSearchDTO({
                        ...searchDTO,
                        dataPrevisaoPagamentoInicio: e,
                      });
                    }}
                    isClearable
                  />
                </Grid>
                {!filtros.movimentacaoOrigem?.value?.includes('ROYALTIES') && (
                  <Grid item xs={2}>
                    <Select
                      options={[
                        {value: undefined, label: 'Todos'},
                        {value: 'PENDENTE', label: 'Pendente'},
                        {value: 'APROVADO', label: 'Aprovado'},
                        {value: 'FINALIZADO', label: 'Pago'},
                        {value: 'REPROVADO', label: 'Reprovado'},
                        {value: 'ERRO_PAGAMENTO', label: 'Erro no pagamento'},
                      ]}
                      label="Situação"
                      value={filtros.statusMovimentacaoFinanceira}
                      onChange={(e) => {
                        setFiltros({
                          ...filtros,
                          statusMovimentacaoFinanceira: e,
                        });
                        setSearchDTO({
                          ...searchDTO,
                          statusMovimentacaoFinanceira: e,
                        });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {selecionados.length > 0 && (
              <Grid sx={styles.acoesMassaContainer}>
                <Typography sx={{marginRight: '8px'}}>Ações em massa:</Typography>
                <ButtonComponent
                  id="button-green"
                  value={'Dar baixa'}
                  onClick={handleClickBaixarMovimentacoes}
                  sx={styles.acoesMassaButton}
                />
                <ButtonComponent
                  id="button"
                  loading={estornarLoading}
                  value={'Estornar'}
                  onClick={handleClickEstornarMovimentacoes}
                  sx={styles.acoesMassaButton}
                />
                <ButtonComponent
                  id="button-cancel"
                  loading={reprovarLoading}
                  value={'Reprovar'}
                  onClick={handleClickReprovarMovimentacoes}
                  sx={styles.acoesMassaButton}
                />
                <ButtonComponent
                  id="button-yellow"
                  loading={errorLoading}
                  value={'Erro no pagamento'}
                  onClick={handleClickErrorMovimentacoes}
                  sx={styles.acoesMassaButton}
                />
                <Typography style={{marginLeft: 'auto'}}>
                  {`${selecionados.length} selecionados - Valor: ${reduceValorSelecionados()}`}
                </Typography>
              </Grid>
            )}
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="acoes-check-all"
                      align="center"
                      sx={styles.checkBoxCell}>
                      <Checkbox
                        color="primary"
                        checked={selecionados.length > 0 && selecionados.length === extratoRepasseList.length}
                        indeterminate={
                          selecionados.length > 0 &&
                          selecionados.length < extratoRepasseList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        style={{minWidth: column.minWidth || 'auto'}}
                      />
                    ))}
                    <TableCell key="acoes" align="center" style={{fontWeight: 'bold'}}>
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extratoRepasseList.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        style={
                          selecionados.includes(row.id)
                            ? {background: '#F0F5FF'}
                            : {background: '#FFF'}
                        }>
                        <TableCell
                          key="acoes-check-one"
                          align="center"
                          sx={styles.checkBoxCell}>
                          <Checkbox
                            color="primary"
                            checked={selecionados.includes(row.id)}
                            onClick={() => {
                              if (selecionados.includes(row.id))
                                return setSelecionados((s) =>
                                  s.filter((id) => id !== row.id),
                                );
                              setSelecionados((s) => s.concat(row.id));
                            }}
                          />
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format({
                                  value,
                                  row,
                                  movimentacaoOrigem:
                                    filtros.movimentacaoOrigem,
                                })
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center" style={{minWidth: '180px'}}>
                          <Grid container spacing={0.8} justifyContent="center">
                            <Grid item>
                              <RoundTooltipButton
                                id="icon-primary"
                                title={'Visualizar'}
                                onClick={() => handleClickExtrato(row)}
                                icon={<Visibility />}
                                sx={styles.actionButton}
                              />
                            </Grid>
                            {['FINALIZADO', 'REPROVADO'].includes(row.situacao) && (
                              <Grid item>
                                <RoundTooltipButton
                                  id="icon-cancel"
                                  title={'Estornar'}
                                  loading={selecionado?.id === row.id}
                                  onClick={() => handleClickEstornar(row)}
                                  icon={<Publish />}
                                  sx={styles.actionButton}
                                />
                              </Grid>
                            )}
                            {['APROVADO', 'ERRO_PAGAMENTO'].includes(row.situacao) && (
                              <Grid item>
                                <RoundTooltipButton
                                  id="icon-green"
                                  title={'Dar baixa'}
                                  onClick={() => handleClickBaixa(row)}
                                  icon={<GetApp />}
                                  sx={styles.actionButton}
                                />
                              </Grid>
                            )}
                            {['PENDENTE', 'APROVADO', 'ERRO_PAGAMENTO'].includes(row.situacao) && (
                              <Grid item>
                                <RoundTooltipButton
                                  id="icon-cancel"
                                  title={'Reprovar'}
                                  onClick={() => handleClickReprovar(row)}
                                  icon={<Close />}
                                  sx={styles.actionButton}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalExtratoRepasse}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={[20, 40, 50, 100]}
              onRowsPerPageChange={({target}) => setRowsPerPage(target?.value)}
              labelRowsPerPage="Linhas"
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
        <DetalhesExtrato
          openModal={isOpenDetalhes}
          onClose={handleCloseDetalhes}
          movimentacaoFinanceira={selecionado}
        />
        <DarBaixaExtrato
          isOpen={openBaixa}
          onClose={() => setOpenBaixa(false)}
          onBaixa={() => {
            setSelecionado(undefined);
            dispatch(atualizarExtratoRepasse());
          }}
          movimentacaoFinanceira={selecionado}
        />
        <DarBaixaExtratosMultiplos
          isOpen={openBaixasMultiplas}
          onClose={() => setOpenBaixasMultiplas(false)}
          onBaixa={() => {
            setSelecionados([]);
            dispatch(atualizarExtratoRepasse());
          }}
          selecionados={selecionados}
        />
        <ReprovarExtrato
          isOpen={openReprovar}
          onClose={() => setOpenReprovar(false)}
          onReprovar={() => {
            setSelecionado(undefined);
            dispatch(atualizarExtratoRepasse());
          }}
          movimentacaoFinanceira={selecionado}
        />
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    flexGrow: 1,
    alignContent: 'flex-start',
    background: theme => theme.palette.paterns.white,
    overflow: 'auto'
  },
  acoesMassaContainer: {
    height: 52,
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.12)',

    '& p': {
      fontFamily: 'AktivGrotesk-Bold',
      fontSize: '0.875rem',
      color: '#000000de',
    },
  },
  acoesMassaButton: {
    minWidth: '120px !important',
    width: 'auto !important',
    marginLeft: '8px',
    textTransform: 'none'
  },
  checkBoxCell: {
    width: '60px',
    padding: '0px',

    '& .MuiCheckbox-root': {
      color: '#CDCED9',
    },

    '& .Mui-checked': {
      color: '#1976d2 !important',
    },
  },
  actionButton: {
    margin: '0px !important'
  }
};

export default ConsultaExtratoRepasse;
