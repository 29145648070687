import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  Avatar,
  Modal,
  Paper,
  AppBar
} from '@mui/material';
import {ArrowDropDownRounded, PersonRounded} from '@mui/icons-material';
import {logout} from '../redux/Auth';
import {PERFIL_ROUTE, routeLabel} from '../constants/routes';
import {getImageAuth} from '../utils/get-image';

export const ToolBarComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {name: anuncianteNome, comercialResponsavel, photo} = useSelector((store) => store.Auth);
  const fotoPerfil = photo && getImageAuth(photo);

  const [openMenu, setOpenMenu] = useState(false);

  const handleClickLogout = async () => {
    dispatch(logout());
  };

  const handleRedirecionar = (rota) => {
    navigate(rota);
    setOpenMenu(false);
  };

  return (
    <AppBar sx={styles.appBarStyled} position="relative">
      <Grid container sx={styles.rotaContainer}>
        {location.pathname.split('/')[1] !== 'home' && (
          <Typography sx={styles.rotaEscolhida}>
            {routeLabel[`/${location.pathname.split('/')[1]}`]}
          </Typography>
        )}
        {location.pathname.split('/')[1] === 'home' && (
          <Typography sx={styles.rotaEscolhida}>
            {comercialResponsavel && `KM - ${comercialResponsavel?.endereco?.cidade?.nome || ''}`}
          </Typography>
        )}
      </Grid>
      <Grid item xs={5} sx={styles.toolBarTools}>
        <Button sx={styles.perfilButton} title={anuncianteNome} onClick={() => setOpenMenu(o => !o)}>
          <Avatar
            src={fotoPerfil}
            alt={anuncianteNome}
            sx={styles.avatar}
          >
            <PersonRounded />
          </Avatar>
          <ArrowDropDownRounded />
        </Button>
      </Grid>
      <Modal
        componentsProps={{
          backdrop: {
            sx: {
              background: 'rgb(0, 0, 0, 0.0)',
            },
          },
        }}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
      >
        <Paper elevation={4} sx={styles.modal}>
          <Grid container style={{padding: '5px 0px'}}>
            <Button sx={styles.buttonMenu} onClick={() => handleRedirecionar(PERFIL_ROUTE)}>
              {'Editar perfil'}
            </Button>
            <Button sx={styles.buttonMenu} onClick={handleClickLogout}>
              {'Sair'}
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </AppBar>
  );
};

const styles = {
  rotaContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rotaEscolhida: {
    fontSize: '20px',
    color: '#00398e',
    fontWeight: '900'
  },
  modal: {
    width: '170px',
    height: 'fit-content',
    zIndex: 10,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    top: theme => `calc(-12px + ${theme.toolbarHeight})`,
  },
  appBarStyled: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#fff !important',
    width: '100% !important',
    height: (theme) => `${theme.toolbarHeight} !important`,
    zIndex: 5,
    padding: (theme) => `${theme.space[1]} 1rem`,
    boxShadow: ' -10px 6px 12px rgba(128, 152, 213, 0.2)',
    '& input': {
      height: '100%',
    },
  },
  toolBarTools: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    svg: {
      height: ' 100%',
    },
  },
  perfilButton: {
    cursor: 'pointer',
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    padding: 0,
    display: 'flex',
    marginLeft: '25px',
    paddingLeft: '20px',
    borderLeft: '1px solid #8995B0',
    height: '40px',
    width: '85px',
    alignItems: 'center',
    overflow: 'hidden',
    'svg': {
      color: '#8995B0',
      width: '28px',
      height: '28px'
    }
  },
  buttonMenu: {
    textTransform: 'none !important',
    justifyContent: 'flex-start !important',
    paddingLeft: '12px !important',
    width: '100%',
    borderRadius: '0px !important',
    color: '#8995B0 !important',
    '&:hover': {
      backgroundColor: '#8995B0 !important',
      color: '#fff !important'
    }
  },
  avatar: {
    background: 'transparent',
    img: {
      objectFit: 'contain'
    }
  }
};
