import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {LoadingPage} from '../../components/loading-page';
import {FIND_BY_ID_COMERCIAL} from '../../graphql/queries';
import FormularioExecutivo from './formulario';

const EditarExecutivo = () => {
  const {executivoId} = useParams();

  const executivoQuery = useQuery(FIND_BY_ID_COMERCIAL, {
    variables: {
      id: executivoId
    }
  });

  const {data = {}} = executivoQuery;
  const {comercial: executivo = {}} = data;

  const refetch = () => {
    executivoQuery.refetch();
  }

  return executivoQuery.loading || executivoQuery.error ? (
    <LoadingPage loading={executivoQuery.loading} error={executivoQuery.error} />
  ) : (
    <FormularioExecutivo executivo={executivo} refetch={refetch} />
  );
};

export default EditarExecutivo;
