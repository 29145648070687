import {useQuery} from '@apollo/client';
import React from 'react';
import {useParams} from 'react-router-dom';
import {LoadingPage} from '../../components/loading-page';
import {FIND_BY_ID_MODELO_ANUNCIO} from '../../graphql/queries';
import FormularioModeloAnuncio from './formulario';

const EditarModeloAnuncio = () => {
  const {modeloAnuncioId} = useParams();

  const modeloAnuncioQuery = useQuery(FIND_BY_ID_MODELO_ANUNCIO, {
    variables: {
      id: modeloAnuncioId,
    },
  });

  const {data = {}} = modeloAnuncioQuery;
  const {modelo = {}} = data;

  if (modeloAnuncioQuery.loading || modeloAnuncioQuery.error) {
    return (
      <LoadingPage
        loading={modeloAnuncioQuery.loading}
        error={modeloAnuncioQuery.error}
      />
    );
  }

  return <FormularioModeloAnuncio modelo={modelo} />;
};

export default EditarModeloAnuncio;
