import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaExtratoRepasse from '../consulta';

const ExtratoReceber = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaExtratoRepasse tipoLancamento="RECEBER" />} />
    </Routes>
  );
};

export default ExtratoReceber;
