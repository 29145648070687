import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sidebar: [],
  sidebarOpen: true
};

const acaoSetOpenSidebar = (state, action) => {
  state.sidebarOpen = action.payload;
  window.sessionStorage.setItem('isSidebarOpen', action.payload); 
};

const resumeSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    setOpenSidebar: acaoSetOpenSidebar,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setOpenSidebar} = actions;
