import React from 'react';
import {movimentacaoSituacao, parcelaTipo} from '../../../constants/enum-labels';
import Number from '../../../utils/number';
import Data from '../../../utils/data';

const formatPeriodo = (row) => {
  if (!row?.dataInicioPeriodo || !row?.dataFimPeriodo) return '';

  return (
    <>
      {Data.dataFormat(row.dataInicioPeriodo, 'DD/MM/YY')}<br />
      {Data.dataFormat(row.dataFimPeriodo, 'DD/MM/YY')}
    </>
  );
}

const motoristas = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'anuncio.campanha.cidade.nome',
    format: ({row}) => row?.anuncio?.campanha?.cidade?.nomeEUf || ' ',
  },
  {
    id: 'nomeRecebedor',
    label: 'Nome do Recebedor',
    campo: 'motorista.nome',
    format: ({row}) => row?.motorista?.nome || ' ',
  },
  {
    id: 'dataInicioPeriodo',
    label: 'Período',
    format: ({row}) => formatPeriodo(row),
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Vencimento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (Data.dataFormat(value) || ' '),
  },
  {
    id: 'valor',
    label: 'Valor',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({value}) => movimentacaoSituacao[value],
  },
];

const anunciantes = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Vencimento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (Data.dataFormat(value) || ' '),
  },
  {
    id: 'parcelaTipo',
    label: 'Tipo',
    format: ({value}) => parcelaTipo[value],
  },
  {
    id: 'valor',
    label: 'Valor',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({value}) => movimentacaoSituacao[value],
  },
];

const royalties = [
  {
    id: 'comercial',
    label: 'Comercial',
    campo: 'comercial.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || ' ',
  },
  {
    id: 'licencaRecebimento',
    label: 'Recebedor',
    campo: 'licencaRecebimento.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || 'Km Mídia',
  },
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Pagamento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (Data.dataFormat(value) || ' '),
  },
  {
    id: 'valorReferencia',
    label: 'Valor bruto',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
  {
    id: 'valor',
    label: 'Royalties',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
];

const royaltiesImpExp = [
  {
    id: 'comercial',
    label: 'Comercial',
    campo: 'comercial.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || ' ',
  },
  {
    id: 'licencaRecebimento',
    label: 'Recebedor',
    campo: 'licencaRecebimento.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || 'Km Mídia',
  },
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Pagamento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (Data.dataFormat(value) || ' '),
  },
  {
    id: 'valorReferencia',
    label: 'Valor líquido',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
  {
    id: 'valor',
    label: 'Royalties',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
];

const instalador = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'anuncio.campanha.cidade.nome',
    format: ({row}) => row?.anuncio?.campanha?.cidade?.nomeEUf || ' ',
  },
  {
    id: 'nomeRecebedor',
    label: 'Nome do Recebedor',
    campo: 'instalador.nome',
    format: ({row}) => row?.instalador?.nome || ' ',
  },
  {
    id: 'nomeMotorista',
    label: 'Nome do Motorista',
    campo: 'motorista.nome',
    format: ({row}) => {
      return row?.motorista?.nome || row?.anuncio?.motorista?.nome || ' ';
    },
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Vencimento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (Data.dataFormat(value)),
  },
  {
    id: 'valor',
    label: 'Valor',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({value}) => movimentacaoSituacao[value],
  },
];

const getColunas = (destinoRecebimento) => {
  switch (destinoRecebimento) {
    case 'MOTORISTA': return motoristas;
    case 'ANUNCIANTE': return anunciantes;
    case 'INSTALADOR': return instalador;
    case 'ROYALTIES': return royalties;
    case 'ROYALTIES_EXPORTACAO':
    case 'ROYALTIES_IMPORTACAO': return royaltiesImpExp;
    default: return [];
  }
}

export default getColunas;