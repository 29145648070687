import React from 'react';
import {FIND_CAMPANHA_BY_COMERCIAL as query} from '../../graphql/queries';
import {SelectBase} from '../../components/select/mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map(item => ({
      ...item,
      label: `[${item.numero}] ${item.titulo}`,
      value: item.id
    }))
    : [];
};

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'titulo', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search
    }
  };
};

export const SelectCampanhas = (props) => {
  return (
    <SelectBase
      label="Campanha"
      responseObjectName="campanhas"
      transformOptions={transformOptions}
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
