import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  midiaKit: [],
  filtrosMateriaisVenda: {
    filtros: {
      search: '',
    },
    page: 0,
    orderBy: {campo: 'titulo', asc: false},
    pageSize: 10,
  },
  hasMore: false,
  totalMateriaisVenda: 0,
  refetchMateriaisVenda: false,
};
const acaoAtualizarMateriaisVenda = (state) => {
  state.refetchMateriaisVenda = true;
};

const acaoPersistMateriaisVenda = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.midiaKit = content;
  state.hasMore = !last;
  state.totalMateriaisVenda = totalElements || 0;
  state.refetchMateriaisVenda = false;
};
const acaoPersistFiltrosMateriaisVenda = (state, action) => {
  state.filtrosMateriaisVenda = action.payload;
};

const resumeSlice = createSlice({
  name: 'materiaisVenda',
  initialState: initialState,
  reducers: {
    persistMateriaisVenda: acaoPersistMateriaisVenda,
    atualizarMateriaisVenda: acaoAtualizarMateriaisVenda,
    persistFiltrosMateriaisVenda: acaoPersistFiltrosMateriaisVenda,
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistMateriaisVenda, atualizarMateriaisVenda, persistFiltrosMateriaisVenda} =
  actions;
