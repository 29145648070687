import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Box,
  Collapse
} from '@mui/material';
import {FIND_ALL_ANUNCIOS} from '../../../graphql/queries';
import {anuncioSituacao} from '../../../constants/enum-labels';
import TableCellOrdered from '../../../components/table-cell-ordered';

const columns = [
  {
    id: 'nome',
    label: 'Nome',
    campo: 'motorista.nome',
    format: ({motorista}) => motorista.nome,
  },
  {
    id: 'veiculo',
    label: 'Marca/Modelo/Ano',
    campo: 'veiculo.modelo.marca.nome',
    format: ({veiculo}) =>
      veiculo?.modelo?.marca
        ? `${veiculo.modelo.marca.nome} - ${veiculo.modelo.nome} ${veiculo.anoFabricacao}/${veiculo.anoModelo}`
        : '-',
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao}) => (situacao ? anuncioSituacao[situacao] : '-'),
  },
];

const MotoristasCampanhaCidade = ({pageSize = 20, campanhaCidade = {}, search = {}, open}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState({campo: 'motorista.nome', asc: true});
  const [searchDTO, setSearchDTO] = useState({});
  const [anuncios, setAnuncios] = useState([]);

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS, {
    variables: {
      searchDTO: {
        campanhaCidadeId: campanhaCidade.id,
        ...searchDTO,
      },
      pageable: {
        pageNumber: page,
        pageSize: rowsPerPage,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
          {
            campo: 'motorista.nome',
            ordem: 'ASC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (anunciosQuery.loading || anunciosQuery.errors) return;

    setAnuncios(anunciosQuery?.data?.anuncios || {});
  }, [anunciosQuery]);

  useEffect(() => {
    setPage(0);
    setSearchDTO(search);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableRow>
      <TableCell colSpan={12} sx={{padding: '0px'}}>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{height: '20px'}}
        >
          <Paper sx={{margin: '6px !important', overflow: 'hidden'}}>
            <Box overflow="auto">
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anuncios?.content?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                            >
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={anuncios.totalElements || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </Paper>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default MotoristasCampanhaCidade;
