import React, {useState} from 'react';
import moment from 'moment';
import {useMutation} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {Grid, Modal, Typography, Box} from '@mui/material';
import {BAIXA_MOVIMENTACAO_FINANCEIRA} from '../../../graphql/mutation';
import InputDateV2 from '../../../components/mui-input-date';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../../components/mui-button';
import Number from '../../../utils/number';
import {toastNotification} from '../../../components/toastify';
import Textarea from '../../../components/textarea/mui-textarea';
import DragNDrop from '../../../components/drag-n-drop';
import {uploadAmazonS3Arquivo} from '../../../services/UploadService';

const DarBaixaExtrato = ({
  isOpen,
  onClose,
  onBaixa,
  movimentacaoFinanceira = {},
}) => {
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState(undefined);
  const [baixaLoading, setBaixaLoading] = useState(false);

  const [baixaMovimentacaoFinanceira] = useMutation(BAIXA_MOVIMENTACAO_FINANCEIRA);

  const handleClickDarBaixa = async () => {
    setBaixaLoading(true);

    let response = undefined;
    try {
      if (file) {
        response = await uploadAmazonS3Arquivo(file);
      }
    } catch (error) {
      console.log(error);
    }

    baixaMovimentacaoFinanceira({
      variables: {
        movimentacao: {
          id: movimentacaoFinanceira.id,
          codigoComprovante: formData.codigoComprovante?.trim(),
          dataPgto: formData.dataBaixa
            ? moment(formData.dataBaixa).format('YYYY-MM-DDTHH:mm:ss')
            : null,
          obs: formData.obs?.trim(),
          valorPago: formData.valor ? Number.stringToFloat(formData.valor) : null,
          documento: response?.id ? {id: response.id} : undefined
        }
      },
    })
      .then(() => {
        setFormData({});
        setFile(undefined);
        onBaixa && onBaixa();
        onClose();
        toastNotification({
          message: 'A movimentação financeira foi baixada!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setBaixaLoading(false));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
    >
      <Box sx={styles.content}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={styles.header}>
              <Typography sx={styles.headerText}>
                Baixar movimentação
              </Typography>
              <Close sx={styles.closeIcon} onClick={onClose} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={4} sx={styles.inputWrapper}>
                  <InputDateV2
                    label="Data da Baixa"
                    selected={formData.dataBaixa}
                    onChange={(e) => setFormData({...formData, dataBaixa: e})}
                  />
                </Grid>
                <Grid item xs={4} sx={styles.inputWrapper}>
                  <InputV2
                    label={
                      movimentacaoFinanceira &&
                        movimentacaoFinanceira.tipo === 'CREDITO'
                        ? 'Valor Recebido'
                        : 'Valor Pago'
                    }
                    value={formData.valor}
                    onChange={(e) =>
                      setFormData({...formData, valor: e.target.value})
                    }
                    mask="moeda"
                  />
                </Grid>
                <Grid item xs={4} sx={styles.inputWrapper}>
                  <InputV2
                    label="Código do Comprovante"
                    value={formData.codigoComprovante}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        codigoComprovante: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <Textarea
                    label="Observação"
                    value={formData.obs}
                    onChange={(e) =>
                      setFormData({...formData, obs: e.target.value})
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={styles.subtitle}>
                    Anexar documento:
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <DragNDrop
                    file={file}
                    onChange={setFile}
                    fileName={file?.name}
                    onDelete={() => {
                      setFile(undefined);
                    }}
                    style={{marginBottom: '15px', marginTop: '-30px'}}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.buttonsContainer}>
              <ButtonComponent
                id="button-cancel-empty"
                value={'Cancelar'}
                onClick={onClose}
                disabled={baixaLoading}
                sx={styles.button}
              />
              <ButtonComponent
                value={'Dar Baixa'}
                loading={baixaLoading}
                onClick={handleClickDarBaixa}
                disabled={baixaLoading}
                sx={styles.button}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const styles = {
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#0083e6',
    padding: '5px 0',
  },
  closeIcon: {
    color: '#0083e6',
    fontSize: '30px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '16px',
  },
  inputWrapper: {
    padding: '0 5px',

    '& .react-datepicker-wrapper': {
      width: '100% !important',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 5px',
  },
  button: {
    width: '200px',
  },
  subtitle: {
    color: ' #00106B',
    fontSize: ' 14px',
    fontWeight: '900',
    marginBottom: '16px',
  },
};

export default DarBaixaExtrato;
