import React from 'react';
import {Box} from '@mui/material';

const TabPanel = (props) => {
  const {children, value, index, boxPadding = 2, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={boxPadding}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;