import React from 'react';
import {Box, Grid} from '@mui/material';
import config from '../../config/config';

const ImagemAnunciante = ({fotoPerfil, width, height, token}) => {
  const src = `${config.AMAZON_S3_BASE_URL}${fotoPerfil}?access_token=${token}`;

  return (
    <Grid container>
      {fotoPerfil && (
        <Box
          component="img"
          src={src}
          alt=""
          sx={styles.image}
          style={{width: `${width}`, height: `${height}`}}
        />
      )}
    </Grid>
  );
};

const styles = {
  image: {
    background: theme => theme.palette.paterns.blueGraySecundary,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
    cursor: 'pointer',
  }
};

export default ImagemAnunciante;