import {Box, Grid} from '@mui/material';
import React from 'react';
import {Navigate, Route, HashRouter as Router, Routes} from 'react-router-dom';
import BannerBgImg from '../assets/images/banner-bg-man@3x.png';
import KmMidiaLogoImg from '../assets/images/km-midia@3x.png';
import {ESQUECI_SENHA_ROUTE, LOGIN_ROUTE} from '../constants/routes';
import LoginView from '../views/auth/login';
import RecuperarSenhaView from '../views/auth/recuperar-senha';
import {DepoimentosContainer} from '../containers/depoimentos';

const depoimentos = [
  {
    descricao:
      'Depois que comecei a trabalhar com o KM Mídia minha renda no final do mês aumentou e eu não fiz nada mais do que minha rotina normal, sem dor de cabeça e sem burocracia. Estava lucrando só por rodar por aí!',
    infoUsuario: 'João Paulo Soares - Motorista autônomo',
  },
  {
    descricao:
      'Resultados excelentes! Uma das únicas empresa que me fornece todas as métricas do período de veiculação, consegui acompanhar também todos os carros contratados com meus anúncios em tempo real, incrível e surpreendente. Muito satisfeito e contente!',
    infoUsuario: 'Wanderlei Rodrigues Junior - Sócio-Fundador Dejú',
  },
  {
    descricao:
      'A experiência foi muito satisfatória e só tenho elogios. Os passageiros se sentiram mais seguros por estarem andando com um motorista que possui o nome atrelado a uma marca tão grande e conhecida, eles relataram muitos elogios tanto a UniFil, quando o Km Mídia pela criatividade nesse novo formato de anunciar.',
    infoUsuario: 'Fernando Portugal - Motorista autônomo',
  },
];

export const RouterAuthComponent = () => (
  <Grid sx={styles.fullWrapper}>
    <Grid sx={styles.bannerContainer}>
      <Box
        component="img"
        src={KmMidiaLogoImg}
        alt="kmmidia"
        sx={styles.kmMidiaLogo}
      />
      <Grid sx={styles.depoimentosWrapper}>
        <DepoimentosContainer depoimentos={depoimentos} />
      </Grid>
    </Grid>
    <Grid sx={styles.container}>
      <Router style={{height: '100%'}}>
        <Routes>
          <Route path={LOGIN_ROUTE} element={<LoginView />} />
          <Route path={ESQUECI_SENHA_ROUTE} element={<RecuperarSenhaView />} />
          <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
        </Routes>
      </Router>
    </Grid>
  </Grid>
);

const styles = {
  fullWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#fff',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  bannerContainer: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${BannerBgImg}) top center no-repeat`,
    backgroundSize: 'cover',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',

    '@media (max-width: 1024px)': {
      width: '100%',
      height: '150px',
      background: `url(${BannerBgImg}) center -325px no-repeat`,
    },
  },
  container: {
    width: '100%',

    '@media (min-width: 1024px)': {
      width: '50%',
      height: '100%',
    },
  },
  kmMidiaLogo: {
    height: '60px',
    margin: 2,

    '@media (min-width: 1024px)': {
      height: 'auto',
      marginTop: '6%',
    },
  },
  depoimentosWrapper: {
    display: 'none',

    '@media (min-width: 1024px)': {
      display: 'block',
      width: '100%',
      bottom: '100px',
      left: 0,
      right: 0,

      '& .slider': {
        height: '140px',
      },
    },
  },
};
