import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaExtratoRepasse from '../consulta';
import RelatorioPagamentos from './relatorio-pagamentos';

const ExtratoPagar = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<ConsultaExtratoRepasse tipoLancamento="PAGAR" />} />
      <Route exact path={'/relatorio-pagamentos'} element={<RelatorioPagamentos />} />
    </Routes>
  );
};

export default ExtratoPagar;
